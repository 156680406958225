import React from 'react';
import { Helmet } from 'react-helmet';
import LoadingContent from 'ekaubamaja-ui/lib/Components/LoadingContent';

export interface IFramePageProps {
    title: string;
    isLoading?: boolean;
}

const FramePage: React.FunctionComponent<IFramePageProps> = (props) => {
    // Todo overlays
    return (
        <React.Fragment>
            <Helmet>
                <title>{props.title}</title>
            </Helmet>
            {props.isLoading ? (
                <LoadingContent
                    size="large"
                    layout="absolute"
                />
            ) : (
                props.children
            )}
        </React.Fragment>
    );
};

FramePage.displayName = 'FramePage';

export default FramePage;
