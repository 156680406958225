import * as React from 'react';
import { useState } from 'react';
import { ISort } from '../../interfaces/Sort';
import { ILabelledString } from 'components/Cart/Interfaces/ICartTotals';
import { CartSortDirection } from '../../enums/CartSortDirection';
import GridComponent from 'components/Common/GridComponent';
import { CartSortKey } from '../../enums/CartSortKey';
import { parse } from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';

interface IToolbarProps {
    config: IToolbarLabels;
}

interface IToolbarLabels {
    view: string;
    sortOrderLabel: string;
    sortOrderSelect: ILabelledString[];
}

const ToolbarComponent: React.FunctionComponent<IToolbarProps> = (props) => {
    const { config } = props;
    const location = useLocation();
    const history = useHistory();
    const parsedQueryParams = parse(location.search);

    const updateQuery = (key: string, value?: string) => {
        const pathname = location.pathname;
        const searchParams = new URLSearchParams(location.search);
        searchParams.delete('p');
        if (!value) {
            searchParams.delete(key);
        } else {
            searchParams.set(key, value);
        }
        history.push({
            pathname,
            search: searchParams.toString(),
        });
    };

    const dir = (parsedQueryParams['direction'] as string) ?? CartSortDirection.ASC;
    const sortKey = (parsedQueryParams['order'] as string) ?? CartSortKey.POSITION;

    const [sortOrder, setSortOrder] = useState<ISort>({
        key: sortKey,
        dir,
    });

    const changeDirection = (e) => {
        e.preventDefault();
        const dir = sortOrder.dir === CartSortDirection.ASC ? CartSortDirection.DESC : CartSortDirection.ASC;
        setSortOrder(
            Object.assign({}, sortOrder, {
                dir,
            }),
        );
        updateQuery('direction', dir);
    };

    const changeSortKey = (e) => {
        e.preventDefault();
        setSortOrder(Object.assign({}, sortOrder, { key: e.target.value }));
        updateQuery('order', e.target.value);
    };

    return (
        <React.Fragment>
            <div className="product-toolbar">
                <div className="group">
                    <label>
                        <span className="label">{config.sortOrderLabel}</span>
                        <select
                            defaultValue={sortOrder.key}
                            onChange={(e) => changeSortKey(e)}
                        >
                            {config.sortOrderSelect.map((selectOption: ILabelledString, key: number) => {
                                return (
                                    <option
                                        key={key}
                                        value={selectOption.value}
                                    >
                                        {selectOption.label}
                                    </option>
                                );
                            })}
                        </select>
                        <span className="togglebutton">
                            <a
                                onClick={(e) => changeDirection(e)}
                                className={sortOrder.dir === CartSortDirection.ASC ? '' : 'active'}
                                href="#"
                            >
                                <span className="icon">
                                    <img
                                        src={`${window.assetUrl}/assets/img/sprite_arrow01_up.svg`}
                                        alt=""
                                    />
                                </span>
                            </a>
                            <a
                                onClick={(e) => changeDirection(e)}
                                className={sortOrder.dir === CartSortDirection.DESC ? '' : 'active'}
                                href="#"
                            >
                                <span className="icon">
                                    <img
                                        src={`${window.assetUrl}/assets/img/sprite_arrow01_down.svg`}
                                        alt=""
                                    />
                                </span>
                            </a>
                        </span>
                    </label>
                    <GridComponent viewLabel={config.view} />
                </div>
            </div>
        </React.Fragment>
    );
};

export default ToolbarComponent;
