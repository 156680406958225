import classNames from 'classnames';
import * as React from 'react';

const RenderWishlistButton: React.FunctionComponent<{
    label: string;
    onClick?: (e) => void;
    target?: string;
    notifications?: number;
}> = (props) => {
    return (
        <a
            href="#"
            tabIndex={0}
            onClick={props.onClick}
            className={classNames({
                desktop: props.target === 'desktop',
                mobile: props.target === 'mobile',
            })}
        >
            <span className="icon">
                <img
                    src={`${window.assetUrl}/assets/img/sprite_heart.svg`}
                    alt={props.label}
                    width="16"
                    height="16"
                />
            </span>
            <span className="label">
                <span className="title">{props.label}</span>
            </span>
            <b className="notifications">{props.notifications ?? 0}</b>
        </a>
    );
};

export default RenderWishlistButton;
