import * as React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';

import PublicRouter from './publicRouter';
import { useRequest } from 'redux-query-react';
import { request } from 'data/requests/request';
import { IRegionOptionListItem } from '../interfaces/checkout/address/IRegionOptionListItem';
import { IAgreementsResponse } from '../interfaces/payment/IAgreementsResponse';
import LoadingApp from 'ekaubamaja-ui/lib/Components/LoadingApp';
import useErrorState from 'components/error/Error';
import { useEffect, useState } from 'react';
import { ISmartdealSummary } from '../interfaces/payment/ISmartdealSummary';
import { IAmoneyData } from '../interfaces/checkout/amoney/IMoneyData';
import i18n from 'components/Checkout/i18n';
import Checkout from 'components/Checkout/Checkout';
import { IAdsConfig } from 'components/AdsSearch/interfaces/IAdsConfig';

export const RouteNotFound = () => <Redirect to={{ state: { notFoundError: true } }} />;

export const Main = (props) => <Checkout {...props} />;

export const CaptureRouteNotFound = withRouter(Main);

export const LocaleContext = React.createContext('en');

const mapStateToProps = (state) => {
    let translations = state.entities.translations;
    if (typeof translations !== 'undefined') {
        translations = JSON.parse(translations);
    }
    return {
        translations,
        locale: typeof translations === 'object' ? Object.keys(translations).pop() : undefined,
    };
};

declare global {
    interface Window {
        regions: IRegionOptionListItem[];
        jwt: string;
        quoteIdMask: string;
        API_URL: string;
        form_key: string;
        LOGIN_URL: string;
        STORE_CODE: string;
        PHONE_PREFIXES: string[];
        detailedPaymentEnabled: boolean;
        paymentLogos: {
            payment: any;
        };
        checkoutConfig?: {
            logo_src?: string;
            business_address_form?: {
                disable_contact_info: boolean;
                fields_sort_order: {
                    [key: string]: number;
                };
            };
        };
        agreements: IAgreementsResponse;
        locationDefaults: {
            country_id: string;
            region_id: number;
        };
        mkCardCancelled: () => void;
        mkCardCompleted: (token) => void;
        isBusinessEnabled: boolean;
        isLoginEnabled: boolean;
        inAddressConfiguration: IAdsConfig;
        rootElement: string;
        isTrhEnabled: boolean;
        messageWidgetUrl: string;
        isSubscriptionEnabled?: boolean;
        isPersonalCodeEnabled?: boolean;
        isPersonalCodeBottomLocated?: boolean;
        personalCodeRequiredFor?: string[];
        isSmartDeal?: boolean;
        smartdealSummary?: ISmartdealSummary;
        allowCheckMoAll?: boolean;
        aMoneyData?: IAmoneyData;
        moneyOrdersAllowedForBusiness: { [key: string]: boolean };
        moneyOrdersAllowedForLogged: boolean;
        personalCodeValidationRules?: {
            [key: string]: string;
        };
        storeCurrencyCode?: string;
    }
}
const MainRouter = () => {
    const translationsRequest = useRequest(
        request({ type: 'translations', url: 'fast/checkout/translations', notApi: true }),
    );
    const { translations, locale } = useSelector(mapStateToProps);

    if (locale && typeof locale !== 'undefined') {
        i18n(translations).changeLanguage(locale);
    }
    const [isErrorState, setIsErrorState] = useState(false);
    const setError = (error: boolean) => {
        if (isErrorState !== error) {
            setIsErrorState(error);
        }
    };
    const { errorComponent } = useErrorState({ setError });

    if (translationsRequest && translationsRequest[0].isFinished && !locale) {
        setError(true);
    }

    return (
        <React.Fragment>
            {isErrorState && errorComponent}
            {translationsRequest && translationsRequest[0].isFinished && locale ? (
                <LocaleContext.Provider value={locale}>
                    <CaptureRouteNotFound>
                        <React.Suspense fallback={<LoadingApp />}>
                            <Switch>
                                <Route
                                    path="/"
                                    component={PublicRouter}
                                />
                                <RouteNotFound />
                            </Switch>
                        </React.Suspense>
                    </CaptureRouteNotFound>
                </LocaleContext.Provider>
            ) : (
                <LoadingApp />
            )}
        </React.Fragment>
    );
};
export default MainRouter;
