import { request } from 'data/requests/request';
import { ICartProduct } from 'components/Cart/Interfaces/ICartProduct';
import { IAlterProduct } from 'components/Cart/Buttons/Alter';

const removeWishlistRequest = (product: IAlterProduct, quoteId?: string) =>
    request({
        type: 'RemoveWishlist',
        url: `wishlist/wishlist/removeProduct`,
        method: 'POST',
        data: { ...product, quoteId },
        notApi: true,
        absolute: false,
    });

export default removeWishlistRequest;
