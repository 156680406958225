import * as React from 'react';
import { useDispatch } from 'react-redux';

import { useHistory } from 'react-router-dom';
import { closeOverlay, openOverlay } from 'data/overlays/overlayHandler';
import Button from 'ekaubamaja-ui/lib/Components/Button';

interface IFilterButtonProps {
    title: string;
    count?: number;
}

const FilterButton: React.FunctionComponent<IFilterButtonProps> = (props) => {
    const { count, title } = props;
    const dispatch = useDispatch();
    const history = useHistory();

    return (
        <li className="toggle">
            <Button
                title={title}
                intent="primary"
                icon="filter2"
                addOn={count}
                onClick={(e) => {
                    e.preventDefault();
                    history.push({ hash: 'mobilefilter' });
                    dispatch(closeOverlay({ name: 'all' }));
                    dispatch(openOverlay({ name: 'mobilefilter' }));
                }}
            />
        </li>
    );
};

export default FilterButton;
