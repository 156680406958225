import * as React from 'react';

interface IProps {
    class: string;
    ulAdditionalStyles?: {
        [key: string]: string | number;
    };
    buttons?: {
        click: (e) => void;
        label: string | React.ReactElement;
        intent?: string;
        disabled?: boolean;
    }[];
    inputs?: {
        change: (e) => void;
        value: string;
        type: string;
        title: string;
    }[];
}

const ReorderActions: React.FunctionComponent<IProps> = (props) => {
    return (
        <React.Fragment>
            <ul
                className={props.class}
                style={props.ulAdditionalStyles}
            >
                {props.buttons &&
                    props.buttons.map((button, key) => {
                        return (
                            <li key={key}>
                                <button
                                    disabled={button.disabled}
                                    onClick={(e) => button.click(e)}
                                    className={`${button.intent}`}
                                    aria-label={typeof button.label === 'string' ? button.label : ''}
                                >
                                    {button.label}
                                </button>
                            </li>
                        );
                    })}
                {props.inputs &&
                    props.inputs.map((input, key) => {
                        return (
                            <li key={key}>
                                <label htmlFor="field01">{input.title}</label>
                                <div className="input-box">
                                    <input
                                        onChange={(e) => input.change(e)}
                                        value={input.value}
                                        type={input.type}
                                        title={input.title}
                                    />
                                </div>
                            </li>
                        );
                    })}
            </ul>
        </React.Fragment>
    );
};

export default ReorderActions;
