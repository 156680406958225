import * as React from 'react';
import { useCallback, useState } from 'react';
import { extractErrors } from '../../../helpers/request/extractErrors';
import OverlayConfirm from 'components/overlay/OverlayConfirm';
import { useMutation } from 'redux-query-react';
import Toaster from 'ekaubamaja-ui/lib/Components/Toaster';
import { request } from 'data/requests/request';
import { ICartProduct } from 'components/Cart/Interfaces/ICartProduct';
import isLoggedIn from '../../../helpers/auth/isLoggedIn';
import { ICartLabels } from 'components/Cart/Interfaces/ICartLabels';
import removeWishlistRequest from 'components/Cart/requests/removeWishlistRequest';
import { IAlterProduct } from 'components/Cart/Buttons/Alter';

export interface IRemoveLabels {
    confirmRemoveTitle?: string;
    confirmRemoveDescription?: string;
    confirmButtonCancel?: string;
    confirmButtonOk?: string;
    removeFromLabel?: string;
}

interface IProps {
    isCheckout?: boolean;
    isWishlist?: boolean;
    product: IAlterProduct;
    setError?: (error) => void;
    quoteId?: string;
    labels: IRemoveLabels;
}

const Remove: React.FunctionComponent<IProps> = (props) => {
    const { product, setError, isWishlist, isCheckout, quoteId, labels } = props;
    const [quoteItemId, setQuoteItemId] = useState(null);
    const [showConfirm, setShowConfirm] = useState(false);
    const [{}, removeWishlistProduct] = useMutation((product: IAlterProduct) =>
        removeWishlistRequest(product, quoteId),
    );

    const [{}, cartRequest] = useMutation((data: any, id: any, method: string) =>
        request({
            method: method,
            type: 'response',
            notApi: false,
            url: isLoggedIn() ? `carts/mine/items/${id}` : `guest-carts/${quoteId}/items/${id}`,
            data,
        }),
    );

    const removeItemFromCart = (id) => {
        setQuoteItemId(id);
        setShowConfirm(true);
    };

    const handleDelete = useCallback(async () => {
        if (isCheckout) {
            const response = await cartRequest({} as any, quoteItemId, 'DELETE');
            if (response.status === 200) {
                window.dispatchEvent(new CustomEvent('cart-altered'));
            } else {
                const errors = extractErrors(response);
                if (errors && errors.error) {
                    if (setError) {
                        setError(errors.error);
                    }
                }
            }
        }

        if (isWishlist) {
            const response = await removeWishlistProduct(product);
            if (response.status === 200) {
                window.dispatchEvent(new CustomEvent('cart-altered'));
                window.dispatchEvent(new CustomEvent('wishlist-altered'));
            }

            Toaster.addToast({
                intent: response.status === 200 ? 'success' : 'danger',
                text: response.body.message,
                asHtml: true,
            });
        }
    }, [quoteItemId]);

    const handleClose = useCallback(async () => {
        setShowConfirm(false);
    }, []);

    return (
        <React.Fragment>
            {showConfirm && (
                <OverlayConfirm
                    title={labels.confirmRemoveTitle || ''}
                    description={labels.confirmRemoveDescription || ''}
                    buttonCancel={labels.confirmButtonCancel || ''}
                    buttonOk={labels.confirmButtonOk || ''}
                    onAccept={handleDelete}
                    onClose={handleClose}
                />
            )}
            <div className="cart-control__buttons">
                <button
                    onClick={(e) => {
                        e.preventDefault();
                        removeItemFromCart(product.id);
                    }}
                    className="danger"
                >
                    <span className="icon">
                        <img
                            src={`${window.assetUrl}/assets/img/sprite_close.svg`}
                            alt=""
                            width="16"
                            height="16"
                        />
                    </span>
                    <span className="label">{labels.removeFromLabel}</span>
                </button>
            </div>
        </React.Fragment>
    );
};

export default Remove;
