import * as React from 'react';
import Overlay from 'ekaubamaja-ui/lib/Components/Overlay/index';
import { useState } from 'react';
import { empty } from '../../helpers/empty';

interface IProps {
    config: IRequiredModal;
}

interface IRequiredModal {
    text: string;
    modalConfirmLabel: string;
}

const STORAGE_CONFIRM_REQUIRED_MODAL = 'STORAGE_CONFIRM_REQUIRED_MODAL';

const RequiredModal = (props: IProps): JSX.Element => {
    const { config } = props;

    const [isOpened, setIsOpened] = useState<boolean>(empty(localStorage.getItem(STORAGE_CONFIRM_REQUIRED_MODAL)));

    const closeModal = () => {
        setIsOpened(false);
        localStorage.setItem(STORAGE_CONFIRM_REQUIRED_MODAL, STORAGE_CONFIRM_REQUIRED_MODAL);
    };

    return (
        <>
            {isOpened && (
                <Overlay
                    isOpen={isOpened}
                    doClose={closeModal}
                    layout="dialog"
                    dialogConfig={{
                        description: (
                            <>
                                <h2 className="center">{config.text}</h2>
                            </>
                        ),
                        buttons: [
                            {
                                title: `${config.modalConfirmLabel}`,
                                intent: 'primary',
                                onClick: (e) => {
                                    e.preventDefault();
                                    closeModal();
                                },
                            },
                        ],
                    }}
                    size="small"
                />
            )}
        </>
    );
};

export default RequiredModal;
