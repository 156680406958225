import * as React from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { ReactElement } from 'react';

interface IProps {
    component: ReactElement;
}

const BrowserRouterWrapper: React.FunctionComponent<IProps> = (props) => {
    // Todo use browserRouter
    return (
        <HashRouter>
            <Switch>
                <Route
                    exact={true}
                    path="/:level1?/:level2?/:level3?/:level4?/:level5?/"
                >
                    {props.component}
                </Route>
            </Switch>
        </HashRouter>
    );
};

export default BrowserRouterWrapper;
