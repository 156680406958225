import { cloneDeep } from 'lodash';
import { IGridViewState } from 'components/Common/store/gridViewSelector';

export enum dispatchType {
    GRID_SET = 'GRID_SET',
}

const initialState: IGridViewState = {
    isListView: localStorage.getItem(dispatchType.GRID_SET) === '1',
};

interface IGridViewAction {
    type: dispatchType;
    payload: IGridViewState;
}

const gridViewReducer = (state: IGridViewState = cloneDeep(initialState), action: IGridViewAction): IGridViewState => {
    const handleSetGridViewState = (payload: IGridViewState) => {
        localStorage.setItem(dispatchType.GRID_SET, payload.isListView ? '1' : '0');
        return {
            ...state,
            isListView: payload.isListView,
        };
    };

    switch (action.type) {
        case dispatchType.GRID_SET: {
            return handleSetGridViewState(action.payload);
        }

        default: {
            return state;
        }
    }
};

export default gridViewReducer;
