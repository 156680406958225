import React from 'react';
import classNames from 'classnames';
import { formatPriceLocale } from '../../../helpers/priceUtils';

interface IProps {
    type: 'primary' | 'old' | 'unit' | 'pledge' | 'primary sale';
    price: number;
    discountPercent?: number;
    pricePrefixText?: string;
    priceSuffixText?: string;
}

const BoxProductPrice = (props: IProps) => {
    const { type, price, discountPercent, pricePrefixText, priceSuffixText } = props;

    return (
        <div className="box-product__price">
            {price > 0 && (
                <span className={classNames('price', type)}>
                    {!!discountPercent && discountPercent > 0 && (
                        <span className="discount real">-{discountPercent}%</span>
                    )}
                    {pricePrefixText}
                    {pricePrefixText && ' '}
                    {formatPriceLocale(price)}
                    {priceSuffixText && '/'}
                    {priceSuffixText}
                </span>
            )}
        </div>
    );
};

export default BoxProductPrice;
