import * as React from 'react';

export interface ICartProductProps {
    titlePrimary?: string;
    titleSecondary?: string;
    shortDescription?: string;
    href?: string;
}

const Title = (props: ICartProductProps) => {
    const { titlePrimary, titleSecondary, shortDescription, href } = props;

    const title = (
        <span className="title-label">
            {!!titlePrimary && <span className="title-primary">{titlePrimary}</span>}
            {!!titleSecondary && <span className="title-secondary">{titleSecondary}</span>}
        </span>
    );

    return (
        <React.Fragment>
            <div className="box-product__title">
                {!!href && (
                    <a
                        href={href}
                        target="_blank"
                    >
                        {title}
                    </a>
                )}
                {!href && <React.Fragment>{title}</React.Fragment>}
            </div>
            {!!shortDescription ?? <div className="box-product__description">{shortDescription}</div>}
        </React.Fragment>
    );
};

export default Title;
