import * as React from 'react';
import Overlay from 'ekaubamaja-ui/lib/Components/Overlay';
import { IButtonProps } from 'ekaubamaja-ui/lib/Components/Button';
import { useState } from 'react';

interface IProps {
    title: string;
    buttonOk?: string;
    buttonCancel: string;
    description: string;
    withExtraDeleteConfim?: boolean;
    onAccept?: () => void;
    onClose?: () => void;
}

const OverlayConfirm = (props: IProps) => {
    const { onAccept, onClose, withExtraDeleteConfim } = props;
    const [showConfirm, setShowConfirm] = useState(false);

    const buttons: IButtonProps[] = [];
    if (onAccept) {
        buttons.push({
            title: props.buttonOk,
            intent: 'danger',
            onClick: onAccept,
        });
    }
    buttons.push({
        title: props.buttonCancel,
        intent: 'discrete',
    });

    if (!showConfirm && withExtraDeleteConfim) {
        return (
            <React.Fragment>
                <span onClick={() => setShowConfirm(true)}>{props.title}</span>
            </React.Fragment>
        );
    }

    return (
        <Overlay
            isOpen={true}
            layout="dialog"
            title={props.title}
            dialogConfig={{
                description: props.description,
                buttons,
            }}
            doClose={() => (onClose ? onClose() : setShowConfirm(false))}
        />
    );
};

export default OverlayConfirm;
