import * as React from 'react';
import { useState } from 'react';
import Tooltip from 'ekaubamaja-ui/lib/Components/Tooltip';
import Icon from 'ekaubamaja-ui/lib/Components/Icon';

interface IProps {
    config: {
        cookieId: number;
        isRequired: boolean;
        isChecked: boolean;
        cookieName: string;
        cookieDescription: string;
    };
}

const AccountCookieChoice = (props: IProps) => {
    const { cookieId, isRequired, cookieName, isChecked, cookieDescription } = props.config;

    const [checked, setIsChecked] = useState<boolean>(isRequired || isChecked);

    return (
        <>
            <input
                type="hidden"
                name={`cookie[${cookieId}]`}
                value={checked ? 1 : 0}
            />
            <input
                checked={checked}
                onChange={() => setIsChecked(isRequired || !checked)}
                defaultChecked={checked}
                disabled={isRequired}
                type="checkbox"
                id={`c-cookie-${cookieId}`}
                className="checkbox hidden"
                name={`cookie[${cookieId}]`}
                value="1"
            />
            <label
                onClick={() => setIsChecked(isRequired || !checked)}
                className="label"
            >
                <span>{cookieName}</span>{' '}
                <Tooltip
                    position="top"
                    interactionKind="hover"
                    content={<span>{cookieDescription}</span>}
                >
                    <Icon
                        width={15}
                        height={15}
                        kind={`info`}
                    />
                </Tooltip>
            </label>
        </>
    );
};

export default AccountCookieChoice;
