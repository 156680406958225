import { request } from 'data/requests/request';

const cartFormPostRequest = (data) =>
    request({
        type: 'cartFormPostRequest',
        url: `checkout/cart/updatePost`,
        method: 'POST',
        force: true,
        notApi: true,
        absolute: false,
        clearContentType: true,
        data,
    });

export default cartFormPostRequest;
