import * as React from 'react';
import { ICartTotals } from 'components/Cart/Interfaces/ICartTotals';
import { CartType } from '../../enums/CartType';
import { ICartLabels } from 'components/Cart/Interfaces/ICartLabels';
import { ICartProduct } from 'components/Cart/Interfaces/ICartProduct';
import RenderHTML from 'ekaubamaja-ui/lib/Components/RenderHTML';
import CartSidebarButtons from 'components/Cart/CartSidebarButtons';
import Coupon from 'components/Cart/Coupon';

export interface IProps {
    cart: ICartTotals;
    cartContainer?: string;
    cartContainerBeforeTotals?: string;
    cartType: string;
    formKey?: string;
    labels: ICartLabels;
    products: ICartProduct[];
}

const CartSidebar = (props: IProps) => {
    const { cart, cartContainer, cartType, labels, products, formKey, cartContainerBeforeTotals } = props;
    if (!cart || !products || products.length < 1) {
        return <React.Fragment />;
    }
    return (
        <div className={'layout-cart__column-a'}>
            <div className={'layout-cart__column-a__content'}>
                {cartContainerBeforeTotals && (
                    <div className="cart-callout">
                        <RenderHTML
                            nowrapper={true}
                            html={cartContainerBeforeTotals}
                        />
                    </div>
                )}
                <ul className={'cart-prices'}>
                    {!!cart.shipping && parseFloat(cart.shipping.value) > 0 && (
                        <React.Fragment>
                            <li>
                                <span className={'label'}>{cart.subtotal.label}</span>
                                <span className={'value'}>{cart.subtotal.value}</span>
                            </li>
                            <li>
                                <span className={'label'}>{cart.shipping.label}</span>
                                <span className={'value'}>{cart.shipping.value}</span>
                            </li>
                        </React.Fragment>
                    )}
                    <li>
                        <span className={'label'}>{cart.withoutTax.label}</span>
                        <span className={'value'}>{cart.withoutTax.value}</span>
                    </li>
                    {cart.cartDiscount && (
                        <li>
                            <span className={'label'}>{cart.cartDiscount.label}</span>
                            <span className={'value'}>{cart.cartDiscount.value}</span>
                        </li>
                    )}
                    {cart.taxes &&
                        cart.taxes.map((item, key) => (
                            <li key={key}>
                                <span className={'label'}>{item.label}</span>
                                <span className={'value'}>{item.value}</span>
                            </li>
                        ))}
                    {cart.pawnTotal && (
                        <li>
                            <span className={'label'}>{cart.pawnTotal.label}</span>
                            <span className={'value'}>{cart.pawnTotal.value}</span>
                        </li>
                    )}
                    <li className={'primary'}>
                        <span className={'label'}>{cart.grandTotal.label}</span>
                        <span className={'value'}>{cart.grandTotal.value}</span>
                    </li>
                    {cartType === CartType.CHECKOUT && (
                        <React.Fragment>
                            {cartContainer && (
                                <li className={`message`}>
                                    <RenderHTML
                                        nowrapper={true}
                                        html={cartContainer}
                                    />
                                </li>
                            )}
                            <CartSidebarButtons
                                formKey={formKey}
                                cart={cart}
                                cartType={cartType}
                                labels={labels}
                                products={products}
                            />
                        </React.Fragment>
                    )}
                </ul>
                {(cartType === CartType.WISHLIST || cartType === CartType.SHARE) && (
                    <CartSidebarButtons
                        formKey={formKey}
                        cart={cart}
                        cartType={cartType}
                        labels={labels}
                        products={products}
                    />
                )}
                {cartType === CartType.CHECKOUT && (
                    <Coupon
                        quoteId={cart.quoteId}
                        labels={cart.couponLabels}
                    />
                )}
            </div>
        </div>
    );
};

export default CartSidebar;
