import { request } from 'data/requests/request';
import { IReturnProduct } from 'components/Catalog/Product/Return/Table';

export interface IOrderReturnRequest {
    orderId: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    returnReason?: string;
    notes?: string;
    products: IReturnProduct[];
}

const orderReturnRequest = (data: IOrderReturnRequest) =>
    request({
        method: 'POST',
        type: 'response',
        url: `order_return/order/submit`,
        notApi: true,
        data,
    });

export default orderReturnRequest;
