import * as React from 'react';
import { Popover, PopoverInteractionKind } from 'ekaubamaja-ui/lib/Components/Popover';
import RenderWishlistButton from 'components/UserHubWishlist/RenderWishlistButton';
import RenderWishlistMenu from 'components/UserHubWishlist/RenderWishlistMenu';
import Overlay from 'ekaubamaja-ui/lib/Components/Overlay';
import Icon from 'ekaubamaja-ui/lib/Components/Icon';
import { useMutation, useRequest } from 'redux-query-react';
import { request } from 'data/requests/request';
import { useSelector } from 'react-redux';
import { useCallback, useEffect } from 'react';

interface IProps {
    config: {
        labels: IUserHubWishlistLabels;
        menuItems: IUserHubWishlistMenuItems[];
    };
}

export interface IUserHubWishlistLabels {
    buttonLabel: string;
}

export interface IUserHubWishlistMenuItems {
    text: string;
    url: string;
    notificationValue?: number;
}

const mapStateToProps = (state) => {
    return {
        userHubWishlistsResponse: state.entities.userHubWishlists,
    };
};

const UserHubWishlist: React.FunctionComponent<IProps> = (props) => {
    const { config } = props;
    const [openOverlay, setOpenOverlay] = React.useState<boolean>(false);

    const [{ isFinished }] = useRequest(
        request({ type: 'userHubWishlists', url: 'wishlist/wishlist/userHubWishlists', notApi: true }),
    );
    const { userHubWishlistsResponse } = useSelector(mapStateToProps);

    const [{}, notificationsRequest] = useMutation(() =>
        request({ type: 'userHubWishlists', url: `wishlist/wishlist/userHubWishlists`, notApi: true }),
    );

    const effect = useCallback(async () => {
        notificationsRequest();
    }, [notificationsRequest]);

    useEffect(() => {
        window.addEventListener('wishlist-altered', effect);
        return function cleanup() {
            window.removeEventListener('wishlist-altered', effect);
        };
    });
    const wishlistCount = !userHubWishlistsResponse ? 0 : userHubWishlistsResponse.length - 1;
    return (
        <React.Fragment>
            {isFinished && (
                <Popover
                    enforceFocus={true}
                    interactionKind={PopoverInteractionKind.CLICK}
                    position="bottom-right"
                    usePortal={true}
                    minimal={true}
                    autoFocus={false}
                    canEscapeKeyClose={true}
                    modifiers={{
                        offset: {
                            offset: '-10px, 0',
                        },
                        flip: {
                            enabled: false,
                        },
                    }}
                    className="dropper"
                    portalClassName={'site-menu-portal'}
                    popoverClassName={'site-menu-popover'}
                    content={<RenderWishlistMenu menuItems={userHubWishlistsResponse} />}
                    target={
                        <RenderWishlistButton
                            label={config.labels.buttonLabel}
                            target="desktop"
                            onClick={(e) => {
                                e.preventDefault();
                            }}
                            notifications={wishlistCount}
                        />
                    }
                    transitionDuration={0}
                    hoverOpenDelay={0}
                    hoverCloseDelay={100}
                />
            )}
            <RenderWishlistButton
                label={config.labels.buttonLabel}
                target="mobile"
                onClick={(e) => {
                    e.preventDefault();
                    setOpenOverlay(true);
                }}
                notifications={wishlistCount}
            />
            {isFinished && (
                <Overlay
                    isOpen={openOverlay}
                    doClose={() => setOpenOverlay(false)}
                    layout="focusview"
                    className="userhub__menu-mobile"
                    customHeader={
                        <div className="userhub__menu-mobile__heading">
                            <h2>{config.labels.buttonLabel}</h2>
                            <button onClick={() => setOpenOverlay(false)}>
                                <Icon
                                    kind="close"
                                    width={16}
                                    height={16}
                                />
                            </button>
                        </div>
                    }
                    canOutsideClickClose={true}
                >
                    <RenderWishlistMenu menuItems={userHubWishlistsResponse} />
                </Overlay>
            )}
        </React.Fragment>
    );
};

export default UserHubWishlist;
