import * as React from 'react';
import { IClientCustomerTableLabels, ICustomer } from 'components/ClientCustomerList/ClientCustomerList';
import CustomerTableRow from 'components/ClientCustomerList/CustomerTableRow';
import FilterPaginator from 'components/Filter/Paginator';

interface IProps {
    labels: IClientCustomerTableLabels;
    customers: ICustomer[];
}

const ClientCustomerList = (props: IProps) => {
    const { labels, customers } = props;

    return (
        <React.Fragment>
            <div className="table">
                <table>
                    <thead>
                        <tr>
                            <th>{labels.nameLabel}</th>
                            <th>{labels.emailLabel}</th>
                            <th>{labels.phoneLabel}</th>
                            <th>{labels.roleLabel}</th>
                            <th>{labels.statusLabel}</th>
                            <th className="actions">&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        {customers.map((customer: ICustomer) => {
                            return (
                                <CustomerTableRow
                                    key={customer.email}
                                    customer={customer}
                                    editLabel={labels.editLabel}
                                    deleteLabel={labels.deleteLabel}
                                />
                            );
                        })}
                    </tbody>
                </table>
            </div>
            <div className={'paginator'}>
                <FilterPaginator
                    config={{
                        pages: 1,
                        current: 1,
                        labelPrev: labels.previousLabel,
                        labelNext: labels.nextLabel,
                    }}
                />
            </div>
        </React.Fragment>
    );
};

export default ClientCustomerList;
