import classNames from 'classnames';
import * as React from 'react';

const RenderCartButton: React.FunctionComponent<{
    label: string;
    total?: string | null;
    notifications?: string | null;
    onClick?: (e) => void;
    target?: string;
}> = (props) => {
    return (
        <a
            href="#"
            tabIndex={0}
            onClick={props.onClick}
            className={classNames({
                desktop: props.target === 'desktop',
                mobile: props.target === 'mobile',
            })}
        >
            <span className="icon">
                <img
                    src={`${window.assetUrl}/assets/img/sprite_cart.svg`}
                    alt={props.label}
                    width="16"
                    height="64"
                />
            </span>
            <span className="label">
                <span className="title">{props.label}</span>
                <span className="description">{props.total}</span>
            </span>
            <b className="amount">{props.total}</b>
            <b className="notifications">{props.notifications}</b>
        </a>
    );
};

export default RenderCartButton;
