import React, { useState } from 'react';
import { IProduct } from 'components/Catalog/CategoryList/IProduct';
import BoxProductPrices from 'components/Catalog/CategoryList/BoxProductPrices';
import ProductActions, { IProductActionsLabels } from 'components/Catalog/Product/ProductActions';
import AddAllToWishlist, { IWishListLabels } from 'components/Cart/Buttons/AddAllToWishlist';
import { isArray } from 'lodash';
import Title from 'components/Catalog/Product/Title';

export interface IProps {
    product: IProduct;
    labels: IProductActionsLabels;
    wishlistLabels?: IWishListLabels;
    hideActions?: boolean;
}

const BoxProduct = (props: IProps) => {
    const { product, labels, hideActions, wishlistLabels } = props;
    const productInAnyWishlist = isArray((window as any).wishlistProductIds)
        ? !!(window as any).wishlistProductIds.find(
              (productId: string) => parseInt(product.id.toString()) === parseInt(productId),
          )
        : false;
    const [hasProductInAnyWishlist, setHasProductInAnyWishlist] = useState(productInAnyWishlist);
    return (
        <div className="box-product">
            <a
                href={product.url}
                className="box-product__link"
            >
                {product.name}
            </a>
            <div className="box-product__thumb">
                <span
                    className="box-product__thumb__image"
                    style={{ backgroundImage: `url('${product.image}')` }}
                />
                {wishlistLabels && (
                    <div className="box-product__wishlist">
                        <AddAllToWishlist
                            hasProductInAnyWishlist={hasProductInAnyWishlist}
                            setHasProductInAnyWishlist={setHasProductInAnyWishlist}
                            renderAddOne={true}
                            products={[{ ...product, productId: product.id.toString() }]}
                            labels={wishlistLabels}
                        />
                    </div>
                )}
            </div>
            <div className="box-product__content">
                <div className="box-product__text">
                    <Title
                        titlePrimary={product.name}
                        shortDescription={product.shortDescription}
                    />
                </div>
            </div>
            <BoxProductPrices
                price={product.price}
                discountPercent={product.discountPercent}
                specialPrice={product.specialPrice}
                pawnValue={product.pawnValue}
                unitPrice={product.unitPrice}
                unitPriceUnit={product.unitPriceUnit}
                pawnLabel={labels.pawnLabel}
                hideOldPrice={product.hideOldPrice}
                isListDiscount={product.isListDiscount}
            />
            {!hideActions && (
                <ProductActions
                    product={product}
                    labels={labels}
                />
            )}
        </div>
    );
};

export default BoxProduct;
