import * as React from 'react';
import classNames from 'classnames';
import BoxProduct from 'components/Catalog/CategoryList/BoxProduct';
import { IProduct } from 'components/Catalog/CategoryList/IProduct';
import { IProductActionsLabels } from 'components/Catalog/Product/ProductActions';
import { IWishListLabels } from 'components/Cart/Buttons/AddAllToWishlist';

export interface IProps {
    config: {
        products: IProduct[];
        labels: IProductActionsLabels;
        wishlistLabels?: IWishListLabels;
        isShowList?: boolean;
        hideActions?: boolean;
    };
}

const ProductsGrid: React.FunctionComponent<IProps> = (props) => {
    const { products, labels, wishlistLabels, isShowList, hideActions } = props.config;
    if (!products || !products.length) {
        return <React.Fragment />;
    }
    return (
        <div className={classNames('layout-products__list', { list: isShowList })}>
            {!!products &&
                products.length > 0 &&
                products.map((product) => (
                    <div
                        className="layout-products__container"
                        key={product.id}
                    >
                        <BoxProduct
                            product={product}
                            labels={labels}
                            hideActions={hideActions}
                            wishlistLabels={wishlistLabels}
                        />
                    </div>
                ))}
        </div>
    );
};

export default ProductsGrid;
