import * as React from 'react';
import { useMutation } from 'redux-query-react';
import DeleteBase from 'components/AccountDelete/DeleteBase';
import deleteWishlistRequest from 'components/Wishlist/requests/deleteWishlistRequest';

interface IProps {
    config: {
        wishlistId: string;
        linkText: string;
        popUpTitle: string;
        popUpButtonConfirm: string;
        popUpButtonCancel: string;
    };
}

const DeleteWishlist: React.FunctionComponent<IProps> = (props) => {
    const [{}, deleteRequest] = useMutation(() => deleteWishlistRequest(props.config.wishlistId));

    return (
        <DeleteBase
            deleteRequest={deleteRequest}
            config={props.config}
        />
    );
};

export default DeleteWishlist;
