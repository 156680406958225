import * as React from 'react';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { parse, stringify } from 'query-string';
import { useKeypress } from 'ekaubamaja-ui/lib/Utilities/Generic';
import { parseInt } from 'lodash';
import { useEffect, useState } from 'react';

export interface IFilterPaginatorProps {
    config: {
        pages: number;
        current: number;
        labelPrev: string;
        labelNext: string;
    };
}

const FilterPaginator: React.FunctionComponent<IFilterPaginatorProps> = (props) => {
    const history = useHistory();
    const location = useLocation();
    const q = parse(location.search);
    const { pages, current, labelPrev, labelNext } = props.config;
    const currentPage = parseInt(q.p as string) || current;

    const [pageNumber, setPageNumber] = useState<number>(currentPage);
    const [focused, setFocused] = useState(false);
    const maxPages = pages || 1;

    useKeypress(
        'Enter',
        () => {
            if (focused) {
                let newPageNumber = pageNumber;
                if (newPageNumber > maxPages) {
                    newPageNumber = maxPages;
                }
                if (newPageNumber < 1) {
                    newPageNumber = 1;
                }
                setPageNumber(newPageNumber);
                history.replace(location.pathname + '?' + stringify({ ...q, p: newPageNumber }));
            }
        },
        [focused, pageNumber],
    );

    // Watch for external url changes

    useEffect(() => {
        if (q.p && parseInt(q.p as string) !== pageNumber) {
            setPageNumber(parseInt(q.p as string));
            window.scrollTo(0, 0);
        }
    }, [q.p]);

    const prevPage: number | null = currentPage > 1 ? currentPage - 1 : 1;
    const nextPage: number | null = currentPage < maxPages ? currentPage + 1 : maxPages;

    return (
        <React.Fragment>
            <NavLink
                className="paginator__prev"
                to={location.pathname + '?' + stringify({ ...q, p: prevPage ? prevPage : undefined })}
            >
                <span>{props.config?.labelPrev || ''}</span>
            </NavLink>
            <div className="paginator__counter">
                <input
                    type="text"
                    value={pageNumber || ''}
                    onChange={(e) => {
                        setPageNumber(parseInt(e.target.value));
                    }}
                    onFocus={() => {
                        setFocused(true);
                    }}
                    onBlur={() => {
                        setFocused(false);
                    }}
                />
                <span className="paginator__label"> / {props.config?.pages || '1'}</span>
            </div>
            <NavLink
                className="paginator__next"
                to={location.pathname + '?' + stringify({ ...q, p: nextPage ? nextPage : undefined })}
            >
                <span>{props.config?.labelNext || ''}</span>
            </NavLink>
        </React.Fragment>
    );
};

FilterPaginator.displayName = 'FilterPaginator';

export default FilterPaginator;
