import * as React from 'react';

interface IProps {
    dom: string;
    componentMapper?: (maybeComponent: string, maybeContent: string, maybeConfig) => React.Component;
}

export const componentMapperParseOptions = (props: IProps) => {
    return {
        replace: (domNode: any) => {
            if (props.componentMapper && domNode.attribs && domNode.attribs['data-component']) {
                const maybeConfig = JSON.parse(domNode.attribs['data-config'] || '{}');
                const maybeComponent = domNode.attribs['data-component'];
                const maybeContent = domNode.children ?? '';
                const { class: className, ...rest } = domNode.attribs;
                const mappedComponent = props.componentMapper(maybeComponent, maybeContent, maybeConfig);

                if (mappedComponent) {
                    return (
                        <domNode.name
                            className={className}
                            {...rest}
                        >
                            {mappedComponent}
                        </domNode.name>
                    );
                }
            }

            return false;
        },
    };
};
