import React from 'react';
import classNames from 'classnames';

interface IProps {
    config: {
        notifications: INotification[];
    };
}

interface INotification {
    notification_message: string;
    notification_type_field: string;
}

const GlobalNotification = (props: IProps) => {
    return (
        <>
            {props.config.notifications.map((notification) => {
                return (
                    <div className={classNames('generic-callout', notification.notification_type_field)}>
                        <div className="generic-callout__limiter">
                            <p>{notification.notification_message}</p>
                        </div>
                    </div>
                );
            })}
        </>
    );
};

export default GlobalNotification;
