import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useMutation } from 'redux-query-react';
import Toaster from 'ekaubamaja-ui/lib/Components/Toaster';
import { debounce } from 'lodash';
import { empty } from '../../../helpers/empty';
import { extractErrors } from '../../../helpers/request/extractErrors';
import { request } from 'data/requests/request';
import isLoggedIn from '../../../helpers/auth/isLoggedIn';
import { ILabelledNumber } from 'components/Cart/Interfaces/ICartProduct';
import Icon from 'ekaubamaja-ui/lib/Components/Icon';
import alterWishlistRequest from 'components/Cart/requests/alterWishlistRequest';
import { CartAmountSizes } from '../../../enums/CartAmountSizes';
import AmountSize from 'components/Common/AmountSize';
import amountSize from 'components/Common/AmountSize';

export interface IAlterProduct {
    id: string;
    sku: string;
    qty: ILabelledNumber;
    caseQty?: number;
    palletQty?: number;
    disableCartEdit?: boolean;
}

interface IProps {
    product: IAlterProduct;
    isCheckout?: boolean;
    isWishlist?: boolean;
    quoteId?: string;
    setError?: (error: string) => void;
    setCurrentAmountOver: (isAmountOver: boolean) => void;
    labels: IAmountSizeLabels;
}

export interface IAmountSizeLabels {
    piece: string;
    pieceMultipleShort: string;
    box: string;
    base: string;
}

interface IAmountSizeProduct {
    amountSize: string;
    amountValue: number;
}

const Alter: React.FunctionComponent<IProps> = (props) => {
    const { product, isCheckout, isWishlist, quoteId, setError, labels, setCurrentAmountOver } = props;

    const detectAmountAndSize = (qty: number) => {
        if (props.product.palletQty && props.product.palletQty <= qty && qty % props.product.palletQty === 0) {
            return {
                amountSize: CartAmountSizes.BASE,
                amountValue: qty / props.product.palletQty,
            };
        } else if (props.product.caseQty && props.product.caseQty <= qty && qty % props.product.caseQty === 0) {
            return {
                amountSize: CartAmountSizes.BOX,
                amountValue: qty / props.product.caseQty,
            };
        }
        return {
            amountSize: CartAmountSizes.PIECE,
            amountValue: qty,
        };
    };
    const amountAccordingToSize: IAmountSizeProduct = detectAmountAndSize(parseInt(product.qty.value));
    const [inputAmount, setInputAmount] = useState<number>(amountAccordingToSize.amountValue);
    const [selectedAmountSize, setSelectedAmountSizeState] = useState<string>(amountAccordingToSize.amountSize);
    const isAbleQtyChange = !!quoteId && !product.disableCartEdit;

    const [{}, cartRequest] = useMutation((data: any, id: any, method: string) =>
        request({
            method: method,
            type: 'response',
            notApi: false,
            url: isLoggedIn() ? `carts/mine/items/${id}` : `guest-carts/${quoteId}/items/${id}`,
            data,
        }),
    );

    const alterProduct = useCallback(
        debounce((id: any, sku: string, qty: number) => {
            props.setCurrentAmountOver(false);
            const data: any = {
                cartItem: {
                    sku,
                    qty,
                    quote_id: quoteId,
                },
            };
            cartRequest(data, id, 'PUT').then((response) => {
                if (response.status === 200) {
                    if (qty > response.body.qty) {
                        setInputAmount(response.body.qty);
                        setSelectedAmountSizeState(CartAmountSizes.PIECE);
                        props.setCurrentAmountOver(true);
                    }
                    window.dispatchEvent(new CustomEvent('cart-altered'));
                } else {
                    const errors = extractErrors(response);
                    if (errors && errors.error) {
                        if (setError) {
                            setError(errors.error);
                        }
                    }
                }
            });
        }, 500),
        [],
    );

    const [{}, alterWishlistProduct] = useMutation((product: IAlterProduct) => alterWishlistRequest(product, quoteId));

    const alterProductWishlist = useCallback(
        debounce((product: IAlterProduct, qty: number) => {
            product.qty.value = qty.toString();
            alterWishlistProduct(product).then((response) => {
                if (!!response.body.qty && qty > response.body.qty) {
                    setInputAmount(response.body.qty);
                    setSelectedAmountSizeState(CartAmountSizes.PIECE);
                    props.setCurrentAmountOver(true);
                }
                window.dispatchEvent(new CustomEvent('cart-altered'));
                Toaster.addToast({
                    intent: response.status === 200 ? 'success' : 'danger',
                    text: response.body.message,
                    asHtml: true,
                });
            });
        }, 500),
        [],
    );

    const setProductAmount = (amount: number, amountSizeState?: string) => {
        if (!amountSizeState) {
            amountSizeState = selectedAmountSize;
        }
        const defaultAmount = 1;

        if (amountSizeState === CartAmountSizes.BOX && product.caseQty && !empty(product.caseQty)) {
            amount = amount * product.caseQty;
        } else if (amountSizeState === CartAmountSizes.BASE && product.palletQty && !empty(product.palletQty)) {
            amount = amount * product.palletQty;
        }

        if (amount <= 0) {
            return;
        }

        if (isCheckout) {
            alterProduct(product.id, product.sku, !empty(amount) ? amount : defaultAmount);
        }
        if (isWishlist) {
            alterProductWishlist(product, !empty(amount) ? amount : defaultAmount);
        }
    };

    const setSelectedAmountSize = (amountSizeState: string) => {
        let newInputAmount = inputAmount;
        if (selectedAmountSize === CartAmountSizes.BOX && product.caseQty && !empty(product.caseQty)) {
            newInputAmount = inputAmount * product.caseQty;
        } else if (selectedAmountSize === CartAmountSizes.BASE && product.palletQty && !empty(product.palletQty)) {
            newInputAmount = inputAmount * product.palletQty;
        }
        setProductAmount(newInputAmount, amountSizeState);
        setInputAmount(newInputAmount);

        setSelectedAmountSizeState(amountSizeState);
    };

    return (
        <div className="cart-control__amount">
            {isAbleQtyChange && (
                <button
                    onClick={(e) => {
                        e.preventDefault();
                        const amount = inputAmount - 1 > 0 ? inputAmount - 1 : 1;
                        setProductAmount(amount);
                        setInputAmount(amount);
                    }}
                >
                    <Icon
                        width={10}
                        height={10}
                        kind="minus"
                    />
                </button>
            )}
            <input
                onChange={(e) => {
                    let amount = parseInt(e.target.value);
                    if (empty(amount) || amount <= 0) {
                        amount = 1;
                    }

                    setProductAmount(amount);
                    setInputAmount(amount);
                }}
                disabled={!isAbleQtyChange}
                type="number"
                value={inputAmount}
            />
            <AmountSize
                product={{
                    caseQty: product.caseQty,
                    palletQty: product.palletQty,
                }}
                labels={labels}
                selectedAmountSize={selectedAmountSize}
                setSelectedAmountSize={setSelectedAmountSize}
            />
            {isAbleQtyChange && (
                <button
                    onClick={(e) => {
                        e.preventDefault();
                        setProductAmount(inputAmount + 1);
                        setInputAmount(inputAmount + 1);
                    }}
                >
                    <Icon
                        width={10}
                        height={10}
                        kind="plus"
                    />
                </button>
            )}
        </div>
    );
};

export default Alter;
