import * as React from 'react';
import OverlayConfirm from 'components/overlay/OverlayConfirm';
import { useDispatch } from 'react-redux';

export interface ICustomerDeleteProps {
    config: {
        linkText: string;
        popUpTitle: string;
        popUpButtonConfirm: string;
    };
}

const AccountDelete: React.FunctionComponent<ICustomerDeleteProps> = (props) => {
    return (
        <OverlayConfirm
            withExtraDeleteConfim={true}
            title={props.config.linkText}
            description={props.config.popUpTitle}
            buttonCancel={props.config.popUpButtonConfirm}
        />
    );
};

export default AccountDelete;
