import * as React from 'react';
import { ICartProduct } from 'components/Cart/Interfaces/ICartProduct';
import CartProducts from 'components/Cart/CartProducts';
import { ICartTotals } from 'components/Cart/Interfaces/ICartTotals';
import CartSidebar from 'components/Cart/CartSidebar';
import { useCallback, useEffect, useState } from 'react';
import { useMutation } from 'redux-query-react';
import { request } from 'data/requests/request';
import { ICartLabels } from 'components/Cart/Interfaces/ICartLabels';
import { CartType } from '../../enums/CartType';

export interface ICheckoutCartProps {
    config: {
        products: ICartProduct[];
        cartTotals: ICartTotals;
        cartContainer?: string;
        cartContainerBeforeTotals?: string;
        emptyMessage?: string;
        labels: ICartLabels;
        wishlistProductIds?: string[];
        formKey?: string;
    };
    cartType: string;
}
interface CartResponseType {
    products: ICartProduct[];
    cartTotals: ICartTotals;
}

const CheckoutCart = (props: ICheckoutCartProps) => {
    const { products, cartTotals, cartContainer, emptyMessage, labels, wishlistProductIds, cartContainerBeforeTotals } =
        props.config;
    const [productsState, setProductsState] = useState<ICartProduct[]>(products);
    const [totalsState, setTotalsState] = useState<ICartTotals>(cartTotals);
    const [triggerSort, setTriggerSort] = useState<boolean>(false);
    const [{}, cartRequest] = useMutation(() =>
        request({ type: 'cart', url: `cart/cart/index/quoteId/${cartTotals.quoteId}`, notApi: true }),
    );

    const effect = useCallback(async () => {
        // Do not update state for share as mocked products will be replaced
        if (props.cartType === CartType.SHARE) {
            return;
        }
        const response = await cartRequest();
        const cartResponse: CartResponseType = response.body;
        setProductsState(cartResponse.products);
        setTotalsState(cartResponse.cartTotals);
        setTriggerSort(true);
    }, [cartRequest, triggerSort]);

    /**
     * TODO rewrite to Store
     * @param props
     * @constructor
     */
    useEffect(() => {
        window.addEventListener('cart-altered', effect);
        return function cleanup() {
            window.removeEventListener('cart-altered', effect);
        };
    });

    return (
        <React.Fragment>
            <CartSidebar
                products={productsState}
                labels={labels}
                cartType={props.cartType}
                cart={totalsState}
                cartContainer={cartContainer}
                cartContainerBeforeTotals={cartContainerBeforeTotals}
                formKey={props.config.formKey}
            />
            <CartProducts
                emptyMessage={emptyMessage}
                products={productsState}
                quoteId={totalsState?.quoteId || ''}
                cartType={props.cartType}
                labels={labels}
                wishlistProductIds={wishlistProductIds}
            />
        </React.Fragment>
    );
};

export default CheckoutCart;
