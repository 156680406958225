import React, { useCallback, useState } from 'react';
import { useMutation } from 'redux-query-react';
import ControlAutocomplete, { IAutocompleteOption } from 'ekaubamaja-ui/lib/Components/ControlAutocomplete/index';
import ControlSelect from 'ekaubamaja-ui/lib/Components/ControlSelect/index';
import { IAdsAppartment, IAdsResponseAddress } from 'components/AdsSearch/interfaces/IAdsResponse';
import { IAdsAddress } from 'components/AdsSearch/interfaces/IAdsAddress';
import { IAdsConfig } from 'components/AdsSearch/interfaces/IAdsConfig';
import AddressRequest from 'components/AdsSearch/requests/AddressRequest';
import FormRow from 'ekaubamaja-ui/lib/Components/FormRow';
import { debounce } from 'lodash';

interface IProps {
    config: IAdsConfig;
    setAddressState: (address: IAdsAddress | undefined) => void;
    error?: boolean;
}

const AdsSearch: React.FunctionComponent<IProps> = (props) => {
    const { config, setAddressState } = props;
    const [{}, addressRequest] = useMutation((requestValue: string) => AddressRequest(config.url ?? '', requestValue));
    const [value, setValue] = useState('');
    const [aptValue, setAptState] = useState('');
    const [selectedAddress, setSelectedAddress] = useState<IAdsResponseAddress>();
    const initialOptionList: IAutocompleteOption[] = [{ label: '', value: '' }];
    const [optionList, setOptionList] = useState<IAutocompleteOption[]>(initialOptionList);
    const [aptOptionList, setAptOptionList] = useState<IAutocompleteOption[]>();

    const setAddress = (address: IAdsResponseAddress, apt?: string) => {
        if (selectedAddress !== address) {
            setSelectedAddress(address);
        }
        if (address) {
            const aptString = apt ?? aptValue ? `-${apt ?? aptValue}` : '';
            const detail: IAdsAddress = {
                region: address.maakond,
                region_id: config.countyIdMap?.find((item) => item.adsCode === address.ehakmk)?.id || 0,
                street: [`${address.aadresstekst}${aptString}`],
                street_wo_house_no: address.liikluspind,
                house_no: address.aadress_nr,
                apartment_no: aptValue,
                postcode: address.sihtnumber,
                city: address.omavalitsus,
            };
            setAddressState(detail);
        } else {
            setAddressState(undefined);
        }
    };
    const setAptValue = (value: string) => {
        setAptState(value);
        if (selectedAddress) {
            setAddress(selectedAddress, value);
        }
    };

    const makeRequest = useCallback(
        debounce((inputValue) => {
            if (inputValue.length > 2) {
                void addressRequest(inputValue).then((response) => {
                    if (response.body && response.body.addresses) {
                        setOptionList(
                            response.body.addresses.map((addr: IAdsResponseAddress) => {
                                return {
                                    label: addr.ipikkaadress,
                                    value: addr.ipikkaadress,
                                    data: addr,
                                };
                            }),
                        );
                    }
                });
            }
        }, 100),
        [],
    );

    const setValueState = (inputValue: string) => {
        if (inputValue === '') {
            setAptValue('');
            setAptOptionList([]);
            setAddressState(undefined);
        }
        setValue(inputValue);
        makeRequest(inputValue);
    };

    return (
        <React.Fragment>
            <FormRow
                label={config.labels.addressSearchLabel}
                required={true}
                error={props.error ? config.labels.addressIsNecessary : undefined}
            >
                <ControlAutocomplete
                    value={value}
                    placeholder={config.labels.placeholder}
                    data={optionList}
                    onChange={(e) => setValueState(e)}
                    onRemove={() => window.dispatchEvent(new CustomEvent('ads-address-clear'))}
                    onSelect={(e) => {
                        setValueState(e.label);
                        setAptValue('');

                        if ((e.data as IAdsResponseAddress).appartments?.length) {
                            setAptOptionList(
                                e.data.appartments.map((apt: IAdsAppartment) => {
                                    return (
                                        <option
                                            key={apt.tahis}
                                            value={apt.tahis}
                                        >
                                            {apt.tahis}
                                        </option>
                                    );
                                }),
                            );
                        } else {
                            setAptOptionList(undefined);
                        }
                        setAddress(e.data);
                    }}
                    minChars={3}
                />
            </FormRow>

            {!!aptOptionList?.length && (
                <FormRow
                    label={config.labels.selectApartmentLabel}
                    required={false}
                >
                    <ControlSelect
                        value={aptValue}
                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setAptValue(e.target.value)}
                        size={'small'}
                    >
                        {aptOptionList}
                    </ControlSelect>
                </FormRow>
            )}
        </React.Fragment>
    );
};

export default AdsSearch;
