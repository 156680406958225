import * as React from 'react';
import { useMutation } from 'redux-query-react';
import { useContext, useState } from 'react';
import LayoutSplit from 'ekaubamaja-ui/lib/Layouts/LayoutSplit';
import LayoutSplitColumn from 'ekaubamaja-ui/lib/Layouts/LayoutSplitColumn';
import LayoutForm from 'ekaubamaja-ui/lib/Layouts/LayoutForm';
import FormRow from 'ekaubamaja-ui/lib/Components/FormRow';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import RedirectAbsolute, { IRedirectProps } from 'components/Redirect/RedirectAbsolute';
import Toaster from 'ekaubamaja-ui/lib/Components/Toaster';
import { empty } from '../../../helpers/empty';
import { useRef } from 'react';
import { MutableRefObject } from 'react';
import { RequestBody } from 'redux-query';
import { CLIENT_TYPE } from 'components/Checkout/enums/clientType';
import { IShippingAddress } from 'components/Checkout/interfaces/checkout/address/IShippingInformationData';
import { IPaymentMethodExtraData } from 'components/Checkout/interfaces/payment/IPaymentMethodExtraData';
import { IPaymentMethodResponse } from 'components/Checkout/interfaces/payment/IPaymentMethodResponse';
import { ICart } from 'components/Checkout/interfaces/checkout/cart/cartType';
import { IShippingMethod } from 'components/Checkout/interfaces/checkout/shipping/IShippingMethod';
import { CartContext } from 'components/Checkout/shipping/ShippingContent';
import { IAddress, ICustomer } from 'components/Checkout/interfaces/checkout/customer/ICustomer';
import { postRequest } from 'components/Checkout/requests/postRequest';
import isLoggedIn from '../../../helpers/auth/isLoggedIn';
import { ResponseStatus } from '../../../enums/ResponseStatus';
import SelectedAddressToPostAddress from 'components/Checkout/shipping/address/selectedAddressToPostAddress';
import HandleMethod, { PaymentMethodEnum } from 'components/Checkout/payment/MethodHandler';
import useOverlays from 'components/Checkout/overlay/Overlay';
import Banklinks from 'components/Checkout/payment/Banklinks';
import Agreements from 'components/Checkout/payment/Agreements';
import LoadingOverlay from 'components/Checkout/LoadingOverlay';

export interface IPaymentOnClickInput {
    paymentMethodExtraData?: IPaymentMethodExtraData;
    redirect?: IRedirectProps;
    rawMethodData?: any;
}

export interface IPaymentMethodProps {
    method: IPaymentMethodResponse;
    clientType: CLIENT_TYPE;
    paymentMethodExtraData: MutableRefObject<IPaymentMethodExtraData | undefined> | undefined;
    onClick: (input: IPaymentOnClickInput) => void;
    cart: ICart | undefined;
    email: string | undefined;
    setAllowQuery: (allowQuery: boolean) => void;
    extensionAttributes: () => {
        risks_awareness?: string;
        agreement_ids?: string[];
    };
    shippingMethod: IShippingMethod | undefined;
}

interface IProps {
    customer: ICustomer | null;
    paymentMethods: IPaymentMethodResponse[] | null;
    email: string | undefined;
    cart: ICart | undefined;
    selectedMethod: IShippingMethod | undefined;
    selectedAddress: IShippingAddress | null;
}

export interface ITerms {
    id: string;
    accepted: boolean;
}

const PaymentBlock = (props: IProps) => {
    const { t } = useTranslation();
    const { openOverlay, closeOverlay } = useOverlays();
    const { customer, paymentMethods, email, cart } = props;
    const allowQuery = useRef(false);
    const [updateState, setUpdateState] = useState(false);
    const cartContext = useContext(CartContext);
    const [redirectUrl, setRedirectUrl] = useState<IRedirectProps | undefined>();
    const [methodCode, setMethodCode] = useState<string>();
    const [methodName, setMethodName] = useState();
    const [selectedAddress, selectAddress] = useState<IAddress>();
    const [errors, setErrors] = useState(false);
    const [clientType, setClientType] = useState(CLIENT_TYPE.CLIENT_REGULAR);
    const [terms, setTerms] = useState<ITerms[]>(
        window.agreements?.totalRecords > 0
            ? window.agreements.items.map((item) => ({
                  id: item.agreement_id,
                  accepted: false,
              }))
            : [],
    );
    const paymentMethodRenderers: React.Component<any, any>[] = [];
    const rawData = useRef();
    const paymentMethodExtraData = useRef<Partial<IPaymentMethodExtraData>>();

    const [{ isFinished, status }, postData] = useMutation((data: RequestBody, url: string) =>
        postRequest({ type: 'response', url, data, useStoreCode: true }),
    );
    const getExtensionAttributes = () => {
        return {
            agreement_ids: terms.filter((item) => item.accepted).map((item) => item.id),
        };
    };
    const setAllowQuery = (state: boolean) => {
        allowQuery.current = state;
        setUpdateState(!updateState);
    };

    if (!paymentMethodRenderers.length) {
        const isFreePaymentMethod = paymentMethods?.find((method) => method.code === PaymentMethodEnum.free);

        let MethodComponent;

        // free payment method is returned only if grandTotal is <=0, if presents show only free payment method
        if (isFreePaymentMethod) {
            const onClick = (input: IPaymentOnClickInput) => {
                rawData.current = input.rawMethodData;
                setMethodCode(isFreePaymentMethod.code);
                paymentMethodExtraData.current = input.paymentMethodExtraData;
                setRedirectUrl(input.redirect);
                window.dispatchEvent(new CustomEvent('business-address-save'));
                window.dispatchEvent(new CustomEvent('subscription-save'));
                if (clientType !== CLIENT_TYPE.CLIENT_BUSINESS || (selectedAddress && !selectedAddress.isNew)) {
                    setAllowQuery(true);
                }
            };
            MethodComponent = HandleMethod({
                method: isFreePaymentMethod,
                clientType,
                paymentMethodExtraData,
                onClick,
                cart,
                email,
                extensionAttributes: getExtensionAttributes,
                setAllowQuery,
                shippingMethod: props.selectedMethod,
            });
            paymentMethodRenderers.push(MethodComponent);
        }

        paymentMethods?.forEach((method: IPaymentMethodResponse, index, array) => {
            if (isFreePaymentMethod) {
                return;
            }
            const onClick = (input: IPaymentOnClickInput) => {
                rawData.current = input.rawMethodData;
                setMethodCode(method.code);
                setMethodName(input?.rawMethodData?.name);
                paymentMethodExtraData.current = input.paymentMethodExtraData;
                setRedirectUrl(input.redirect);
                window.dispatchEvent(new CustomEvent('business-address-save'));
                window.dispatchEvent(new CustomEvent('subscription-save'));

                if (clientType !== CLIENT_TYPE.CLIENT_BUSINESS || (selectedAddress && !selectedAddress.isNew)) {
                    setAllowQuery(true);
                }
            };

            MethodComponent = HandleMethod({
                method,
                clientType,
                paymentMethodExtraData,
                onClick,
                cart,
                email,
                extensionAttributes: getExtensionAttributes,
                setAllowQuery,
                shippingMethod: props.selectedMethod,
            });
            if (MethodComponent && !paymentMethodRenderers.includes(MethodComponent)) {
                paymentMethodRenderers.push(MethodComponent);
            }
        });
    }

    const queryAction = () => {
        if (allowQuery.current) {
            let hasErrors = !!terms.find((term) => !term.accepted);
            if (methodCode) {
                setErrors(hasErrors);
            }
            if (!hasErrors && methodCode) {
                openOverlay('payment-loading-overlay', true);
                setAllowQuery(false);
                let address;
                if (selectedAddress && clientType === CLIENT_TYPE.CLIENT_BUSINESS) {
                    address = SelectedAddressToPostAddress(selectedAddress);
                }

                const addressInformation = {
                    ...paymentMethodExtraData.current?.extraParams,
                    email,
                    billing_address: typeof address !== 'undefined' ? address : undefined,
                    paymentMethod: {
                        method: methodCode,
                        extension_attributes: getExtensionAttributes(),
                        additional_data: { ...paymentMethodExtraData.current?.additionalData },
                    },
                };

                window.dispatchEvent(
                    new CustomEvent('checkout-step-proceed', {
                        detail: {
                            action: 'add-payment-info',
                            method: { methodCode, methodName },
                            items: cartContext.cartItems,
                            customer: cartContext.customer,
                        },
                    }),
                );

                const url =
                    paymentMethodExtraData.current?.paymentVerificationUrl ||
                    (isLoggedIn()
                        ? 'carts/mine/payment-information'
                        : `guest-carts/${window.quoteIdMask}/payment-information`);
                postData(
                    {
                        ...addressInformation,
                    },
                    url,
                ).then((response) => {
                    const responseSuccessful = response && response.status === ResponseStatus.ok;
                    if (responseSuccessful) {
                        window.dispatchEvent(
                            new CustomEvent('payment-response-receive', {
                                detail: {
                                    methodCode,
                                    paymentResponse: response.body,
                                    rawData: rawData.current,
                                    setRedirectUrl,
                                    customer,
                                },
                            }),
                        );
                    }

                    if (!responseSuccessful && response?.body?.message) {
                        closeOverlay('payment-loading-overlay', false);
                        setMethodCode(undefined);
                        setAllowQuery(true);

                        Toaster.addToast({
                            intent: 'danger',
                            text: response.body.message,
                            asHtml: true,
                        });
                    }
                    if (response.body && response.body.code === 455) {
                        setRedirectUrl({ to: `checkout/cart` });
                    }
                });
            } else {
                setMethodCode(undefined);
            }
        }
    };
    useEffect(queryAction);

    useEffect(() => {
        if (isFinished && redirectUrl && !empty(redirectUrl.to)) {
            if (status === 400) {
                setTimeout(() => RedirectAbsolute({ to: `checkout` }), 2000);
            } else {
                RedirectAbsolute(redirectUrl);
            }
        }
    });

    return (
        <LayoutSplit>
            <LayoutSplitColumn>
                <Agreements
                    terms={terms}
                    setTerms={setTerms}
                    errors={errors}
                />
                <LayoutForm layout="vertical">
                    <FormRow
                        label={<b>{t('checkout.Payment Method:')}</b>}
                        description={t('checkout.You will be redirected back to us after payment')}
                    >
                        <Banklinks components={paymentMethodRenderers} />
                    </FormRow>
                </LayoutForm>
            </LayoutSplitColumn>
            <LoadingOverlay index={'payment-loading-overlay'} />
        </LayoutSplit>
    );
};

export default PaymentBlock;
