import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useMutation } from 'redux-query-react';
import shareQuoteRequest from 'components/Wishlist/requests/shareQuoteRequest';
import Overlay from 'ekaubamaja-ui/lib/Components/Overlay';
import Block from 'ekaubamaja-ui/lib/Components/Block';
import LayoutForm from 'ekaubamaja-ui/lib/Layouts/LayoutForm';
import FormRow from 'ekaubamaja-ui/lib/Components/FormRow';
import FormRowGroup from 'ekaubamaja-ui/lib/Components/FormRowGroup';
import ControlInput from 'ekaubamaja-ui/lib/Components/ControlInput';
import Button from 'ekaubamaja-ui/lib/Components/Button';

interface IProps {
    config: {
        quoteId: string;
        labels: IQuoteShareLabels;
    };
}

export interface IQuoteShareLabels {
    shareButton: string;
    modalTitle: string;
    modalText: string;
    copyButton: string;
    onClick: () => void;
}

const ShareQuote: React.FunctionComponent<IProps> = (props) => {
    const { config } = props;
    const shareInputRef = React.useRef<HTMLInputElement>();

    const [{}, shareRequest] = useMutation(() => shareQuoteRequest(props.config.quoteId));

    const [showModal, setShowModal] = useState(false);
    const [useParentClick, setUseParentClick] = useState(false);
    const [generatedUrl, setGeneratedUrl] = useState('');

    const handleClick = useCallback(async () => {
        const response = await shareRequest();

        if (response.status === 200) {
            setGeneratedUrl(response.body.url);
            setShowModal(true);
        }
    }, []);

    const closeModal = () => {
        setShowModal(false);
    };

    // handle click of parent element if outline area is big
    useEffect(() => {
        const parent = document.getElementById('parentShareQuote');
        if (parent) {
            parent.onclick = (e) => {
                e.preventDefault();
                handleClick();
            };
            setUseParentClick(true);
        }
    }, []);

    return !showModal ? (
        <React.Fragment>
            <span onClick={() => (!useParentClick ? handleClick() : {})}>{config.labels.shareButton}</span>
        </React.Fragment>
    ) : (
        <Overlay
            layout="focusview"
            title={config.labels.modalTitle}
            isOpen={showModal}
            doClose={() => {
                closeModal();
            }}
            buttonIcons={[
                {
                    icon: 'close',
                    intent: 'link',
                    onClick: (e) => {
                        e.preventDefault();
                        closeModal();
                    },
                },
            ]}
            size="medium"
        >
            <Block>
                <LayoutForm layout="vertical">
                    <FormRow label={config.labels.modalText}>
                        <FormRowGroup>
                            <ControlInput
                                value={generatedUrl}
                                inputRef={(r) => {
                                    shareInputRef.current = r;
                                }}
                                onFocus={() => {
                                    if (shareInputRef.current) {
                                        shareInputRef.current.select();
                                        document.execCommand('copy');
                                    }
                                }}
                            />
                            <Button
                                title={config.labels.copyButton}
                                onClick={() => {
                                    if (shareInputRef.current) {
                                        shareInputRef.current.select();
                                        document.execCommand('copy');
                                    }
                                }}
                            />
                        </FormRowGroup>
                    </FormRow>
                </LayoutForm>
            </Block>
        </Overlay>
    );
};

export default ShareQuote;
