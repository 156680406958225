import React from 'react';

interface IProps {
    formKey?: string;
}

const FormKey = (props: IProps): JSX.Element => {
    const formKey = props.formKey ?? (window as any).form_key;

    return (
        <input
            type="hidden"
            name="form_key"
            value={formKey}
        />
    );
};

export default FormKey;
