import * as React from 'react';
import useAuth, { ICustomer } from '../../hooks/useAuth';
import { useEffect, useState } from 'react';

const CustomerName = () => {
    const [customerState, setCustomerState] = useState<ICustomer>();
    const { customer } = useAuth();

    useEffect(() => {
        if (customer) {
            setCustomerState(customer);
        }
    }, [customer]);

    return <React.Fragment>{customer?.name}</React.Fragment>;
};
export default CustomerName;
