export interface ISelectedAttributes {
    attributeId: string;
    optionId: string;
}

export interface IProductRequest {
    cartItem: {
        sku: string;
        qty: number;
        quote_id: string;
        product_type: string;
        product_option: {
            extension_attributes: {
                configurable_item_options: {
                    option_id: string;
                    option_value: string;
                }[];
            };
        };
    };
}

const formProductRequest = (
    quoteId: string,
    sku: string,
    qty?: number,
    selectedAttributes?: ISelectedAttributes[],
): IProductRequest => {
    let selectedAttributeFields: { option_id: string; option_value: string }[] = [];
    if (selectedAttributes) {
        selectedAttributeFields = selectedAttributes.map((item) => ({
            option_id: item.attributeId,
            option_value: item.optionId,
        }));
    }
    return {
        cartItem: {
            sku: sku,
            qty: qty ? qty : 1,
            quote_id: quoteId,
            product_type: 'simple',
            product_option: {
                extension_attributes: {
                    configurable_item_options: selectedAttributeFields,
                },
            },
        },
    };
};

export default formProductRequest;
