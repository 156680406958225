import { request } from 'data/requests/request';

const deleteWishlistRequest = (id: string) =>
    request({
        type: 'deleteWishlist',
        url: `wishlist/wishlist/delete`,
        method: 'POST',
        data: { id },
        notApi: true,
        absolute: false,
    });

export default deleteWishlistRequest;
