const getActiveCategories = (): string[] => {
    return (window as unknown as { currentCategoryIds: string[] }).currentCategoryIds || ([] as string[]);
};

const applyByUrl = (menuLinks: NodeListOf<Element>, currentPath: string) => {
    let haveActiveByUrl: boolean = false;

    menuLinks.forEach((link: Element): void => {
        const linkAttribute: string | null = link.getAttribute('href');
        if (linkAttribute) {
            const linkPath = linkAttribute.replace(/^\/|\/$/g, '');
            if (linkPath === currentPath) {
                link.classList.add('active');
                haveActiveByUrl = true;
                const popoverWrapper: HTMLUListElement | null = link.closest('ul');
                if (popoverWrapper) {
                    const popoverWrapperLi: HTMLLIElement | null = popoverWrapper.closest('li');

                    if (popoverWrapperLi) {
                        // @ts-ignore
                        const sibling: HTMLElement | null = popoverWrapperLi.firstChild;

                        if (sibling && sibling.getAttribute('href') === '#') {
                            sibling.classList.add('open', 'active');
                            haveActiveByUrl = true;
                        }
                    }
                }
            }
        }
    });
    return haveActiveByUrl;
};

const applyBySavedCategoryId = () => {
    getActiveCategories().forEach((categoryId: string): void => {
        const parentElements: HTMLCollectionOf<Element> = document.getElementsByClassName(
            `category-menu-element-${categoryId}`,
        );
        if (parentElements && parentElements.length > 0) {
            parentElements[0].classList.add('open', 'active');
        }

        const childElement: HTMLElement | null = document.getElementById(`category-menu-element-${categoryId}`);

        if (childElement) {
            childElement.classList.add('open', 'active');
        }
    });
};

export const setActiveLink = (): void => {
    const path: string = window.location.pathname;
    const currentPath: string = path.replace(/^\/|\/$/g, '');
    if (currentPath) {
        const menuLinks: NodeListOf<Element> = document.querySelectorAll('.site-menu ul li a');
        const haveActiveByUrl: boolean = applyByUrl(menuLinks, currentPath);

        if (
            !haveActiveByUrl &&
            ((window as unknown as { currentCategoryIds: string[] }).currentCategoryIds || []).length > 0
        ) {
            applyBySavedCategoryId();
        }
    }
};
