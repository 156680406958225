import * as React from 'react';
import { IReturnActionProps } from 'components/Return/returnActions';
import Block from 'ekaubamaja-ui/lib/Components/Block';
import ControlInput from 'ekaubamaja-ui/lib/Components/ControlInput';
import LayoutForm from 'ekaubamaja-ui/lib/Layouts/LayoutForm';
import FormRow from 'ekaubamaja-ui/lib/Components/FormRow';
import ProductReturnTable, { IReturnProduct } from 'components/Catalog/Product/Return/Table';
import { useMutation, useRequest } from 'redux-query-react';
import { request } from 'data/requests/request';
import { useDispatch, useSelector } from 'react-redux';
import RenderHTML from 'ekaubamaja-ui/lib/Components/RenderHTML';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { closeOverlay } from 'data/overlays/overlayHandler';
import Button from 'ekaubamaja-ui/lib/Components/Button';
import Buttons from 'ekaubamaja-ui/lib/Components/Buttons';
import ControlTextarea from 'ekaubamaja-ui/lib/Components/ControlTextarea/index';
import { useState } from 'react';
import orderReturnRequest, { IOrderReturnRequest } from 'data/requests/orderReturnRequest';
import Toaster from 'ekaubamaja-ui/lib/Components/Toaster';

export interface IReturnFormLabels {
    conditions?: string;
    extraInformationText?: string;
    hasBeenReturned: string;
    firstname: string;
    lastname: string;
    email: string;
    phone: string;
    orderNumber: string;
    productsToReturn: string;
    returnReason: string;
    notes: string;
    emailMustBeValidErrorMessage: string;
    requiredField: string;
    cancel: string;
    send: string;
    noProductsSelected: string;
    somethingWentWrong: string;
}

interface IOrderReturnDetailedResponse {
    orderReturnResponse: {
        products: IReturnProduct[];
    };
}

const mapStateToProps = (state): IOrderReturnDetailedResponse => {
    return {
        orderReturnResponse: state.entities?.orderReturn,
    };
};

const ReturnForm: React.FunctionComponent<IReturnActionProps> = (props) => {
    const { orderId, labels, orderNumber, formData, hasBeenReturned } = props.config;
    const dispatch = useDispatch();
    const [productsSelected, setProductsSelected] = useState<IReturnProduct[]>([]);

    const [{ isFinished }] = useRequest(
        request({
            type: `orderReturn`,
            url: `order_return/order/ajax/order_id/${orderId}`,
            notApi: true,
        }),
    );

    const [{}, triggerOrderReturnRequest] = useMutation((data: IOrderReturnRequest) => orderReturnRequest(data));

    const { orderReturnResponse } = useSelector(mapStateToProps);

    const FormDataValidationSchema = {
        firstName: Yup.string().nullable().required(labels.requiredField),
        lastName: Yup.string().nullable().required(labels.requiredField),
        email: Yup.string().nullable().email(labels.emailMustBeValidErrorMessage).required(labels.requiredField),
        phone: Yup.string().nullable().required(labels.requiredField),
        notes: Yup.string(),
        returnReason: Yup.string(),
    };

    const formik = useFormik({
        initialValues: {
            ...formData,
            returnReason: '',
            notes: '',
        },
        validationSchema: Yup.object().shape(FormDataValidationSchema),
        validateOnChange: false,
        validateOnBlur: true,
        onSubmit: () => {
            if (productsSelected.length === 0) {
                Toaster.addToast({
                    intent: 'danger',
                    text: labels.noProductsSelected,
                    asHtml: true,
                });
                formik.setSubmitting(false);
                return;
            }
            const data = { ...formik.values, products: productsSelected, orderId };
            triggerOrderReturnRequest(data)
                .then((response) => {
                    Toaster.addToast({
                        intent: 'success',
                        text: response.body.message,
                        asHtml: true,
                    });
                    dispatch(closeOverlay({ name: 'all' }));
                })
                .catch((error) => {
                    Toaster.addToast({
                        intent: 'danger',
                        text: labels.somethingWentWrong,
                        asHtml: true,
                    });
                });
        },
    });

    return (
        <Block>
            {labels.conditions && (
                <RenderHTML
                    html={`<p>${labels.conditions}</p>`}
                    nowrapper={true}
                />
            )}
            <LayoutForm>
                <FormRow
                    label={labels.firstname}
                    required={true}
                    error={formik.errors.firstName}
                >
                    <ControlInput
                        name={'firstName'}
                        value={formik.values.firstName ?? ''}
                        onChange={formik.handleChange}
                    />
                </FormRow>
                <FormRow
                    label={labels.lastname}
                    required={true}
                    error={formik.errors.lastName}
                >
                    <ControlInput
                        name={'lastName'}
                        value={formik.values.lastName}
                        onChange={formik.handleChange}
                    />
                </FormRow>
                <FormRow
                    label={labels.email}
                    required={true}
                    error={formik.errors.email}
                >
                    <ControlInput
                        name={'email'}
                        value={formik.values.email}
                        onChange={formik.handleChange}
                    />
                </FormRow>
                <FormRow
                    label={labels.phone}
                    required={true}
                    error={formik.errors.phone}
                >
                    <ControlInput
                        name={'phone'}
                        value={formik.values.phone}
                        onChange={formik.handleChange}
                    />
                </FormRow>
                <FormRow label={labels.orderNumber}>
                    <ControlInput
                        value={orderNumber}
                        disabled={true}
                    />
                </FormRow>
                <h3>{labels.productsToReturn}</h3>
                {isFinished && !!orderReturnResponse?.products && (
                    <ProductReturnTable
                        products={orderReturnResponse.products}
                        labels={labels}
                        productsSelected={productsSelected}
                        setProductsSelected={setProductsSelected}
                    />
                )}
                <FormRow label={labels.returnReason}>
                    <ControlTextarea
                        name={'returnReason'}
                        minRows={3}
                        value={formik.values.returnReason}
                        onChange={formik.handleChange}
                    />
                </FormRow>
                {labels.extraInformationText && (
                    <RenderHTML
                        html={`<p>${labels.extraInformationText}</p>`}
                        nowrapper={true}
                    />
                )}
                <Buttons layout="reversed">
                    <Button
                        title={labels.send}
                        intent="primary"
                        disabled={formik.isSubmitting}
                        onClick={(e) => {
                            e.preventDefault();
                            formik.submitForm();
                        }}
                    />
                    <Button
                        title={labels.cancel}
                        intent="discrete"
                        onClick={(e) => {
                            e.preventDefault();
                            dispatch(closeOverlay({ name: 'all' }));
                        }}
                    />
                </Buttons>
            </LayoutForm>
        </Block>
    );
};

export default ReturnForm;
