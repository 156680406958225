import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useMutation } from 'redux-query-react';
import saveCookieRequest from 'data/requests/cookie/saveCookieRequest';
import Toaster from 'ekaubamaja-ui/lib/Components/Toaster/index';
import Cookies from 'js-cookie';
import CookieSettings from 'components/Cookie/cookieSetting';
import { useDispatch } from 'react-redux';
import { closeOverlay } from 'data/overlays/overlayHandler';

export interface ICookieProps {
    config: ICookieConfig;
}

export interface ICookieConfig {
    modalTitle: string;
    introText: string;
    settingsLabel: string;
    agreeToAllLabel: string;
    notAgreeLabel: string;
    agreeToSelectedLabel: string;
    cookieSelection: ICookieSelection[];
    showSettingsStraightAway?: boolean;
}

export interface ICookieSelection {
    id: number;
    code: string;
    name: string;
    description: string;
    is_required: boolean;
    isChecked: boolean;
}

declare const gtag: Function;

const hasGdprCookieToken = (): boolean => {
    return !!Cookies.get('gdpr_cookie_token');
};

const Cookie: React.FunctionComponent<ICookieProps> = (props) => {
    const dispatch = useDispatch();

    const [{}, saveRequest] = useMutation((cookies: ICookieSelection[]) => saveCookieRequest(cookies));

    const [showSettings, setShowSettings] = useState(props.config.showSettingsStraightAway);
    const [defaultConsentSet, setDefaultConsentSet] = useState(false);

    const handleAcceptAll = useCallback(async () => {
        const cookiesToSend = props.config.cookieSelection.map((cookieSelection: ICookieSelection) => {
            cookieSelection.isChecked = true;
            return cookieSelection;
        });
        saveData(cookiesToSend);
    }, []);

    const saveData = (cookiesToSend: ICookieSelection[]) => {
        saveRequest(cookiesToSend).then((response) => {
            if (response.body) {
                Toaster.addToast({
                    intent: response.body.errors ? 'danger' : 'success',
                    text: response.body.message,
                    asHtml: true,
                });
                dispatch(closeOverlay({ name: 'all' }));
            }
        });
    };

    useEffect(() => {
        if (showSettings) {
            document.body.closest('html')?.classList.add('overlayopen');
        } else {
            document.body.closest('html')?.classList.remove('overlayopen');
        }
        return function cleanup() {
            document.body.closest('html')?.classList.remove('overlayopen');
        };
    }, [showSettings]);

    if (!hasGdprCookieToken() && !defaultConsentSet) {
        gtag('consent', 'default', {
            security_storage: 'granted',
            analytics_storage: 'denied',
            ad_user_data: 'denied',
            ad_personalization: 'denied',
            ad_storage: 'denied',
            functionality_storage: 'denied',
            personalization_storage: 'denied',
        });
        setDefaultConsentSet(true);
    }

    if (hasGdprCookieToken()) {
        return <React.Fragment />;
    }

    return !showSettings ? (
        <React.Fragment>
            <div className="cookiemessage">
                <div className="cookiemessage__limiter">
                    <div
                        dangerouslySetInnerHTML={{ __html: props.config.introText }}
                        className="content"
                    />
                    <div className="actions">
                        <a
                            href="#"
                            onClick={(e) => {
                                e.preventDefault();
                                handleAcceptAll();
                            }}
                        >
                            {props.config.agreeToAllLabel}
                        </a>
                        <a
                            href="#"
                            onClick={(e) => {
                                e.preventDefault();
                                setShowSettings(!showSettings);
                            }}
                        >
                            {props.config.settingsLabel}
                        </a>
                    </div>
                </div>
            </div>
        </React.Fragment>
    ) : (
        <CookieSettings
            config={props.config}
            saveData={saveData}
            handleAcceptAll={handleAcceptAll}
        />
    );
};

Cookie.displayName = 'Cookie';

export default Cookie;
