import { request } from 'data/requests/request';
import { CookieType } from 'components/Gdpr/enums/CookieType';

const reorderRequest = (url: string) =>
    request({
        type: 'reorderRequest',
        url,
        method: 'POST',
        notApi: true,
        absolute: true,
        force: true,
    });

export default reorderRequest;
