import * as React from 'react';
import { ISidebarLabels } from 'components/Cart/Interfaces/ICartLabels';
import { empty } from '../../../helpers/empty';
import AddAllToWishlist from 'components/Cart/Buttons/AddAllToWishlist';

interface IProps {
    config: {
        isWishlistProduct?: boolean;
        labels: ISidebarLabels;
        product: {
            sku: string;
            id: string;
        };
    };
}

const AddAllToWishlistWrapper: React.FunctionComponent<IProps> = (props) => {
    const { config } = props;

    return (
        <>
            <AddAllToWishlist
                hasProductInAnyWishlist={!empty(config.isWishlistProduct)}
                setHasProductInAnyWishlist={() => {}}
                renderAddOne={true}
                products={[
                    {
                        quoteId: '',
                        sku: config?.product?.sku,
                        productId: config?.product?.id,
                        qty: {
                            value: '1',
                        },
                    },
                ]}
                labels={config.labels}
            />
        </>
    );
};

export default AddAllToWishlistWrapper;
