import * as React from 'react';
import { useCallback } from 'react';
import { useMutation } from 'redux-query-react';
import Toaster from 'ekaubamaja-ui/lib/Components/Toaster';
import { extractErrors } from '../../../helpers/request/extractErrors';
import { ICartProduct } from 'components/Cart/Interfaces/ICartProduct';
import quoteIdRequest from 'data/requests/product/quoteIdRequest';
import formProductRequest, { IProductRequest } from 'data/requests/product/data/formProductRequest';
import toCartRequest from 'data/requests/product/toCartRequest';
import { ICartLabels } from 'components/Cart/Interfaces/ICartLabels';

interface IProps {
    products: ICartProduct[];
    labels: ICartLabels;
}

const AddAllToCartFromWishlist: React.FunctionComponent<IProps> = (props) => {
    const { labels, products } = props;

    const [{}, quoteRequest] = useMutation(() => quoteIdRequest());
    const [{}, addToCartRequest] = useMutation((data: IProductRequest, quoteIdMask: string) =>
        toCartRequest(data, quoteIdMask),
    );

    const addToCart = useCallback(
        async (product: ICartProduct, quoteId) => {
            const productRequestData: IProductRequest = formProductRequest(
                quoteId,
                product.sku,
                parseInt(product.qty.value),
            );
            const response = await addToCartRequest(productRequestData, quoteId);
            if (response.status !== 200) {
                const errors = extractErrors(response);
                if (errors && errors.error) {
                    Toaster.addToast({
                        intent: 'danger',
                        text: errors.error,
                        asHtml: true,
                    });
                }
            }
        },
        [addToCartRequest, quoteRequest],
    );

    const addAllToCart = async () => {
        const quoteIdResponse = await quoteRequest();
        if (quoteIdResponse.status !== 200) {
            window.location.reload();
        }

        const { quoteId } = quoteIdResponse.body;

        const promises: Promise<any>[] = [];
        products
            .filter((product) => !product.isPawn)
            .map((product: ICartProduct) => {
                promises.push(addToCart(product, quoteId));
            });

        Promise.all(promises).then(() => {
            Toaster.addToast({
                intent: 'success',
                text: labels.sidebar.addAllCartSuccess,
                asHtml: true,
            });
            window.dispatchEvent(new CustomEvent('cart-altered'));
        });
    };

    return (
        <button
            onClick={() => addAllToCart()}
            className="cart-button primary"
        >
            {labels.sidebar.moveAllToCart}
        </button>
    );
};

export default AddAllToCartFromWishlist;
