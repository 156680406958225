import { request } from 'data/requests/request';

const addWishlistRequest = (name: string) =>
    request({
        type: 'addWishlist',
        url: `wishlist/wishlist/add`,
        method: 'POST',
        data: { name },
        notApi: true,
        absolute: false,
    });

export default addWishlistRequest;
