import * as React from 'react';
import ShowError from 'components/error/ShowError';
import { empty } from '../../helpers/empty';

let setError;

interface IProps {
    setError: (error: boolean) => void;
}

/**
 * Used to show general error message on screen
 */
const useErrorState = (props?: IProps) => {
    if (empty(setError) && props) {
        setError = props.setError;
    }

    return {
        setError,
        errorComponent: <ShowError />,
    };
};
export default useErrorState;
