import * as React from 'react';
import { ICartProduct } from 'components/Cart/Interfaces/ICartProduct';
import { CartType } from '../../../enums/CartType';
import { ICartLabels } from 'components/Cart/Interfaces/ICartLabels';
import AddAllToWishlist from 'components/Cart/Buttons/AddAllToWishlist';
import { isArray } from 'lodash';
import { useState } from 'react';
import Alter from 'components/Cart/Buttons/Alter';
import Remove from 'components/Cart/Buttons/Remove';
import { formatPriceLocale } from '../../../helpers/priceUtils';
import BoxProductPrices from 'components/Catalog/CategoryList/BoxProductPrices';
import BoxProductPrice from 'components/Catalog/CategoryList/BoxProductPrice';

export interface ICartProductProps {
    labels: ICartLabels;
    quoteId?: string;
    cartType: string;
    product: ICartProduct;
    setError: (message: string) => void;
    wishlistProductIds?: string[];
}

const Product = (props: ICartProductProps) => {
    const { product, quoteId, setError, cartType, labels, wishlistProductIds } = props;
    const [currentAmountOver, setCurrentAmountOver] = useState(false);

    const productInAnyWishlist = isArray(wishlistProductIds)
        ? !!wishlistProductIds.find(
              (productId: string) => parseInt(product.productId.toString()) === parseInt(productId),
          )
        : false;
    const [hasProductInAnyWishlist, setHasProductInAnyWishlist] = useState(productInAnyWishlist);

    return (
        <React.Fragment>
            <div className={`layout-products__container`}>
                <div className="box-product">
                    <a
                        href={product.disableCartEdit ? undefined : product.url}
                        className="box-product__link"
                    >
                        {product.name}
                    </a>
                    <div className="box-product__thumb">
                        <span
                            className="box-product__thumb__image"
                            style={{ backgroundImage: 'url(' + product.image + ')' }}
                        />
                        {cartType !== CartType.WISHLIST && (
                            <div className="box-product__wishlist">
                                <AddAllToWishlist
                                    hasProductInAnyWishlist={hasProductInAnyWishlist}
                                    setHasProductInAnyWishlist={setHasProductInAnyWishlist}
                                    renderAddOne={true}
                                    products={[product]}
                                    labels={labels.sidebar}
                                />
                            </div>
                        )}
                    </div>
                    <div className="box-product__content">
                        <div className="box-product__text">
                            <div className="box-product__title">
                                <span className="title-label">
                                    <span className="title-primary">{product.name}</span>
                                </span>
                            </div>
                            <div className="box-product__description">{product.shortDescription}</div>
                        </div>
                    </div>
                    <BoxProductPrices
                        price={product.price}
                        discountPercent={product.discountPercent}
                        specialPrice={product.specialPrice}
                        unitPrice={product.unitPrice}
                        unitPriceUnit={product.unitPriceUnit}
                        hideOldPrice={product.hideOldPrice}
                        isListDiscount={product.isListDiscount}
                    />
                    <div className="box-product__cart">
                        <div className="cart-control">
                            <div className="cart-control__row">
                                <Alter
                                    labels={labels.amountSize}
                                    product={product}
                                    quoteId={quoteId}
                                    setError={setError}
                                    setCurrentAmountOver={setCurrentAmountOver}
                                    isCheckout={cartType === CartType.CHECKOUT}
                                    isWishlist={cartType === CartType.WISHLIST}
                                />
                                {quoteId && labels.togetherSum && !product.disableCartEdit && (
                                    <Remove
                                        quoteId={quoteId}
                                        isWishlist={cartType === CartType.WISHLIST}
                                        isCheckout={cartType === CartType.CHECKOUT}
                                        setError={setError}
                                        labels={labels}
                                        product={product}
                                    />
                                )}
                            </div>
                            {currentAmountOver && (
                                <div className="cart-control__row">
                                    <p className="cart-control__error">{labels.productQtyNotAvailable}</p>
                                </div>
                            )}
                            <div className="cart-control__row">
                                <div className="cart-control__extra">
                                    <span className="cart-control__extra__block">
                                        {labels.togetherSum}:{' '}
                                        <b>
                                            {`${formatPriceLocale(
                                                (product.specialPrice ?? product.price) * parseInt(product.qty.value),
                                            )}`}
                                        </b>
                                        {!!product.pawnValue && (
                                            <BoxProductPrice
                                                type={'pledge'}
                                                price={product.pawnValue * parseInt(product.qty.value)}
                                                pricePrefixText={labels.pawnLabel}
                                            />
                                        )}
                                    </span>
                                    {!!product.isListDiscount && (
                                        <span className="cart-control__extra__block">
                                            {labels.includingDiscount} -{product.discountPercent}%
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Product;
