import { request } from 'data/requests/request';

const newsletterRequest = (email: string) =>
    request({
        type: 'newsletterSubscribe',
        url: 'newsletter/newsletter/subscribe',
        data: { email },
        method: 'POST',
        notApi: true,
    });

export default newsletterRequest;
