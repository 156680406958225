import * as React from 'react';

const ThemeWrapper: React.FunctionComponent = (props) => {
    const theme = process.env?.SITE || '';

    return (
        <React.Fragment>
            <div className={`frame-${theme}__body`}>
                <div className={`frame-${theme}__body__limiter`}>{props.children}</div>
            </div>
        </React.Fragment>
    );
};

export default ThemeWrapper;
