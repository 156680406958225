import { cloneDeep } from 'lodash';
import { IProductsState } from 'components/Common/store/productsSelector';

export enum dispatchType {
    PRODUCTS_SET = 'PRODUCTS_SET',
}

const initialState: IProductsState = {
    products: [],
    partiallyFilteredProducts: [],
};

interface IAction {
    type: dispatchType;
    payload: IProductsState;
}

const productsReducer = (state: IProductsState = cloneDeep(initialState), action: IAction): IProductsState => {
    const handleState = (payload: IProductsState) => {
        return {
            ...state,
            products: payload.products,
            partiallyFilteredProducts: payload.partiallyFilteredProducts,
        };
    };

    switch (action.type) {
        case dispatchType.PRODUCTS_SET: {
            return handleState(action.payload);
        }

        default: {
            return state;
        }
    }
};

export default productsReducer;
