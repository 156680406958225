import * as React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setGridViewState } from 'components/Common/store/gridViewHandler';
import { IGridViewState } from 'components/Common/store/gridViewSelector';
import { IStoreState } from '../../helpers/rootReducer';

interface IProps {
    viewLabel: string;
}

const GridComponent: React.FunctionComponent<IProps> = (props) => {
    const { viewLabel } = props;
    const dispatch = useDispatch();
    const { isListView } = useSelector((state: IStoreState) => state.gridViewReducer);

    const setListView = (isListView: boolean) => {
        dispatch(setGridViewState({ isListView }));
    };

    return (
        <label>
            <span className="label">{viewLabel}</span>
            <span className="pillbutton">
                <a
                    onClick={(e) => {
                        e.preventDefault();
                        setListView(false);
                    }}
                    className={!isListView ? 'active' : ''}
                    data-key="mode"
                    data-value="grid"
                    href="#"
                >
                    <span className="icon">
                        <img
                            src={`${window.assetUrl}/assets/img/sprite_grid.svg`}
                            alt=""
                        />
                    </span>
                </a>
                <a
                    onClick={(e) => {
                        e.preventDefault();
                        setListView(true);
                    }}
                    className={isListView ? 'active' : ''}
                    data-key="mode"
                    data-value="list"
                    data-default=""
                    href="#"
                >
                    <span className="icon">
                        <img
                            src={`${window.assetUrl}/assets/img/sprite_list.svg`}
                            alt=""
                        />
                    </span>
                </a>
            </span>
        </label>
    );
};
export default GridComponent;
