import * as React from 'react';
import { includes } from 'lodash';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import CategoryTree from 'ekaubamaja-ui/lib/Components/CategoryTree';
import { closeOverlay, openOverlay } from 'data/overlays/overlayHandler';
import { IStoreState } from '../../../helpers/rootReducer';

export interface IFilterSidebarProps {
    /* DOM to parse */
    dom: string;
    /** Unsafe config */
    config?: any;
}

const FilterSidebar: React.FunctionComponent<IFilterSidebarProps> = (props) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const { openOverlays } = useSelector((state: IStoreState) => state.overlaysReducer);

    // Main output

    return (
        <CategoryTree
            dom={props.dom}
            openMobileFilter={(e) => {
                e.preventDefault();
                dispatch(closeOverlay({ name: 'all' }));
                dispatch(openOverlay({ name: 'mobilefilter' }));
            }}
            closeMobileFilter={(e) => {
                e.preventDefault();
                dispatch(closeOverlay({ name: 'all' }));
            }}
            mobileFilterOpen={includes(openOverlays, 'mobilefilter')}
            link={props.config && props.config.disableRouter ? undefined : NavLink}
            query={location.search}
            location={location}
            history={history}
        />
    );
};

FilterSidebar.displayName = 'FilterSidebar';

export default FilterSidebar;
