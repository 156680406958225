import * as React from 'react';
import { useMutation } from 'redux-query-react';
import reorderRequest from 'components/Reorder/requests/reorderRequest';
import { ResponseStatus } from '../../enums/ResponseStatus';
import Toaster from 'ekaubamaja-ui/lib/Components/Toaster/index';
import { useDispatch } from 'react-redux';
import { closeOverlay } from 'data/overlays/overlayHandler';
import cartFormPostRequest from 'data/requests/cart/cartFormPostRequest';
import Actions from 'components/Common/Actions';

interface IProps {
    config: {
        reorderUrl: string;
        reorderLabel: string;
        reorderSuccessMessage: string;
        reorderErrorMessage: string;
        redirectUrl: string;
        modalCancelLabel: string;
        modalReorderAndReplaceLabel: string;
        formKey: string;
        isSideView?: boolean;
    };
}

const ReorderActions: React.FunctionComponent<IProps> = (props) => {
    const { config } = props;
    const [{}, reorderQuery] = useMutation(() => reorderRequest(props.config.reorderUrl));
    const dispatch = useDispatch();
    const [{}, postRequest] = useMutation((data) => cartFormPostRequest(data));

    const action = (e, update_cart_action) => {
        const formData = new FormData();
        formData.append('update_cart_action', update_cart_action);
        formData.append('form_key', config.formKey);

        // @ts-ignore
        postRequest(formData).then(() => {
            reorder(e);
        });
    };

    const reorder = (e) => {
        e.preventDefault();
        void reorderQuery().then((response) => {
            if (response.status === ResponseStatus.ok) {
                Toaster.addToast({
                    intent: 'success',
                    text: config.reorderSuccessMessage,
                    asHtml: true,
                });
                window.location.href = config.redirectUrl;
            } else {
                Toaster.addToast({
                    intent: 'success',
                    text: config.reorderErrorMessage,
                    asHtml: true,
                });
            }
        });
    };

    return (
        <React.Fragment>
            {config.isSideView ? (
                <Actions
                    class={`cart-buttons`}
                    buttons={[
                        {
                            click: (e) => reorder(e),
                            label: config.reorderLabel,
                            intent: 'cart-button primary',
                        },
                        {
                            click: (e) => action(e, 'empty_cart'),
                            label: config.modalReorderAndReplaceLabel,
                            intent: 'cart-button secondary',
                        },
                    ]}
                />
            ) : (
                <Actions
                    class={`form-buttons`}
                    buttons={[
                        {
                            click: (e) => reorder(e),
                            label: config.reorderLabel,
                            intent: 'form-buttons__button intent-primary',
                        },
                        {
                            click: (e) => action(e, 'empty_cart'),
                            label: config.modalReorderAndReplaceLabel,
                            intent: 'form-buttons__button intent-secondary',
                        },
                        {
                            click: (e) => {
                                e.preventDefault();
                                dispatch(closeOverlay({ name: 'all' }));
                            },
                            label: config.modalCancelLabel,
                            intent: 'form-buttons__button',
                        },
                    ]}
                />
            )}
        </React.Fragment>
    );
};

export default ReorderActions;
