import React from 'react';
import ControlInput from 'ekaubamaja-ui/lib/Components/ControlInput';

export interface IControlPasswordProps {
    id?: string;
    name?: string;
    value: string;
    onChange?: (event) => void;
    showPasswordButtonLabel: string;
}

export default function ControlPassword(props: IControlPasswordProps): JSX.Element {
    return (
        <React.Fragment>
            <div className="form-control-password">
                <ControlInput
                    secureTextEntry={true}
                    id={props.id}
                    name={props.name}
                    value={props.value}
                    onChange={(e) => {
                        if (props.onChange) {
                            props.onChange(e);
                        }
                    }}
                />
            </div>
        </React.Fragment>
    );
}
