import { request } from 'data/requests/request';

const emptyQuoteRequest = (quoteId: string) =>
    request({
        type: 'emptyQuote',
        url: `wishlist/wishlist/emptyQuote`,
        method: 'POST',
        data: { quoteId },
        notApi: true,
        absolute: false,
    });

export default emptyQuoteRequest;
