import React from 'react';
import BoxProductPrice from 'components/Catalog/CategoryList/BoxProductPrice';

interface IProps {
    price: number;
    specialPrice?: number;
    discountPercent?: number;
    pawnValue?: number;
    unitPrice?: number;
    unitPriceUnit?: string;
    pawnLabel?: string;
    hideOldPrice?: boolean;
    isListDiscount?: number;
}

const BoxProductPrices = (props: IProps) => {
    const {
        price,
        specialPrice,
        discountPercent,
        pawnValue,
        unitPrice,
        unitPriceUnit,
        pawnLabel,
        hideOldPrice,
        isListDiscount,
    } = props;

    return (
        <div className="box-product__prices">
            <div className="box-product__prices__content">
                <div className="box-product__prices__prices">
                    {!!isListDiscount && !!specialPrice && (
                        <BoxProductPrice
                            type={'primary'}
                            price={specialPrice}
                        />
                    )}
                    {!isListDiscount && !!specialPrice && !!discountPercent && discountPercent > 0 && (
                        <>
                            <BoxProductPrice
                                type={'primary sale'}
                                price={specialPrice}
                                discountPercent={discountPercent}
                            />
                            {!hideOldPrice && (
                                <BoxProductPrice
                                    type={'old'}
                                    price={price}
                                />
                            )}
                        </>
                    )}
                    {!isListDiscount && !discountPercent && (
                        <BoxProductPrice
                            type={'primary'}
                            price={price}
                        />
                    )}
                    {!!unitPrice && unitPrice > 0 && unitPriceUnit && (
                        <BoxProductPrice
                            type={'unit'}
                            price={unitPrice}
                            priceSuffixText={unitPriceUnit}
                        />
                    )}
                    {!!pawnValue && !!pawnLabel && (
                        <BoxProductPrice
                            type={'pledge'}
                            price={pawnValue}
                            pricePrefixText={pawnLabel}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default BoxProductPrices;
