import * as React from 'react';
import { IPaymentMethodProps } from 'components/Checkout/payment/PaymentBlock';
import { CLIENT_TYPE } from 'components/Checkout/enums/clientType';
import FreePayment from 'components/Checkout/payment/methods/FreePayment';
import isLoggedIn from '../../../helpers/auth/isLoggedIn';
import Checkmo from 'components/Checkout/payment/methods/Checkmo';
import MakeCommerce from 'components/Checkout/payment/methods/MakeCommerce';

export enum PaymentMethodEnum {
    citadele_lv = 'citadele_lv',
    swedbank = 'swedbank',
    swedbank_lv = 'swedbank_lv',
    swedbank_lt = 'swedbank_lt',
    seb = 'seb',
    seb_lv = 'seb_lv',
    seb_lt = 'seb_lt',
    nordea = 'nordea',
    lhv = 'lhv',
    telia = 'telia',
    liisi = 'liisi',
    makecommerce = 'makecommerce',
    esto_hirepurchase = 'esto_hirepurchase',
    esto_pay_later = 'esto_pay_later',
    esto_x = 'esto_x',
    esto_pay = 'esto_pay',
    paysera = 'paysera',
    checkmo = 'checkmo',
    cashondelivery = 'cashondelivery',
    einvoice = 'einvoice',
    montonio = 'montonio',
    montonio_payments = 'montonio_payments',
    montonio_payments_grouped = 'montonio_payments_grouped',
    tfbank_hp = 'tfbank_hp',
    inbank_payments = 'inbank_payments',
    inbank_payments_ee_hps = 'inbank_payments_ee_hps',
    inbank_payments_pl_hps = 'inbank_payments_pl_hps',
    inbank_payments_ee_go = 'inbank_payments_ee_go',
    inbank_payments_ee_slice = 'inbank_payments_ee_slice',
    lhv_hp = 'lhv_hp',
    inbank_lv = 'inbank_lv',
    verifone_payment = 'verifone_payment',
    smartdeal = 'smartdeal',
    free = 'free',
    seb_estonia = 'seb_estonia',
    seb_latvia = 'seb_latvia',
    seb_lithuania = 'seb_lithuania',
    swedbank_estonia = 'swedbank_estonia',
    swedbank_latvia = 'swedbank_latvia',
    swedbank_lithuania = 'swedbank_lithuania',
    lhv_estonia = 'lhv_estonia',
    citadele_latvia = 'citadele_latvia',
    luminor_estonia = 'luminor_estonia',
    everypay = 'everypay',
    resursbank_default = 'resursbank_default',
    banktransfer = 'banktransfer', // resurs bank related method
}
const checkMethodCodeExceptions = (methodCode: string) => {
    if (methodCode.startsWith(PaymentMethodEnum.montonio)) {
        methodCode = PaymentMethodEnum.montonio;
    }
    if (methodCode.startsWith(PaymentMethodEnum.inbank_payments)) {
        methodCode = PaymentMethodEnum.inbank_payments;
    }
    return methodCode;
};

const HandleMethod = (props: IPaymentMethodProps): React.Component => {
    let methodComponent;
    const method = props.method;
    let methodCode = method.code;
    const ignorePayments = [
        PaymentMethodEnum.montonio_payments,
        PaymentMethodEnum.montonio,
        PaymentMethodEnum.inbank_payments,
        PaymentMethodEnum.banktransfer,
        PaymentMethodEnum.resursbank_default,
        methodCode.match('resursbank_account') ? methodCode : null,
    ];

    if (ignorePayments.includes(methodCode as PaymentMethodEnum)) {
        methodComponent = <React.Fragment />;
        return methodComponent;
    }
    methodCode = checkMethodCodeExceptions(methodCode);

    switch (methodCode) {
        case PaymentMethodEnum.checkmo:
        case PaymentMethodEnum.cashondelivery:
        case PaymentMethodEnum.einvoice:
            if (
                (props.clientType === CLIENT_TYPE.CLIENT_BUSINESS &&
                    window.moneyOrdersAllowedForBusiness !== undefined) ||
                (window.allowCheckMoAll !== undefined &&
                    (props.clientType === CLIENT_TYPE.CLIENT_BUSINESS || window.allowCheckMoAll)) ||
                methodCode === PaymentMethodEnum.cashondelivery
            ) {
                methodComponent = (
                    <Checkmo
                        key={method.code}
                        method={method.code}
                        title={method.title}
                        paymentMethodProps={props}
                        disabled={
                            methodCode !== PaymentMethodEnum.cashondelivery &&
                            ((window.moneyOrdersAllowedForBusiness !== undefined &&
                                !window.moneyOrdersAllowedForBusiness[methodCode] &&
                                props.clientType === CLIENT_TYPE.CLIENT_BUSINESS) ||
                                (window.allowCheckMoAll !== undefined && !window.allowCheckMoAll && !isLoggedIn()))
                        }
                    />
                );
            }
            break;
        case PaymentMethodEnum.makecommerce:
            methodComponent = (
                <MakeCommerce
                    key={method.code}
                    paymentMethodProps={props}
                />
            );
            break;

        case PaymentMethodEnum.free:
            methodComponent = (
                <FreePayment
                    key={method.code}
                    method={method}
                    paymentMethodProps={props}
                    email={props.email}
                />
            );
            break;
        default:
            // tslint:disable-next-line:no-console
            console.error(`Invalid payment method (TODO) "${method.code}"`);
            methodComponent = <React.Fragment />;
            break;
    }
    return methodComponent;
};

export default HandleMethod;
