import * as React from 'react';
import { useEffect, useState } from 'react';
import { useMutation } from 'redux-query-react';
import * as classNames from 'classnames';
import { empty } from '../../../helpers/empty';
import { genericRequest } from 'components/Checkout/requests/genericRequest';

let requesting = true;

interface IMessageState {
    backgroundColor?: string;
    message?: string;
    textColor?: string;
}

const MessageWidget = () => {
    const [{}, messageRequest] = useMutation(() => genericRequest({ type: 'message', url: window.messageWidgetUrl }));
    const [message, setMessage] = useState<IMessageState>({});

    useEffect(() => {
        if (window.messageWidgetUrl && requesting) {
            requesting = false;
            messageRequest().then((response) => {
                if (response.status === 200 && response.body) {
                    const responseBody: IMessageState = response.body;
                    if (!empty(responseBody.message)) {
                        setMessage(responseBody);
                    }
                }
            });
        }
    });

    return (
        <React.Fragment>
            {!empty(window.messageWidgetUrl) && message.message && (
                <div
                    className={'callout'}
                    style={{
                        backgroundColor: message.backgroundColor,
                        color: message.textColor,
                    }}
                >
                    <div className="callout-content">
                        <span
                            className="callout-text"
                            dangerouslySetInnerHTML={{ __html: message.message }}
                        />
                    </div>
                </div>
            )}
        </React.Fragment>
    );
};

export default MessageWidget;
