import * as React from 'react';
import ProductActions, { IProductActionsLabels } from 'components/Catalog/Product/ProductActions';
import BoxProductPrices from 'components/Catalog/CategoryList/BoxProductPrices';
import { IProduct } from 'components/Catalog/CategoryList/IProduct';

export interface ICartProductProps {
    config: {
        labels: IProductActionsLabels;
        product: IProduct;
    };
}

const AddToCart = (props: ICartProductProps) => {
    const { product, labels } = props.config;

    return (
        <React.Fragment>
            <BoxProductPrices
                price={product.price}
                discountPercent={product.discountPercent}
                specialPrice={product.specialPrice}
                pawnValue={product.pawnValue}
                unitPrice={product.unitPrice}
                unitPriceUnit={product.unitPriceUnit}
                pawnLabel={labels.pawnLabel}
                hideOldPrice={product.hideOldPrice}
                isListDiscount={product.isListDiscount}
            />
            <ProductActions
                product={product}
                labels={labels}
                isDetail={true}
            />
        </React.Fragment>
    );
};

export default AddToCart;
