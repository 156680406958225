import { IUser } from '../interfaces/user/User';

export function getItem<T>(name: string): T | null {
    const res = localStorage.getItem(name);
    if (res) {
        try {
            return JSON.parse(res) as T;
        } catch (e) {
            return null;
        }
    }
    return null;
}

export function getLoggedInUser(): { user: IUser } | null {
    return getItem<{ user: IUser }>('user');
}

export function setItem(name: string, value: string): void {
    // This is just a hack to ensure JSON stringified data is in the localstorage
    try {
        JSON.parse(value);
        return localStorage.setItem(name, value);
    } catch (e) {
        return localStorage.setItem(name, JSON.stringify(value));
    }
}

export function removeItem(name: string): void {
    return localStorage.removeItem(name);
}

export function clear(): void {
    return localStorage.clear();
}

export function updateUserToken(token: string): void {
    const user = getItem<{ user: IUser }>('user');
    removeItem('user');
    setItem(
        'user',
        JSON.stringify({
            user: user?.user,
            token,
        }),
    );
}

// todo :: change the IUser with something more descriptive
export const rememberAuth = function (user: IUser, token: string): void {
    removeItem('user');
    setItem(
        'user',
        JSON.stringify({
            user,
            token,
        }),
    );
};
