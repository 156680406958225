import * as React from 'react';
import { useEffect, useState } from 'react';
import { parse } from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { includes, without } from 'lodash';

interface IProps {
    filters: FilterType[];
    label: string;
    type: string;
    labels?: IValueLabels;
    appendId?: string;
}
interface IValueLabels {
    vegan: string;
    eco: string;
    lactosefree: string;
    glutenfree: string;
}

export interface FilterType {
    [key: string]: number;
}

const Filter: React.FunctionComponent<IProps> = (props) => {
    const { filters, label, type, labels, appendId } = props;
    const location = useLocation();
    const history = useHistory();
    const parsedQueryParams = parse(location.search);
    const urlActiveValues = parsedQueryParams[`${type}`] ? (parsedQueryParams[`${type}`] as string).split(',') : [];

    const [activeValues, setActiveValues] = useState<string[]>(urlActiveValues);

    const getListableFilterStack = () => {
        const filtersStack: FilterType[] = [];
        Object.keys(filters).forEach((filter) => {
            const filterResults = filters[`${filter}`];
            if (filterResults.length) {
                filtersStack[`${filter}`] = filterResults;
            }
        });
        return filtersStack;
    };
    const [listableFilters, setListableFilters] = useState<FilterType[]>([]);

    useEffect(() => {
        const pathname = location.pathname;
        const searchParams = new URLSearchParams(location.search);
        if (!activeValues.length) {
            if (searchParams.has(type)) {
                searchParams.delete('p');
                searchParams.delete(type);
            }
        } else {
            if (!searchParams.has(type) || searchParams.get(type) !== activeValues.toString()) {
                searchParams.delete('p');
            }
            searchParams.set(type, activeValues.toString());
        }
        history.push({
            pathname,
            search: searchParams.toString(),
        });
    }, [activeValues]);

    useEffect(() => {
        setListableFilters(getListableFilterStack());
    }, [filters]);

    useEffect(() => {
        setActiveValues(urlActiveValues);
    }, [location]);

    return (
        <React.Fragment>
            {((activeValues && activeValues.length > 0) || Object.keys(listableFilters).length > 0) && (
                <div className="category-tree__group">
                    <input
                        type="checkbox"
                        id={type + appendId}
                        defaultChecked={activeValues.length > 0}
                    />
                    <label htmlFor={type + appendId}>
                        <span className="label">{label}</span>{' '}
                        {activeValues.length > 0 && <span className="count">{activeValues.length}</span>}
                    </label>
                    <ul>
                        {Object.keys(listableFilters)
                            .filter((item) => !!filters[`${item}`])
                            .map((filter) => {
                                const value = filters[`${filter}`];
                                const valueActive = includes(activeValues, filter);
                                return (
                                    <li key={filter}>
                                        <a
                                            onClick={(e) =>
                                                setActiveValues(
                                                    valueActive
                                                        ? without(activeValues, filter)
                                                        : [...activeValues, filter],
                                                )
                                            }
                                            className={classNames({ active: valueActive })}
                                        >
                                            <span className="label">
                                                {labels && labels[`${filter}`] ? labels[`${filter}`] : filter}
                                            </span>
                                            <span className="count">{value.length}</span>
                                        </a>
                                    </li>
                                );
                            })}
                    </ul>
                </div>
            )}
        </React.Fragment>
    );
};

export default Filter;
