import { request } from 'data/requests/request';

const smartIdPollRequest = (url: string, personalCode: string) =>
    request({
        type: 'smartIdStartRequest',
        url: `${url}?personalcode=${personalCode}`,
        method: 'POST',
        notApi: true,
        absolute: true,
    });

export default smartIdPollRequest;
