import * as React from 'react';
import { ICartTotals } from 'components/Cart/Interfaces/ICartTotals';
import { CartType } from '../../enums/CartType';
import AddAllToCartFromWishlist from 'components/Cart/Buttons/AddAllToCartFromWishlist';
import AddAllToWishlist from 'components/Cart/Buttons/AddAllToWishlist';
import ShareQuote from 'components/Wishlist/ShareQuote';
import EmptyQuote from 'components/Cart/Buttons/EmptyQuote';
import DeleteWishlist from 'components/Wishlist/DeleteWishlist';
import ChangeQuoteName from 'components/Cart/Buttons/ChangeQuoteName';
import { empty } from '../../helpers/empty';
import { ICartLabels } from 'components/Cart/Interfaces/ICartLabels';
import { ICartProduct } from 'components/Cart/Interfaces/ICartProduct';
import isLoggedIn from '../../helpers/auth/isLoggedIn';
import { useEffect, useState } from 'react';

export interface IProps {
    cart: ICartTotals;
    cartContainer?: string;
    cartType: string;
    formKey?: string;
    labels: ICartLabels;
    products: ICartProduct[];
}

const CartSidebarButtons = (props: IProps) => {
    const { cart, cartType, labels, products, formKey } = props;

    const [loggedIn, setLoggedIn] = useState<boolean>(isLoggedIn());

    const handleLoggedIn = () => {
        setLoggedIn(isLoggedIn());
    };

    useEffect(() => {
        window.addEventListener('logged-in-value-changed', handleLoggedIn);
        return () => window.removeEventListener('logged-in-value-changed', handleLoggedIn);
    }, []);

    return (
        <ul className={'cart-buttons'}>
            {cartType === CartType.CHECKOUT && loggedIn && (
                <li>
                    <a
                        className={'cart-button primary'}
                        href={cart.checkoutUrl.value}
                    >
                        {cart.checkoutUrl.label}
                    </a>
                </li>
            )}
            {cartType === CartType.CHECKOUT && !loggedIn && (
                <React.Fragment>
                    <li>
                        <a
                            className={'cart-button primary'}
                            href={cart.logInUrl.value}
                        >
                            {cart.logInUrl.label}
                        </a>
                    </li>
                    <li>
                        <a
                            className={'cart-button primary outline'}
                            href={cart.registerUrl.value}
                        >
                            {cart.registerUrl.label}
                        </a>
                    </li>
                </React.Fragment>
            )}
            {(cartType === CartType.WISHLIST || cartType === CartType.SHARE) && (
                <React.Fragment>
                    <li>
                        <AddAllToCartFromWishlist
                            products={products}
                            labels={labels}
                        />
                    </li>
                </React.Fragment>
            )}
            {cartType === CartType.WISHLIST && empty(cart.isGuestQuote) && (
                <React.Fragment>
                    <li>
                        <AddAllToWishlist
                            quoteId={cart.quoteId}
                            cartType={cartType}
                            products={products}
                            labels={labels.sidebar}
                            excludeQuoteId={cart.quoteId}
                        />
                    </li>
                </React.Fragment>
            )}
            {(cartType === CartType.SHARE || cartType === CartType.CHECKOUT) && (
                <React.Fragment>
                    <li>
                        <AddAllToWishlist
                            quoteId={cart.quoteId}
                            cartType={cartType}
                            products={products}
                            labels={labels.sidebar}
                        />
                    </li>
                </React.Fragment>
            )}
            {cartType === CartType.WISHLIST && (
                <React.Fragment>
                    {empty(cart.isGuestQuote) && (
                        <li>
                            <button
                                id={`parentChangeQuoteName`}
                                className="cart-button"
                            >
                                <ChangeQuoteName
                                    config={{
                                        quoteId: cart.quoteId,
                                        quoteName: cart.quoteName,
                                        labels: labels.sidebar.changeName,
                                    }}
                                />
                            </button>
                        </li>
                    )}
                </React.Fragment>
            )}
            {(cartType === CartType.WISHLIST || cartType === CartType.CHECKOUT) && (
                <React.Fragment>
                    <li>
                        <button
                            id={`parentShareQuote`}
                            className="cart-button"
                        >
                            <ShareQuote
                                config={{
                                    quoteId: cart.quoteId,
                                    labels: labels.sidebar.share,
                                }}
                            />
                        </button>
                    </li>
                </React.Fragment>
            )}
            {(cartType === CartType.WISHLIST || cartType === CartType.CHECKOUT) && (
                <React.Fragment>
                    <li>
                        <EmptyQuote
                            formKey={formKey}
                            cartType={cartType}
                            quoteId={cart.quoteId}
                            labels={labels.sidebar.emptyQuote}
                        />
                    </li>
                </React.Fragment>
            )}
            {cartType === CartType.WISHLIST && (
                <React.Fragment>
                    <li>
                        {empty(cart.isGuestQuote) && (
                            <button
                                id={`parentDelete`}
                                className="cart-button"
                            >
                                <DeleteWishlist
                                    config={{
                                        wishlistId: cart.quoteId,
                                        linkText: labels.sidebar.deleteQuote.linkText,
                                        popUpButtonCancel: labels.sidebar.deleteQuote.popUpButtonCancel,
                                        popUpTitle: labels.sidebar.deleteQuote.popUpTitle,
                                        popUpButtonConfirm: labels.sidebar.deleteQuote.popUpButtonConfirm,
                                    }}
                                />
                            </button>
                        )}
                    </li>
                </React.Fragment>
            )}
        </ul>
    );
};

export default CartSidebarButtons;
