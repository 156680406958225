import * as React from 'react';
import RenderLink from 'ekaubamaja-ui/lib/Components/RenderLink/index';
import CartButton from 'components/CartButton';
import CustomerName from 'components/Customer/CustomerName';
import MainMenu from 'components/MainMenu';
import ConsentHandler from 'components/Gdpr/ConsentHandler';
import Cookie from 'components/Cookie';
import Search from 'components/Catalog/Search/Search';
import CustomerDelete from 'components/AccountDelete/CustomerDelete';
import AddressDelete from 'components/AccountDelete/AddressDelete';
import RegionSelect from 'components/RegionSelect';
import Messages from 'components/Messages/Messages';
import Filter from 'components/Filter';
import Reorder from 'components/Reorder/reorder';
import { Title } from 'components/Page/Title';
import ReorderActions from 'components/Reorder/reorderActions';
import StrongAuth from 'components/StrongAuth';
import AddWishlist from 'components/Wishlist/AddWishlist';
import DeleteWishlist from 'components/Wishlist/DeleteWishlist';
import ShareQuote from 'components/Wishlist/ShareQuote';
import { CartType } from './enums/CartType';
import CheckoutCart from 'components/Cart/CheckoutCart';
import AuthorizationHandler from 'components/Authorization/AuthorizationHandler';
import UserHubWishlist from 'components/UserHubWishlist';
import CategoryMenuTreeItem from 'components/Category/CategoryMenuTreeItem';
import UserHubWishlistGuest from 'components/UserHubWishlist/guest';
import RenderCarousel from 'ekaubamaja-ui/lib/Components/RenderCarousel';
import RenderGallery from 'ekaubamaja-ui/lib/Components/RenderGallery';
import CustomerRegisterForm from 'components/Customer/Register';
import AddToCart from 'components/Catalog/Product/AddToCart';
import AddAllToWishlistWrapper from 'components/Cart/Buttons/AddAllToWishlistWrapper';
import ProductCategoryList from 'components/Catalog/CategoryList/ProductCategoryList';
import ProductsGrid from 'components/Catalog/Product/ProductsGrid';
import BoxProduct from 'components/Catalog/CategoryList/BoxProduct';
import ToolbarComponent from 'components/Toolbar/ToolbarComponent';
import BrowserRouterWrapper from 'components/BrowserRouterWrapper';
import RequiredModal from 'components/RequiredModal/Modal';
import Filters from 'components/LayeredNavigation/Filters';
import FilterTags from 'components/Filter/FilterTags';
import ClientCustomerList from 'components/ClientCustomerList/ClientCustomerList';
import Newsletter from 'components/Newsletter';
import AccountCookieChoice from 'components/Cookie/AccountCookieChoice';
import CheckoutProvider from 'components/Checkout/CheckoutProvider';
import ReturnActions from 'components/Return/returnActions';
import AddressEditForm from 'components/Customer/AddressEditForm';
import ContactForm from 'components/ContactForm';
import GlobalNotification from 'components/GlobalNotification/GlobalNotification';

const componentMapper = (
    maybeComponent: string,
    maybeContent: string,
    maybeConfig,
    ignoreTabs: string[] = [],
): React.Component => {
    return {
        authorization: <AuthorizationHandler config={maybeConfig} />,
        addToCart: <AddToCart config={maybeConfig} />,
        dropdown: (
            <RenderLink
                dom={maybeContent}
                config={maybeConfig}
                componentMapper={componentMapper}
            />
        ),
        cart: (
            <CartButton
                dom={maybeContent}
                config={maybeConfig}
            />
        ),
        strongAuth: <StrongAuth config={maybeConfig} />,
        customerName: <CustomerName />,
        menu: (
            <MainMenu
                componentMapper={componentMapper}
                dom={maybeContent}
                config={maybeConfig}
            />
        ),
        gdprConsentHandler: <ConsentHandler />,
        cookieSelection: <Cookie config={maybeConfig} />,
        search: (
            <Search
                dom={maybeContent}
                config={maybeConfig}
                output="field"
            />
        ),
        searchButton: (
            <Search
                dom={maybeContent}
                config={maybeConfig}
                output="button"
            />
        ),
        carousel: (
            <RenderCarousel
                dom={maybeContent}
                config={maybeConfig}
                componentMapper={componentMapper}
            />
        ),
        productItem: (
            <BoxProduct
                product={maybeConfig?.product}
                labels={maybeConfig?.labels}
                wishlistLabels={maybeConfig?.wishlistLabels}
            />
        ),
        accountDelete: <CustomerDelete config={maybeConfig} />,
        addressDelete: <AddressDelete config={maybeConfig} />,
        regionSelect: <RegionSelect config={maybeConfig} />,
        messages: <Messages />,
        filter: (
            <Filter
                dom={maybeContent}
                config={maybeConfig}
            />
        ),
        reorder: <Reorder config={maybeConfig} />,
        returnActions: <ReturnActions config={maybeConfig} />,
        title: <Title config={maybeConfig} />,
        reorderActions: <ReorderActions config={maybeConfig} />,
        addWishlist: <AddWishlist config={maybeConfig} />,
        deleteWishlist: <DeleteWishlist config={maybeConfig} />,
        shareQuote: <ShareQuote config={maybeConfig} />,
        wishlistCart: (
            <CheckoutCart
                cartType={CartType.WISHLIST}
                config={maybeConfig}
            />
        ),
        shareCart: (
            <CheckoutCart
                cartType={CartType.SHARE}
                config={maybeConfig}
            />
        ),
        userHubWishlist: <UserHubWishlist config={maybeConfig} />,
        userHubWishlistGuest: <UserHubWishlistGuest config={maybeConfig} />,
        checkoutCart: (
            <CheckoutCart
                cartType={CartType.CHECKOUT}
                config={maybeConfig}
            />
        ),
        categoryTreeItem: <CategoryMenuTreeItem config={maybeConfig} />,
        gallery: (
            <RenderGallery
                dom={maybeContent}
                config={maybeConfig}
            />
        ),
        addProductToWishlist: <AddAllToWishlistWrapper config={maybeConfig} />,
        productCategoryList: <BrowserRouterWrapper component={<ProductCategoryList config={maybeConfig} />} />,
        gridRefactored: <ProductsGrid config={maybeConfig} />,
        toolbar: <BrowserRouterWrapper component={<ToolbarComponent config={maybeConfig} />} />,
        requiredModal: <RequiredModal config={maybeConfig} />,
        listFilters: <BrowserRouterWrapper component={<Filters config={maybeConfig} />} />,
        filterTags: <BrowserRouterWrapper component={<FilterTags config={maybeConfig} />} />,
        clientCustomerList: <BrowserRouterWrapper component={<ClientCustomerList config={maybeConfig} />} />,
        newsletter: <Newsletter config={maybeConfig} />,
        accountCookieChoice: <AccountCookieChoice config={maybeConfig} />,
        checkout: <CheckoutProvider />,
        addressEditForm: <AddressEditForm config={maybeConfig} />,
        feedback: <ContactForm config={maybeConfig} />,
        globalNotification: <GlobalNotification config={maybeConfig} />,
    }[(ignoreTabs.includes(maybeComponent) ? '' : maybeComponent) || ''];
};

export const componentMap = {
    customerRegisterForm: CustomerRegisterForm,
};

export default componentMapper;
