import * as React from 'react';
import { useCallback, useState } from 'react';
import { useMutation } from 'redux-query-react';
import addWishlistRequest from 'components/Wishlist/requests/addWishlistRequest';
import Toaster from 'ekaubamaja-ui/lib/Components/Toaster/index';
import Actions from 'components/Common/Actions';

interface IProps {
    config: {
        labels: {
            inputLabel: string;
            buttonLabel: string;
        };
    };
}

const AddWishlist: React.FunctionComponent<IProps> = (props) => {
    const { labels } = props.config;

    const [wishlistName, setWishlistName] = useState<string>('');

    const [{}, addToWishlistQuery] = useMutation(() => addWishlistRequest(wishlistName));

    const addWishlist = useCallback(async () => {
        const response = await addToWishlistQuery();

        Toaster.addToast({
            intent: response.status === 200 ? 'success' : 'danger',
            text: response.body.message,
            asHtml: true,
        });

        if (response.status === 200) {
            setTimeout(() => window.location.reload(), 500);
        }
    }, [wishlistName]);

    return (
        <React.Fragment>
            <div className="layout-management__block">
                <Actions
                    class={`form-list`}
                    inputs={[
                        {
                            change: (e) => setWishlistName(e.target.value),
                            title: labels.inputLabel,
                            type: 'text',
                            value: wishlistName,
                        },
                    ]}
                />
                <Actions
                    class={`cart-buttons`}
                    buttons={[
                        {
                            click: () => addWishlist(),
                            label: labels.buttonLabel,
                            intent: 'cart-button',
                        },
                    ]}
                />
            </div>
        </React.Fragment>
    );
};

export default AddWishlist;
