import React, { useState } from 'react';
import { useMutation } from 'redux-query-react';
import Toaster from 'ekaubamaja-ui/lib/Components/Toaster/index';
import smartIdPollRequest from 'components/Authorization/Login/requests/smartIdPollRequest';
import smartIdRequest from 'components/Authorization/Login/requests/smartIdRequest';
import useLoginMethod from '../../../hooks/useLoginMethod';
import FormRow from 'ekaubamaja-ui/lib/Components/FormRow';
import ControlInput from 'ekaubamaja-ui/lib/Components/ControlInput';
import Buttons from 'ekaubamaja-ui/lib/Components/Buttons';
import Button from 'ekaubamaja-ui/lib/Components/Button';
import { LoginMethodMode } from 'components/StrongAuth';
import { IMobileIdProps } from 'components/Authorization/Login/MobileId';
import product from 'components/Cart/Product/Product';

interface IProps {
    methodMode?: LoginMethodMode;
    config: ISmartIdProps | IMobileIdProps;
    verificationCode: string | undefined;
    setVerificationCode: (verificationCode?: string) => void;
    registerLabel?: string;
}
export interface ISmartIdProps {
    authUrl: string;
    loginUrl: string;
    labels: {
        verificationCodeLabel: string;
        personalCodeLabel: string;
        signInLabel: string;
        signUpLabel: string;
        cancelLabel: string;
        requiredField: string;
    };
}
interface IForm {
    personalcode?: string;
}

const SmartId = (props: IProps) => {
    const { config, verificationCode, setVerificationCode } = props;
    const [personalCode, setPersonalCode] = useState<string>('');
    const [errors, setErrors] = useState<IForm>({});
    const [{}, smartIdPoll] = useMutation(() => smartIdPollRequest(props.config.authUrl, personalCode));
    const [{}, smartIdStart] = useMutation(() => smartIdRequest(props.config.authUrl));

    const start = () => {
        if (!formIsValid()) {
            return;
        }

        void smartIdStart().then((response) => {
            const { body } = response;
            if (body.status === '1') {
                if (body.session_code) {
                    setVerificationCode(body.session_code);
                } else {
                    setVerificationCode(undefined);
                }
                void smartIdPoll().then((response) => {
                    if (response.body.status === 1 || response.body.status === '1') {
                        window.location.href = props.config.loginUrl;
                    } else {
                        if (response.body.message) {
                            setVerificationCode(undefined);

                            Toaster.addToast({
                                intent: 'danger',
                                text: response.body.message,
                            });
                        }
                    }
                });
            }
            if (body.status === 0) {
                Toaster.addToast({
                    intent: 'danger',
                    text: body.message,
                });
            }
        });
    };
    const [handleLogin] = useLoginMethod(start);

    const formIsValid = (): boolean => {
        const errorList: IForm = {};
        setErrors(errorList);

        if (!personalCode) {
            errorList.personalcode = config.labels.requiredField;
        }

        setErrors(errorList);

        return Object.keys(errorList).length === 0;
    };

    return (
        <React.Fragment>
            {!verificationCode && (
                <form
                    action="#"
                    onSubmit={(event) => {
                        event.preventDefault();
                        handleLogin();
                    }}
                    className="login-method-form"
                >
                    <FormRow
                        label={config.labels.personalCodeLabel}
                        required={true}
                        error={errors.personalcode}
                    >
                        <ControlInput
                            value={personalCode}
                            onChange={(e) => setPersonalCode(e.target.value)}
                        />
                    </FormRow>
                    <Buttons>
                        <Button
                            title={props.registerLabel ?? config.labels.signInLabel}
                            intent="primary"
                            type={'submit'}
                        />
                    </Buttons>
                </form>
            )}
        </React.Fragment>
    );
};

export default SmartId;
