import * as React from 'react';
import Icon from 'ekaubamaja-ui/lib/Components/Icon';
import { parse } from 'query-string';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { without } from 'lodash';
import FilterButton from 'components/Filter/Button';

export interface IFilterTagsProps {
    config: {
        removeFiltersLabel: string;
        openFilter: string;
        vegan: boolean;
        eco: boolean;
        glutenfree: boolean;
        lactosefree: boolean;
    };
}
interface IActiveFilter {
    key: string;
    value: string;
}
const FilterTags: React.FunctionComponent<IFilterTagsProps> = (props) => {
    const { config } = props;
    const location = useLocation();
    const history = useHistory();
    const parsedQueryParams = parse(location.search);
    const activeFilterStateless: IActiveFilter[] = [];
    const handleFilterParams = () => {
        for (const [key, value] of Object.entries(parsedQueryParams)) {
            if (!['order', 'direction', 'p'].includes(key)) {
                ((value || '') as string).split(',').forEach((attribute) => {
                    activeFilterStateless.push({ key, value: attribute as string });
                });
            }
        }
    };
    handleFilterParams();

    useEffect(() => {
        handleFilterParams();
    }, [parsedQueryParams]);

    const removeFilter = (filter: IActiveFilter) => {
        const pathname = location.pathname;
        const searchParams = new URLSearchParams(location.search);
        const searchStrings = searchParams.get(filter.key)?.split(',');
        if (searchStrings && searchStrings.length < 2) {
            searchParams.delete(filter.key);
        }
        if (searchStrings && searchStrings.length > 1) {
            searchParams.set(filter.key, without(searchStrings, filter.value).join(','));
        }

        history.push({
            pathname,
            search: searchParams.toString(),
        });
    };
    const removeAll = () => {
        const pathname = location.pathname;
        const searchParams = new URLSearchParams(location.search);
        activeFilterStateless.forEach((filter) => searchParams.delete(filter.key));

        history.push({
            pathname,
            search: searchParams.toString(),
        });
    };

    return (
        <React.Fragment>
            <ul className="category-tree__tags__list">
                <FilterButton
                    title={config.openFilter}
                    count={activeFilterStateless.length}
                />
                {activeFilterStateless.map((item, itemIndex) => (
                    <li key={itemIndex}>
                        <button
                            className="category-tree__tags__tag"
                            onClick={() => removeFilter(item)}
                        >
                            <span className="wrapper">
                                <span className="label">
                                    {config[`${item.value}`] ? config[`${item.value}`] : item.value}
                                </span>
                                <Icon
                                    width={8}
                                    height={8}
                                    kind="remove"
                                    wrapperClassName="icon"
                                />
                            </span>
                        </button>
                    </li>
                ))}
                {activeFilterStateless.length > 0 && (
                    <li className="reset">
                        <button
                            className="category-tree__tags__tag"
                            onClick={removeAll}
                        >
                            <span className="wrapper">
                                <span className="label">{config.removeFiltersLabel}</span>
                            </span>
                        </button>
                    </li>
                )}
            </ul>
        </React.Fragment>
    );
};

FilterTags.displayName = 'FilterTags';

export default FilterTags;
