import { request } from 'data/requests/request';

const smartIdRequest = (url: string) =>
    request({
        type: 'smartIdPollRequest',
        url: `${url}`,
        method: 'POST',
        notApi: true,
        absolute: true,
    });

export default smartIdRequest;
