import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeOverlay } from 'data/overlays/overlayHandler';
import Overlay from 'ekaubamaja-ui/lib/Components/Overlay';
import { includes } from 'lodash';
import { IStoreState } from '../../helpers/rootReducer';
import { IReturnActionProps } from 'components/Return/returnActions';
import ReturnForm from 'components/Return/returnForm';

const ReturnOverlay: React.FunctionComponent<IReturnActionProps> = (props) => {
    const { config } = props;
    const dispatch = useDispatch();
    const { openOverlays } = useSelector((state: IStoreState) => state.overlaysReducer);

    return (
        <>
            <Overlay
                layout="focusview"
                title={config.labels.return}
                isOpen={includes(openOverlays, `returnModal${config.orderId}`)}
                doClose={() => {
                    dispatch(closeOverlay({ name: 'all' }));
                }}
                zIndex={16000004}
                size="wide"
                buttonIcons={[
                    {
                        icon: 'close',
                        onClick: () => {
                            dispatch(closeOverlay({ name: 'all' }));
                        },
                    },
                ]}
            >
                <ReturnForm config={config} />
            </Overlay>
        </>
    );
};

export default ReturnOverlay;
