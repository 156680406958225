import * as React from 'react';
import { useCallback, useState } from 'react';
import { useMutation } from 'redux-query-react';
import Toaster from 'ekaubamaja-ui/lib/Components/Toaster';
import newsletterRequest from 'components/Newsletter/requests/newsletterRequest';
import RenderHTML from 'ekaubamaja-ui/lib/Components/RenderHTML';

export interface INewsletterProps {
    config: {
        inputLabel: string;
        buttonLabel: string;
        confirmTerms: string;
    };
}

const Newsletter: React.FunctionComponent<INewsletterProps> = (props) => {
    const [{}, subscribeRequest] = useMutation((email: string) => newsletterRequest(email));

    const [email, setEmail] = useState<string>();

    const handleSubscribe = useCallback(async (email) => {
        const response = await subscribeRequest(email);
        Toaster.addToast({
            intent: response.status === 200 ? 'success' : 'danger',
            text: response.body.message,
            asHtml: true,
        });

        if (response.status === 200) {
            setEmail('');
        }
    }, []);

    return (
        <div className="form-columns">
            <div>
                <ul className="form-list">
                    <li>
                        <label
                            htmlFor="field-email"
                            className="required"
                        >
                            <em>*</em>
                            {props.config.inputLabel}
                        </label>
                        <div className="input-box">
                            <input
                                value={email}
                                onChange={(event) => setEmail(event.target.value)}
                                type="text"
                                name="field-email"
                                id="field-email"
                            />
                        </div>
                    </li>
                </ul>
                <ul className="form-buttons">
                    <li>
                        <button
                            disabled={!email?.length}
                            onClick={() => handleSubscribe(email)}
                            type="button"
                            className="form-buttons__button intent-primary"
                        >
                            {props.config.buttonLabel}
                        </button>
                    </li>
                </ul>
            </div>

            <p className="form-note">
                <RenderHTML
                    html={props.config.confirmTerms}
                    nowrapper={true}
                />
            </p>
        </div>
    );
};

Newsletter.displayName = 'Newsletter';

export default Newsletter;
