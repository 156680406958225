import * as React from 'react';
import { IClientCustomerTableLabels } from 'components/ClientCustomerList/ClientCustomerList';

interface IProps {
    labels: IClientCustomerTableLabels;
}

const CustomerFilter = (props: IProps) => {
    const { labels } = props;

    return (
        <React.Fragment>
            <form
                action="#"
                className="layout-management__block"
            >
                <ul className="form-list">
                    <li>
                        <label htmlFor="field01">{labels.nameLabel}</label>
                        <div className="input-box">
                            <input
                                type="text"
                                name="field01"
                                value=""
                                id="field01"
                                title="Nimi"
                            />
                        </div>
                    </li>
                    <li>
                        <label htmlFor="field02">{labels.emailLabel}</label>
                        <div className="input-box">
                            <input
                                type="text"
                                name="field02"
                                value=""
                                id="field01"
                                title="E-post"
                            />
                        </div>
                    </li>
                    <li>
                        <label htmlFor="field04">{labels.phoneLabel}</label>
                        <div className="input-box">
                            <input
                                type="text"
                                name="field04"
                                value=""
                                id="field04"
                                title="Telefon"
                            />
                        </div>
                    </li>
                    <li>
                        <label htmlFor="field06">{labels.statusLabel}</label>
                        <div className="input-box">
                            <select
                                name="field06"
                                value=""
                                id="field06"
                            >
                                <option>- Kõik -</option>
                                <option>Aktiivne</option>
                                <option>Kustutatud</option>
                            </select>
                        </div>
                    </li>
                    <li>
                        <label htmlFor="field07">{labels.roleLabel}</label>
                        <div className="input-box">
                            <select
                                name="field07"
                                value=""
                                id="field07"
                            >
                                <option>- Kõik -</option>
                                <option>Administraator</option>
                                <option>Tavakasutaja</option>
                            </select>
                        </div>
                    </li>
                </ul>
                <ul className="cart-buttons">
                    <li>
                        <button
                            type="submit"
                            className="cart-button primary"
                        >
                            {labels.filterLabel}
                        </button>
                    </li>
                    <li>
                        <button
                            type="button"
                            className="cart-button"
                        >
                            {labels.clearFilterLabel}
                        </button>
                    </li>
                </ul>
            </form>
        </React.Fragment>
    );
};

export default CustomerFilter;
