import * as React from 'react';
import FormRow from 'ekaubamaja-ui/lib/Components/FormRow/index';
import ControlInput from 'ekaubamaja-ui/lib/Components/ControlInput/index';
import ControlSelect from 'ekaubamaja-ui/lib/Components/ControlSelect/index';
import FormRowGroup from 'ekaubamaja-ui/lib/Components/FormRowGroup/index';
import ControlTextarea from 'ekaubamaja-ui/lib/Components/ControlTextarea';
import Buttons from 'ekaubamaja-ui/lib/Components/Buttons';
import Button from 'ekaubamaja-ui/lib/Components/Button';
import Callout from 'ekaubamaja-ui/lib/Components/Callout/index';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useMutation } from 'redux-query-react';
import feedbackRequest, { IFeedbackRequest } from 'data/requests/feedback/feedbackRequest';
import Toaster from 'ekaubamaja-ui/lib/Components/Toaster';
import { useState } from 'react';
import { getCaptchaToken } from 'components/GoogleRecaptcha/Index';

export interface IContactFormProps {
    config: {
        phonePrefixes: string[];
        customerName?: string;
        customerEmail?: string;
        labels: {
            fillForm: string;
            submitLabel: string;
            phoneLabel: string;
            nameLabel: string;
            emailLabel: string;
            messageLabel: string;
            requiredField: string;
            emailMustBeValidErrorMessage: string;
            checkFields: string;
            somethingWentWrong: string;
        };
    };
}
const ContactForm: React.FunctionComponent<IContactFormProps> = (props) => {
    const { labels, phonePrefixes } = props.config;
    const formDataValidation = Yup.object().shape({
        name: Yup.string().nullable().required(labels.requiredField),
        phoneArea: Yup.string().nullable(),
        phone: Yup.string().nullable(),
        email: Yup.string().nullable().email(labels.emailMustBeValidErrorMessage).required(labels.requiredField),
        message: Yup.string().nullable().required(labels.requiredField),
    });
    const [formSentMessage, setFormSentMessage] = useState<string>();

    const [{}, triggerFeedbackRequest] = useMutation((data: IFeedbackRequest) => feedbackRequest(data));

    const errorHandler = () => {
        Toaster.addToast({
            intent: 'danger',
            text: labels.somethingWentWrong,
            asHtml: true,
        });
        formik.setSubmitting(false);
    };

    const formik = useFormik({
        initialValues: {
            name: props.config.customerName ?? '',
            email: props.config.customerEmail ?? '',
            phoneArea: phonePrefixes[0],
            phone: '',
            message: '',
        },
        validationSchema: formDataValidation,
        validateOnBlur: true,
        validateOnChange: false,
        onSubmit: async () => {
            const captchaToken = await getCaptchaToken();
            const data = {
                name: formik.values.name,
                email: formik.values.email,
                phone: `${formik.values.phoneArea}${formik.values.phone}`,
                message: formik.values.message,
                captchaToken,
            };
            triggerFeedbackRequest(data)
                .then((response) => {
                    if (response.body.success) {
                        setFormSentMessage(response.body.message);
                    } else {
                        errorHandler();
                    }
                })
                .catch(errorHandler);
        },
    });

    if (formSentMessage) {
        return <Callout intent="success">{formSentMessage}</Callout>;
    }

    return (
        <React.Fragment>
            <h3>{labels.fillForm}</h3>
            {!formik.isValid && <Callout intent="danger">{labels.checkFields}</Callout>}
            <FormRow
                label={labels.nameLabel}
                required={true}
                error={formik.errors.name}
            >
                <ControlInput
                    name={'name'}
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    hasError={!!formik.errors.name}
                />
            </FormRow>
            <FormRow
                label={labels.emailLabel}
                required={true}
                error={formik.errors.email}
            >
                <ControlInput
                    name={'email'}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    hasError={!!formik.errors.email}
                />
            </FormRow>
            <FormRow
                label={labels.phoneLabel}
                error={formik.errors.phone}
            >
                <FormRowGroup>
                    <ControlSelect
                        name={'phoneArea'}
                        value={formik.values.phoneArea}
                        onChange={formik.handleChange}
                        size="small"
                    >
                        {phonePrefixes.map((prefix, key) => (
                            <option
                                key={prefix}
                                value={prefix}
                            >
                                {prefix}
                            </option>
                        ))}
                    </ControlSelect>
                    <ControlInput
                        name={'phone'}
                        value={formik.values.phone}
                        onChange={formik.handleChange}
                        hasError={!!formik.errors.phone}
                    />
                </FormRowGroup>
            </FormRow>
            <FormRow
                label={labels.messageLabel}
                required={true}
                error={formik.errors.message}
            >
                <ControlTextarea
                    name={'message'}
                    value={formik.values.message}
                    onChange={formik.handleChange}
                    hasError={!!formik.errors.message}
                    minRows={4}
                />
            </FormRow>
            <Buttons>
                <Button
                    intent="primary"
                    disabled={formik.isSubmitting}
                    title={labels.submitLabel}
                    onClick={formik.submitForm}
                />
            </Buttons>
        </React.Fragment>
    );
};

ContactForm.displayName = 'ContactForm';

export default ContactForm;
