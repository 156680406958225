import { request } from 'data/requests/request';

const mobileIdPollRequest = (url: string, personalCode: string, phone: string) =>
    request({
        type: 'mobileIdStartRequest',
        url: `${url}?personalcode=${personalCode}&phone=${phone}`,
        method: 'POST',
        notApi: true,
        absolute: true,
    });

export default mobileIdPollRequest;
