import React, { useEffect } from 'react';
import Cookies from 'js-cookie';
import { useRequest } from 'redux-query-react';
import consentRequest, { ICustomerConsentResponse } from 'components/Gdpr/requests/consentRequest';
import { useSelector } from 'react-redux';
import { CookieType } from 'components/Gdpr/enums/CookieType';

const mapStateToProps = (state) => {
    return {
        customerConsent: state.entities.customerConsentRequest as ICustomerConsentResponse,
    };
};

export const hasGdprCookieToken = (): boolean => {
    return !!Cookies.get('gdpr_cookie_token');
};

const compositeCookies = {
    necessary: ['security_storage'],
    marketing: ['ad_user_data', 'ad_personalization', 'ad_storage'],
    preference: ['functionality_storage', 'personalization_storage'],
    analytics: ['analytics_storage'],
};

declare const gtag: Function;

const ConsentHandler = (): JSX.Element => {
    const gdprCookieToken = Cookies.get('gdpr_cookie_token');
    const [{ isFinished, status }] = useRequest(consentRequest(gdprCookieToken ?? ''));
    const { customerConsent } = useSelector(mapStateToProps);

    useEffect(() => {
        if (isFinished && status === 200 && !customerConsent.errors) {
            const gdprScriptNodes = document.querySelectorAll('script[type="text/x-magento-gdpr"]');

            gdprScriptNodes.forEach((scriptElement) => {
                const cookieCode = scriptElement.getAttribute('data-consent');

                if (hasConsent(cookieCode as CookieType)) {
                    const approvedScriptElement = document.createElement('script');
                    approvedScriptElement.setAttribute('type', 'text/javascript');
                    approvedScriptElement.innerHTML = scriptElement.innerHTML;

                    scriptElement.after(approvedScriptElement);

                    if (scriptElement.parentElement) {
                        scriptElement.parentElement.removeChild(scriptElement);
                    }
                }
            });
            updateGtmConsent(customerConsent);
        }
    }, [isFinished, status]);

    const hasConsent = (cookieCode: CookieType) => {
        const consent = customerConsent.data.find((consent) => consent.code === cookieCode);

        return consent?.status;
    };

    const updateGtmConsent = (customerConsent: ICustomerConsentResponse) => {
        const consentData: Record<string, 'granted' | 'denied'> = {};

        if (customerConsent.data.length === 0) {
            return;
        }

        customerConsent.data.forEach((cookie) => {
            const cookieCode = cookie.code;
            const isChecked = cookie.status ? 'granted' : 'denied';

            if (compositeCookies[cookieCode]) {
                compositeCookies[cookieCode].forEach((code) => (consentData[code] = isChecked));
            } else {
                consentData[cookieCode] = isChecked;
            }
        });
        gtag('consent', 'update', consentData);
    };

    return <React.Fragment />;
};

export default ConsentHandler;
