import React, { useRef, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import FormKey from 'components/GeneralForm/Field/FormKey';
import LayoutForm from 'ekaubamaja-ui/lib/Layouts/LayoutForm/index';
import FormRow from 'ekaubamaja-ui/lib/Components/FormRow';
import ControlInput from 'ekaubamaja-ui/lib/Components/ControlInput';
import CheckboxList from 'ekaubamaja-ui/lib/Applications/ePharma2/Components/CheckboxList';
import CheckboxWithLabel from 'ekaubamaja-ui/lib/Components/CheckboxWithLabel';
import Buttons from 'ekaubamaja-ui/lib/Components/Buttons/index';
import Button from 'ekaubamaja-ui/lib/Components/Button';

import ControlSelect from 'ekaubamaja-ui/lib/Components/ControlSelect';
import AdsSearch from 'components/AdsSearch';
import { IAdsConfig } from 'components/AdsSearch/interfaces/IAdsConfig';
import { IAdsAddress } from 'components/AdsSearch/interfaces/IAdsAddress';

interface IProps {
    config: {
        formConfig: {
            actionUrl: string;
            backUrl: string;
        };
        formData: {
            city: string;
            firstName: string;
            lastName: string;
            postcode: string;
            regionSelect: {
                default?: string;
                selectedValue: number;
                selectedValueLabel: string;
                regions: region[];
            };
            street: string;
            telephone: string;
            country: string;
        };
        inAdsSearchConfig: IAdsConfig;
        labels: {
            firstname: string;
            lastname: string;
            telephone: string;
            address: string;
            city: string;
            contactInformation: string;
            defaultShippingAddress: string;
            goBack: string;
            postcode: string;
            region: string;
            requiredField: string;
            numericField: string;
            saveAddress: string;
            setAsDefaultShippingAddress: string;
            street: string;
        };
    };
}

type region = {
    value: number;
    title: string;
    country_id: string;
    label: string;
};

const AddressEditForm = (props: IProps) => {
    const { config } = props;
    const { formConfig, formData, labels } = config;
    const [addressState, setAddressState] = useState<IAdsAddress>();
    const [addressValid, setAddressValid] = useState(true);

    const formElement = useRef<HTMLFormElement>(null);
    const formDataValidation = Yup.object().shape({
        city: Yup.string().nullable().required(labels.requiredField),
        firstname: Yup.string().nullable().required(labels.requiredField),
        lastname: Yup.string().nullable().required(labels.requiredField),
        postcode: Yup.number().nullable().required(labels.requiredField).typeError(labels.numericField),
        region: Yup.string().nullable().required(labels.requiredField),
        region_id: Yup.string().nullable().required(labels.requiredField),
        street: Yup.string().nullable().required(labels.requiredField),
        telephone: Yup.number().nullable().required(labels.requiredField).typeError(labels.numericField),
    });

    const formik = useFormik({
        initialValues: {
            city: formData.city ?? '',
            firstname: formData.firstName ?? '',
            lastname: formData.lastName ?? '',
            postcode: formData.postcode ?? '',
            region: formData.regionSelect.selectedValueLabel ?? '',
            region_id: formData.regionSelect.selectedValue ?? '',
            street: formData.street ?? '',
            telephone: formData.telephone ?? '',
            default_shipping: false,
        },
        validationSchema: formDataValidation,
        validateOnBlur: true,
        validateOnChange: false,
        onSubmit: () => {
            if (formElement.current) {
                formElement.current.submit();
            }
        },
    });

    const setAddress = (address: IAdsAddress | undefined) => {
        if (address && address.street && address.postcode) {
            void formik.setFieldValue('street', address.street[0]);
            void formik.setFieldValue('city', address.city);
            void formik.setFieldValue('postcode', address.postcode);
            const selectedRegion = formData.regionSelect.regions.find((region) => {
                return region.value === address.region_id;
            });

            if (selectedRegion) {
                formik.setFieldValue('region_id', selectedRegion.value);
                formik.setFieldValue('region', selectedRegion.title);
            }

            void formik.setErrors({});
            setAddressState(address);
            setAddressValid(true);
        } else {
            setAddressState(undefined);
            setAddressValid(false);
            formik.resetForm();
            void formik.setErrors({ postcode: config.labels.requiredField });
        }
    };

    return (
        <form
            ref={formElement}
            action={formConfig.actionUrl}
            method="post"
            onSubmit={formik.handleSubmit}
            className="form-address-edit"
        >
            <FormKey />
            <h3>{labels.contactInformation}</h3>
            <LayoutForm layout="vertical">
                <FormRow
                    label={labels.firstname}
                    required={true}
                    error={formik.errors.firstname}
                >
                    <ControlInput
                        name="firstname"
                        value={formik.values.firstname}
                        onChange={formik.handleChange}
                    />
                </FormRow>
                <FormRow
                    label={labels.lastname}
                    required={true}
                    error={formik.errors.lastname}
                >
                    <ControlInput
                        name="lastname"
                        value={formik.values.lastname}
                        onChange={formik.handleChange}
                    />
                </FormRow>
                <FormRow
                    label={labels.telephone}
                    required={true}
                    error={formik.errors.telephone}
                >
                    <ControlInput
                        name="telephone"
                        value={formik.values.telephone}
                        onChange={formik.handleChange}
                    />
                </FormRow>
                <FormRow
                    label={
                        <CheckboxList>
                            <CheckboxWithLabel
                                label={labels.setAsDefaultShippingAddress}
                                checked={formik.values.default_shipping}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    formik.setFieldValue('default_shipping', e.target.checked)
                                }
                            />
                        </CheckboxList>
                    }
                />
                <input
                    type="hidden"
                    name="default_shipping"
                    value={formik.values.default_shipping ? 1 : 0}
                />

                <h3>{labels.address}</h3>
                <AdsSearch
                    config={config.inAdsSearchConfig}
                    setAddressState={setAddress}
                    error={!!formik.errors.postcode}
                />
                {((addressValid && formData.street) || addressState) && (
                    <React.Fragment>
                        <FormRow
                            label={labels.street}
                            required={true}
                            error={formik.errors.street}
                        >
                            <ControlInput
                                name="street[]"
                                readonly={true}
                                disabled={!formik.isSubmitting}
                                value={formik.values.street}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    void formik.setFieldValue('street', e.target.value);
                                }}
                            />
                        </FormRow>
                        <FormRow
                            label={labels.region}
                            required={true}
                            error={formik.errors.region}
                        >
                            <ControlInput
                                readonly={true}
                                disabled={!formik.isSubmitting}
                                name="region"
                                value={formik.values.region}
                                onChange={formik.handleChange}
                            />
                            <input
                                type="hidden"
                                value={formik.values.region_id}
                                name="region_id"
                            />
                        </FormRow>
                        <FormRow
                            label={labels.city}
                            required={true}
                            error={formik.errors.city}
                        >
                            <ControlInput
                                readonly={true}
                                disabled={!formik.isSubmitting}
                                name="city"
                                value={formik.values.city}
                                onChange={formik.handleChange}
                            />
                        </FormRow>
                        <FormRow
                            label={labels.postcode}
                            required={true}
                            error={formik.errors.postcode}
                        >
                            <ControlInput
                                readonly={true}
                                disabled={!formik.isSubmitting}
                                name="postcode"
                                value={formik.values.postcode}
                                onChange={formik.handleChange}
                            />
                        </FormRow>
                        <input
                            type="hidden"
                            value={formData.country}
                            name="country_id"
                            id="country"
                        />
                    </React.Fragment>
                )}
            </LayoutForm>
            <Buttons>
                <Button
                    disabled={!addressValid}
                    type="submit"
                    intent="primary"
                    title={labels.saveAddress}
                />
                <Button
                    type="anchor"
                    intent="discrete"
                    href={formConfig.backUrl}
                    title={labels.goBack}
                />
            </Buttons>
        </form>
    );
};

export default AddressEditForm;
