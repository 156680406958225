import * as React from 'react';
import { useEffect, useState } from 'react';
import { useMutation, useRequest } from 'redux-query-react';
import getCouponRequest from 'data/requests/coupon/getCouponRequest';
import { useSelector } from 'react-redux';
import ControlInput from 'ekaubamaja-ui/lib/Components/ControlInput';
import addCouponRequest from 'data/requests/coupon/addCouponRequest';
import deleteCouponRequest from 'data/requests/coupon/deleteCouponRequest';
import { ICouponLabels } from 'components/Cart/Interfaces/ICartTotals';

export interface IProps {
    quoteId: string;
    labels: ICouponLabels;
}

const mapStateToProps = (state) => {
    return {
        requestedCoupon: state.entities.coupons,
    };
};

const Coupon = (props: IProps) => {
    const { quoteId, labels } = props;
    if (!labels) {
        return <></>;
    }
    const [error, setError] = useState(false);
    const [coupon, setCoupon] = useState('');

    useRequest(getCouponRequest(quoteId));
    const { requestedCoupon } = useSelector(mapStateToProps);
    const [activeCoupon, setActiveCoupon] = useState('');
    const [{}, couponRequest] = useMutation((coupon: string, quoteIdMask: string) =>
        addCouponRequest(coupon, quoteIdMask),
    );
    const [{}, removeCouponRequest] = useMutation((data: string, quoteIdMask: string) =>
        deleteCouponRequest(quoteIdMask),
    );

    useEffect(() => {
        if (typeof requestedCoupon === 'string') {
            setActiveCoupon(requestedCoupon);
        }
    }, [requestedCoupon]);

    const remove = async () => {
        await removeCouponRequest(coupon, quoteId);
        setActiveCoupon('');
        window.dispatchEvent(new CustomEvent('cart-altered'));
    };
    const send = async () => {
        if (coupon.length) {
            setError(false);
            const response = await couponRequest(coupon, quoteId);
            if (response.status === 200) {
                setActiveCoupon(coupon);
                window.dispatchEvent(new CustomEvent('cart-altered'));
            } else {
                setError(true);
            }
        } else {
            setError(true);
        }
    };

    const activeMessage = () => labels.activeCode.replace('%1', activeCoupon);

    return (
        <React.Fragment>
            <div className="cart-block">
                {error && <div className="cart-callout intent-danger">{labels.error}</div>}
                {!!activeCoupon && <div className="cart-callout intent-success">{activeMessage()}</div>}
                <React.Fragment>
                    {!activeCoupon && (
                        <ul className="form-list">
                            <li>
                                <label htmlFor="coupon">{labels.askCode}</label>
                                <ControlInput
                                    value={coupon}
                                    name={coupon}
                                    maxLength={25}
                                    onChange={(e) => setCoupon(e.target.value)}
                                />
                            </li>
                        </ul>
                    )}
                    <ul className="cart-buttons">
                        <li>
                            {!activeCoupon && (
                                <button
                                    className="cart-button"
                                    type="submit"
                                    onClick={() => send()}
                                >
                                    {labels.add}
                                </button>
                            )}
                            {!!activeCoupon && (
                                <button
                                    className="cart-button"
                                    type="submit"
                                    onClick={() => remove()}
                                >
                                    {labels.remove}
                                </button>
                            )}
                        </li>
                    </ul>
                </React.Fragment>
            </div>
        </React.Fragment>
    );
};

export default Coupon;
