import * as React from 'react';
import { useEffect, useState } from 'react';
import { parse } from 'query-string';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { IProduct } from 'components/Catalog/CategoryList/IProduct';
import { FilterType } from 'components/LayeredNavigation/Components/Filter';
import { includes, trim } from 'lodash';
import { IProductListResponse } from 'components/Catalog/CategoryList/ProductListRequest';
import { handleProducts } from 'components/Catalog/CategoryList/ProductCategoryList';
import Overlay from 'ekaubamaja-ui/lib/Components/Overlay';
import Icon from 'ekaubamaja-ui/lib/Components/Icon';
import { IStoreState } from '../../helpers/rootReducer';
import { closeOverlay } from 'data/overlays/overlayHandler';
import CategoryTree from 'components/LayeredNavigation/CategoryTree';

interface IProps {
    config: IValueLabels;
}

export interface IValueLabels {
    openFilter: string;
    vegan: string;
    eco: string;
    lactosefree: string;
    glutenfree: string;
    attributegrapevariety: string;
    attributefoodrecommendation: string;
    attributesweetness: string;
    attributebadge: string;
    attributegeoarea: string;
    brand: string;
    rawmaterialcountry: string;
    features: string;
}

export interface IFilters {
    boolfilters: FilterType[];
    attributegrapevariety: FilterType[];
    attributefoodrecommendation: FilterType[];
    attributesweetness: FilterType[];
    attributebadge: FilterType[];
    attributegeoarea: FilterType[];
    rawmaterialcountry: FilterType[];
    brand: FilterType[];
}

export const multiFilters = [
    'attributegrapevariety',
    'attributefoodrecommendation',
    'attributesweetness',
    'attributebadge',
    'rawmaterialcountry',
    'attributegeoarea',
    'brand',
];

export const boolFilters = ['vegan', 'eco', 'lactosefree', 'glutenfree'];

const mapStateToProps = (state): IProductListResponse => {
    return {
        products: state.entities?.productListRequest?.products || [],
    };
};

const Filters: React.FunctionComponent<IProps> = (props) => {
    const { config } = props;
    const location = useLocation();
    const { products } = useSelector(mapStateToProps);
    const parsedQueryParams = parse(location.search);
    const { openOverlays } = useSelector((state: IStoreState) => state.overlaysReducer);
    const dispatch = useDispatch();

    const filters: IFilters = {
        boolfilters: [],
        attributegrapevariety: [],
        attributefoodrecommendation: [],
        attributesweetness: [],
        attributebadge: [],
        attributegeoarea: [],
        rawmaterialcountry: [],
        brand: [],
    };
    const sortedFilters = filters;
    const filterMultiFilters = (product: IProduct, multiFilter: string) => {
        if (product[`${multiFilter}`]) {
            const varieties = [...new Set(product[`${multiFilter}`].split(',') as string)];
            varieties.map((variety) => {
                if (trim(variety).length) {
                    if (!filters[`${multiFilter}`][`${variety}`]) {
                        filters[`${multiFilter}`][`${variety}`] = [];
                    }
                    filters[`${multiFilter}`][`${variety}`].push(product.id);
                }
            });
        }
    };
    const getActiveFilters = () => {
        boolFilters.forEach((boolFilter) => {
            filters.boolfilters[`${boolFilter}`] = [];
        });
        multiFilters.forEach((multiFilter) => {
            filters[`${multiFilter}`] = [];
        });

        multiFilters.forEach((multiFilter) => {
            handleProducts(products, parsedQueryParams, multiFilter).map((product) => {
                filterMultiFilters(product, multiFilter);
            });
        });
        boolFilters.forEach((boolFilter) => {
            handleProducts(products, parsedQueryParams, boolFilter).map((product) => {
                if (product[`${boolFilter}`]) {
                    filters.boolfilters[`${boolFilter}`].push(product.id);
                }
            });
        });

        Object.keys(filters).forEach((filterkey) => {
            const sortable: any[] = [];
            for (const filter in filters[`${filterkey}`]) {
                sortable.push([filter, filters[`${filterkey}`][`${filter}`]]);
            }

            sortable.sort(function (a, b) {
                return b[1].length - a[1].length;
            });
            sortedFilters[`${filterkey}`] = [];
            sortable.forEach((sortableItem) => {
                sortedFilters[`${filterkey}`][`${sortableItem[0]}`] = sortableItem[1];
            });
        });

        return sortedFilters;
    };
    const [activeFilters, setActiveFilters] = useState<IFilters>(filters);

    useEffect(() => {
        setActiveFilters(getActiveFilters());
    }, [location, products]);

    const doClose = () => {
        dispatch(closeOverlay({ name: 'all' }));
    };
    return (
        <React.Fragment>
            <Overlay
                isOpen={includes(openOverlays, 'mobilefilter')}
                doClose={(e) => {
                    e.preventDefault();
                    doClose();
                }}
                layout="category-tree"
                className="category-tree__mobile"
                customHeader={
                    <div className="category-tree__mobile__heading">
                        <button onClick={doClose}>
                            <Icon
                                kind="close"
                                width={16}
                                height={16}
                            />
                        </button>
                    </div>
                }
            >
                <div className="category-tree__mobile__content">
                    <CategoryTree
                        activeFilters={activeFilters}
                        config={config}
                        appendId={'_mobile'}
                    />
                </div>
            </Overlay>
            <CategoryTree
                activeFilters={activeFilters}
                config={config}
            />
        </React.Fragment>
    );
};

export default Filters;
