import isLoggedIn from '../../../helpers/auth/isLoggedIn';
import { request } from 'data/requests/request';

export interface IProductRequest {
    cartItem: {
        sku: string;
        qty: number;
        quote_id: string;
        product_type: string;
        product_option: {
            extension_attributes: {
                configurable_item_options: {
                    option_id: string;
                    option_value: string;
                }[];
            };
        };
    };
}

const toCartRequest = (data: IProductRequest, quoteIdMask: string) =>
    request({
        method: 'POST',
        type: 'response',
        url: isLoggedIn() ? `carts/mine/items` : `guest-carts/${quoteIdMask}/items`,
        data,
    });

export default toCartRequest;
