import * as React from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useRequest } from 'redux-query-react';
import { useRef, useState } from 'react';

import { request } from 'data/requests/request';

// import Cart from 'components/checkout/checkout/cart/Cart';
// import FramePage from 'components/framePage/FramePage';
// import RedirectAbsolute from 'components/Redirect/RedirectAbsolute';
// import ShippingContent from 'components/checkout/Content';

// import { ResponseStatusEnum } from '../../enums/ResponseStatus';
// import { isLoggedIn } from '../../helpers/customer/isLoggedIn';
// import useOverlays from 'components/overlay/Overlay';
// import CartOverlay from 'components/checkout/cart/CartOverlay';

import FrameCheckout from 'ekaubamaja-ui/lib/Frames/Checkout';
import LayoutSidebar from 'ekaubamaja-ui/lib/Layouts/LayoutSidebar';
import LayoutColumnPrimary from 'ekaubamaja-ui/lib/Layouts/LayoutSidebarPrimary';
import LayoutColumnSecondary from 'ekaubamaja-ui/lib/Layouts/LayoutSidebarSecondary';
import Block from 'ekaubamaja-ui/lib/Components/Block';
import { useEffect } from 'react';
import { IRegionOptionListItem } from 'components/Checkout/interfaces/checkout/address/IRegionOptionListItem';
import { IAgreementsResponse } from 'components/Checkout/interfaces/payment/IAgreementsResponse';
import { IQuote } from 'components/Checkout/interfaces/checkout/quote/IQuote';
import MessageWidget from 'components/Checkout/message/Widget';
import FramePage from 'components/frames/FramePage';
import { overlaysSelector } from 'data/overlays/overlaysSelector';
import isLoggedIn from '../../helpers/auth/isLoggedIn';
import RedirectAbsolute from 'components/Redirect/RedirectAbsolute';
import { openOverlay } from 'data/overlays/overlayHandler';
import { ICart } from 'components/Checkout/interfaces/checkout/cart/cartType';
import Cart from 'components/Checkout/cart/Cart';
import ShippingContent from 'components/Checkout/shipping/ShippingContent';
import CartOverlay from 'components/Checkout/CartOverlay';
import { IAdsConfig } from 'components/AdsSearch/interfaces/IAdsConfig';

const mapStateToProps = (state) => {
    return {
        quote: state.entities.quote,
        quoteId: state.entities.quote?.id,
        customer: state.entities.quote?.customer,
    };
};

declare global {
    interface Window {
        regions: IRegionOptionListItem[];
        jwt: string;
        quoteIdMask: string;
        API_URL: string;
        form_key: string;
        LOGIN_URL: string;
        STORE_CODE: string;
        PHONE_PREFIXES: string[];
        detailedPaymentEnabled: boolean;
        paymentLogos: {
            payment: any;
        };
        checkoutConfig?: {
            logo_src?: string;
            business_address_form?: {
                disable_contact_info: boolean;
                fields_sort_order: {
                    [key: string]: number;
                };
            };
        };
        agreements: IAgreementsResponse;
        locationDefaults: {
            country_id: string;
            region_id: number;
        };
        mkCardCancelled: () => void;
        mkCardCompleted: (token) => void;
        isBusinessEnabled: boolean;
        isLoginEnabled: boolean;
        inAddressConfiguration: IAdsConfig;
        rootElement: string;
        isTrhEnabled: boolean;
        messageWidgetUrl: string;
        isSubscriptionEnabled?: boolean;
        isPersonalCodeEnabled?: boolean;
        isPersonalCodeBottomLocated?: boolean;
        personalCodeRequiredFor?: string[];
        allowCheckMoAll?: boolean;
        moneyOrdersAllowedForBusiness: { [key: string]: boolean };
        moneyOrdersAllowedForLogged: boolean;
        personalCodeValidationRules?: {
            [key: string]: string;
        };
        storeCurrencyCode?: string;
    }
}

const Checkout = () => {
    const { openOverlays } = useSelector(overlaysSelector);
    const dispatch = useDispatch();

    const quoteRequest = useRequest<IQuote | null>(
        request({ type: 'quote', url: isLoggedIn() ? 'carts/mine' : `guest-carts/${window.quoteIdMask}` }),
    );
    const history = useHistory();
    const { t } = useTranslation();
    const { customer, quote } = useSelector(mapStateToProps);
    const [cart, setCart] = useState<ICart | undefined>();
    const shippingPrice = useRef(0.0);
    const [updateState, setUpdateState] = useState(false);

    const CartContent = (
        <Cart
            customer={customer}
            cart={cart}
            quote={quote}
            setCart={setCart}
            shippingPrice={shippingPrice}
        />
    );
    const updateShipping = (event) => {
        shippingPrice.current = parseFloat(event.detail?.discountPrice ?? event.detail?.price);
        setUpdateState(!updateState);
    };

    useEffect(() => {
        window.addEventListener('shipping-additional-change', updateShipping);
        return function cleanup() {
            window.removeEventListener('shipping-additional-change', updateShipping);
        };
    });
    const quoteRequestFinished = quoteRequest && quoteRequest[0].isFinished;
    if (quoteRequestFinished && (quoteRequest[0].status !== 200 || (quote && quote.items_qty < 1))) {
        return <RedirectAbsolute to={'checkout'} />;
    }

    return (
        <React.Fragment>
            {quoteRequestFinished && (
                <FrameCheckout
                    title={t('checkout.Checkout')}
                    login={
                        window.isLoginEnabled && !customer?.firstname
                            ? {
                                  title: t('checkout.Sign In'),
                                  href: `${window.LOGIN_URL}`,
                              }
                            : undefined
                    }
                    logoHref={window.API_URL}
                    logoURL={window.checkoutConfig?.logo_src}
                    wrapHeader={true}
                >
                    <FramePage title={t('checkout.Checkout')}>
                        <CartOverlay CartContent={CartContent} />
                        <Block>
                            <MessageWidget />
                            <LayoutSidebar
                                openSidebarLabel={t('checkout.View purchase summary')}
                                openSidebar={(e) => {
                                    e.preventDefault();
                                    history.push({ hash: 'summary' });
                                    dispatch(openOverlay({ name: 'summary' }));
                                }}
                                openSidebarIcon="cart"
                                openSidebarAddOn={`${cart?.base_grand_total ?? 0} €`}
                            >
                                <LayoutColumnPrimary
                                    title={t('Checkout')}
                                    icon="checklist"
                                >
                                    <ShippingContent
                                        quote={quote}
                                        cart={cart}
                                    />
                                </LayoutColumnPrimary>
                                <LayoutColumnSecondary
                                    title={t('checkout.Order Summary')}
                                    icon="cart"
                                >
                                    {CartContent}
                                </LayoutColumnSecondary>
                            </LayoutSidebar>
                        </Block>
                    </FramePage>
                </FrameCheckout>
            )}
        </React.Fragment>
    );
};

export default Checkout;
