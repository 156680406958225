import * as React from 'react';
import CustomerFilter from 'components/ClientCustomerList/CustomerFilter';
import CustomerTable from 'components/ClientCustomerList/CustomerTable';

export interface IClientCustomerTableLabels {
    nameLabel: string;
    emailLabel: string;
    phoneLabel: string;
    roleLabel: string;
    statusLabel: string;
    filterLabel: string;
    clearFilterLabel: string;
    editLabel: string;
    deleteLabel: string;
    nextLabel: string;
    previousLabel: string;
}

export interface ICustomer {
    name: string;
    email: string;
    phone: string;
    role: string;
    status: string;
}

interface IProps {
    config: {
        labels: IClientCustomerTableLabels;
        customers: ICustomer[];
    };
}

const ClientCustomerList: React.FunctionComponent<IProps> = (props) => {
    const { labels, customers } = props.config;

    return (
        <React.Fragment>
            <div className="layout-management">
                <div className="layout-management__column-a">
                    <div className="layout-management__column-a__content">
                        <CustomerFilter labels={labels} />
                    </div>
                </div>
                <div className="layout-management__column-c">
                    <CustomerTable
                        labels={labels}
                        customers={customers}
                    />
                </div>
            </div>
        </React.Fragment>
    );
};

export default ClientCustomerList;
