import * as React from 'react';
import { memo, useEffect } from 'react';

interface IProps {
    config: {
        category: CategoryItem;
        activeIds: string[];
    };
}

export interface CategoryItem {
    id: string;
    url: string;
    name: string;
    active: boolean;
    children?: CategoryItem[];
}

const setActiveCategoriesToStorage = (ids: string[]) => {
    localStorage.setItem('lastActiveCategoryIds', JSON.stringify(ids));
};

const CategoryMenuTreeItem = (props: IProps) => {
    const { category, activeIds } = props.config;

    useEffect(() => {
        if (activeIds.length > 0) {
            setActiveCategoriesToStorage(activeIds);
        }
    }, [activeIds]);

    const categorySubComponents = (children) => {
        return (
            <React.Fragment>
                {!!children?.length && (
                    <ul>
                        {children.map((childCategory, filterKey) => {
                            return (
                                <li key={filterKey}>
                                    <a
                                        id={`category-menu-element-${childCategory.id}`}
                                        href={`${childCategory.url}`}
                                        className={activeIds.includes(childCategory.id.toString()) ? 'active' : ''}
                                    >
                                        {childCategory.name}
                                    </a>
                                    {categorySubComponents(childCategory.children)}
                                </li>
                            );
                        })}
                    </ul>
                )}
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            <a
                href={category.children && category.children.length ? '#' : `${category.url}`}
                className={
                    activeIds.includes(category.id.toString()) ? 'active' : `category-menu-element-${category.id}`
                }
            >
                <span className="label parentCategory">{category.name}</span>
            </a>
            {categorySubComponents(category.children)}
        </React.Fragment>
    );
};

export default memo(CategoryMenuTreeItem);
