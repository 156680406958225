import { request } from 'data/requests/request';

const mobileIdRequest = (url: string) =>
    request({
        type: 'mobileIdPollRequest',
        url: `${url}`,
        method: 'POST',
        notApi: true,
        absolute: true,
    });

export default mobileIdRequest;
