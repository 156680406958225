import * as React from 'react';

import LayoutForm from 'ekaubamaja-ui/lib/Layouts/LayoutForm';
import FormRow from 'ekaubamaja-ui/lib/Components/FormRow';
import ControlInput from 'ekaubamaja-ui/lib/Components/ControlInput';
import ControlSelect from 'ekaubamaja-ui/lib/Components/ControlSelect';
import FormRowGroup from 'ekaubamaja-ui/lib/Components/FormRowGroup';
import ButtonIcon from 'ekaubamaja-ui/lib/Components/ButtonIcon';
import { useTranslation } from 'react-i18next';
import LayoutFormColumn from 'ekaubamaja-ui/lib/Layouts/LayoutFormColumn';
import { useEffect, useRef, useState } from 'react';
import CheckboxWithLabel from 'ekaubamaja-ui/lib/Components/CheckboxWithLabel/index';
import { useHistory } from 'react-router-dom';
import { IShippingMethod } from 'components/Checkout/interfaces/checkout/shipping/IShippingMethod';
import { IAddress, ICustomer, IGuestAddress } from 'components/Checkout/interfaces/checkout/customer/ICustomer';
import { IRegionOptionListItem } from 'components/Checkout/interfaces/checkout/address/IRegionOptionListItem';
import { IErrorField, IValidationItem, validate, VALIDATION_TYPES } from 'components/Checkout/form/validate';
import isLoggedIn from '../../../../helpers/auth/isLoggedIn';
import { empty } from '../../../../helpers/empty';
import { dummyFields } from 'components/Checkout/shipping/address/dummyFields';
import useOverlays from 'components/Checkout/overlay/Overlay';
import { extractCodeFromPhone } from 'components/Checkout/helpers/extractCodeFromPhone';
import { Regions } from 'components/Checkout/shipping/address/Regions';
import { getErrorForField } from 'components/Checkout/form/getErrorForField';
import { AddressFields } from 'components/Checkout/enums/address/AddressFields';
import { ContactPhoneAreaItems } from 'components/Checkout/helpers/ContactPhoneAreaItems';
import { CLIENT_TYPE } from 'components/Checkout/enums/clientType';
import { BaseAddressDefaults } from 'components/Checkout/shipping/address/BaseAddressDefaults';
import { BaseAddressRules } from 'components/Checkout/helpers/BaseAddressRules';
import AdsSearch from 'components/AdsSearch';
import { IAdsAddress } from 'components/AdsSearch/interfaces/IAdsAddress';

export interface IProps {
    proceedAction: () => void;
    selectedMethod: IShippingMethod | undefined;
    newAddressFields: IGuestAddress | undefined;
    setNewAddressFields: (addressFields: IGuestAddress) => void;
    email: string;
    setEmail: (email: string) => void;
    selectedAddress: IAddress | null;
    selectAddress: (address: IAddress) => void;
    customer: ICustomer | null;
    clientType: CLIENT_TYPE;
}

const NewAddressForm = (props) => {
    const { t } = useTranslation();
    const {
        selectedMethod,
        proceedAction,
        email,
        setEmail,
        selectedAddress,
        selectAddress,
        customer,
        newAddressFields,
        setNewAddressFields,
    } = props;
    const errorDefaults: IErrorField[] = [];
    const errors = useRef(errorDefaults);
    const contactPhoneAreaItems = ContactPhoneAreaItems();
    const [updateStateStatus, updateState] = useState(false);
    const [showAdsFields, setShowAdsFields] = useState<boolean>();
    const { overlays, closeOverlay } = useOverlays();
    const history = useHistory();
    const overlayOpen = overlays.indexOf('add-address') !== -1;

    const addressDefaults: IGuestAddress = selectedAddress || newAddressFields || BaseAddressDefaults(customer);
    const { phoneCode, telephone } = extractCodeFromPhone(addressDefaults.telephone, addressDefaults.phoneCode);
    addressDefaults.telephone = telephone;
    if (phoneCode) {
        addressDefaults.phoneCode = phoneCode;
    }
    if (selectedMethod?.extra_data?.city_limit) {
        addressDefaults.city = selectedMethod?.extra_data?.city_limit;
    }

    const savedAddressFields = JSON.parse(localStorage.getItem('saved_address_fields') ?? '{}') as IGuestAddress;

    const [fields, setFields] = useState<IGuestAddress>(
        Object.keys(savedAddressFields).length > 0 ? savedAddressFields : addressDefaults,
    );

    let regions = Regions;
    if (selectedMethod?.extra_data?.allow_only?.length) {
        regions = regions.filter(
            (regionItem) => !regionItem.value || selectedMethod.extra_data.allow_only.includes(regionItem.value),
        );
    }
    const defaultRegion = regions.find((reg) => reg.value === addressDefaults.region_id);
    const [region, setRegion] = useState(defaultRegion || regions[0]);

    const showField = (fieldName: string) =>
        selectedMethod && (!selectedMethod.visibleFields || selectedMethod.visibleFields.includes(fieldName));

    const changeRegion = (regionItem: IRegionOptionListItem) => {
        setRegion(regionItem);
        setFields({
            ...fields,
            region_id: regionItem.value,
            country_id: regionItem.country_id,
        });
    };
    const validateForm = () => {
        const validationRules: IValidationItem[] = BaseAddressRules();
        validationRules.push({
            field: AddressFields.email,
            validations: [
                { rule: VALIDATION_TYPES.REQUIRED, skip: isLoggedIn() },
                { rule: VALIDATION_TYPES.EMAIL, skip: isLoggedIn() },
            ],
        });

        errors.current = validate(
            validationRules.filter((rule) => showField(rule.field)),
            fields,
            t,
        );
    };

    const getError = (field) => getErrorForField(field, errors.current);

    const effect = (event) => {
        if (email) {
            fields.email = email;
            setEmail(email);
        }
        validateForm();
        updateState(!updateStateStatus);

        const address = { ...fields, isNew: 1 };
        Object.entries(address).map(([field, value]) => {
            if (empty(address[field])) {
                address[field] = dummyFields[field];
            }
        });
        if (errors.current.length < 1) {
            selectAddress(address);
            if (overlayOpen) {
                history.goBack();
                closeOverlay('add-address');
            }
            if (event.detail) {
                proceedAction();
            }
        }
    };

    useEffect(() => {
        window.addEventListener('new-address-save', effect);
        return function cleanup() {
            window.removeEventListener('new-address-save', effect);
        };
    });

    useEffect(() => {
        if (!empty(fields)) {
            setNewAddressFields(fields);
        }
        if (selectedAddress && errors.current.length < 1) {
            const address = { ...selectedAddress, ...fields };
            selectAddress(address);
        }
        localStorage.setItem('saved_address_fields', JSON.stringify(fields));
    }, [fields]);

    const adsSave = (address: IAdsAddress | undefined) => {
        if (address && address.postcode) {
            changeRegion(regions.find((reg) => reg.value === address.region_id));
            if (!!selectedMethod?.extra_data?.city_limit) {
                address.city = fields.city;
            }
            errors.current = errorDefaults;
            setFields({ ...fields, ...address });
            setShowAdsFields(true);
        } else {
            setShowAdsFields(false);
        }
    };
    const adsSet = showAdsFields !== undefined;

    return (
        <React.Fragment>
            <LayoutForm layout="vertical">
                {!isLoggedIn() && (
                    <LayoutFormColumn>
                        <FormRow
                            label={t('checkout.Email Address')}
                            description={t('checkout.descriptionContactEmail')}
                            required={true}
                            error={getError('email')}
                        >
                            <FormRowGroup>
                                <ControlInput
                                    name={'email'}
                                    autocomplete={true}
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </FormRowGroup>
                        </FormRow>
                    </LayoutFormColumn>
                )}
                <LayoutFormColumn>
                    <React.Fragment>
                        {showField(AddressFields.firstname) && (
                            <FormRow
                                label={t('checkout.Firstname')}
                                required={true}
                                error={getError(AddressFields.firstname)}
                            >
                                <ControlInput
                                    name={'firstname'}
                                    autocomplete={true}
                                    value={fields.firstname}
                                    onChange={(e) => setFields({ ...fields, firstname: e.target.value })}
                                />
                            </FormRow>
                        )}
                    </React.Fragment>
                    <React.Fragment>
                        {showField(AddressFields.lastname) && (
                            <FormRow
                                label={t('checkout.Lastname')}
                                required={true}
                                error={getError(AddressFields.lastname)}
                            >
                                <ControlInput
                                    name={'lastname'}
                                    autocomplete={true}
                                    value={fields.lastname}
                                    onChange={(e) => setFields({ ...fields, lastname: e.target.value })}
                                />
                            </FormRow>
                        )}
                    </React.Fragment>
                    <React.Fragment>
                        {showField(AddressFields.telephone) && (
                            <FormRow
                                label={t('checkout.Mobile number')}
                                required={true}
                                error={getError(AddressFields.telephone)}
                            >
                                <FormRowGroup>
                                    <ControlSelect
                                        value={fields.phoneCode}
                                        onChange={(e) => setFields({ ...fields, phoneCode: e.target.value })}
                                        size="small"
                                    >
                                        {contactPhoneAreaItems.map((item) => (
                                            <option
                                                key={item}
                                                value={item}
                                            >
                                                {item}
                                            </option>
                                        ))}
                                    </ControlSelect>
                                    <ControlInput
                                        name={'phone'}
                                        autocomplete={true}
                                        value={fields.telephone}
                                        onChange={(e) => setFields({ ...fields, telephone: e.target.value })}
                                    />
                                </FormRowGroup>
                            </FormRow>
                        )}
                    </React.Fragment>
                    {window.inAddressConfiguration?.enabled && selectedMethod?.extra_data?.ads_enabled && (
                        <AdsSearch
                            config={window.inAddressConfiguration}
                            setAddressState={adsSave}
                            error={adsSet && !showAdsFields}
                        />
                    )}
                    <React.Fragment>
                        {showField(AddressFields.street) && showAdsFields && !!fields.street[0] && (
                            <FormRow
                                label={t('checkout.Address')}
                                required={true}
                                error={getError(AddressFields.street)}
                            >
                                <ControlInput
                                    readonly={true}
                                    name={'street'}
                                    autocomplete={true}
                                    value={fields.street[0] || ''}
                                    onChange={(e) => setFields({ ...fields, street: [e.target.value] })}
                                />
                            </FormRow>
                        )}
                        {showField(AddressFields.city) && showAdsFields && !!fields.city && (
                            <FormRow
                                label={t('checkout.City')}
                                required={true}
                                error={getError(AddressFields.city)}
                            >
                                <ControlInput
                                    readonly={true}
                                    name={'city'}
                                    autocomplete={true}
                                    disabled={!!selectedMethod?.extra_data?.city_limit}
                                    value={fields.city}
                                    onChange={(e) => setFields({ ...fields, city: e.target.value })}
                                />
                            </FormRow>
                        )}
                        {showField(AddressFields.region_id) && showAdsFields && !!fields.region_id && (
                            <FormRow
                                label={t('checkout.County')}
                                required={true}
                                error={getError(AddressFields.region_id)}
                            >
                                <ControlInput
                                    readonly={true}
                                    name={'city'}
                                    autocomplete={true}
                                    disabled={!!selectedMethod?.extra_data?.city_limit}
                                    value={regions.find((reg) => reg.value === fields.region_id)?.label ?? ''}
                                    onChange={(e) => setFields({ ...fields, region_id: e.target.value })}
                                />
                            </FormRow>
                        )}
                        {showField(AddressFields.postcode) && showAdsFields && !!fields.postcode.length && (
                            <FormRow
                                label={t('checkout.Postcode')}
                                required={true}
                                error={getError(AddressFields.postcode)}
                            >
                                <ControlInput
                                    readonly={true}
                                    name={'postcode'}
                                    autocomplete={true}
                                    value={fields.postcode}
                                    onChange={(e) => {
                                        setFields({ ...fields, postcode: e.target.value });
                                    }}
                                />
                            </FormRow>
                        )}
                    </React.Fragment>
                    {isLoggedIn && showField(AddressFields.street) && (
                        <React.Fragment>
                            <FormRow>
                                <CheckboxWithLabel
                                    label={t('Save in address book')}
                                    checked={!!fields.saveInAddressBook}
                                    onChange={() =>
                                        setFields({ ...fields, saveInAddressBook: !fields.saveInAddressBook })
                                    }
                                />
                            </FormRow>
                        </React.Fragment>
                    )}
                </LayoutFormColumn>
            </LayoutForm>
        </React.Fragment>
    );
};

export default NewAddressForm;
