import React, { useState } from 'react';
import * as webeid from '@web-eid/web-eid-library/web-eid.js';
import { useMutation } from 'redux-query-react';
import authenticateStartRequest, {
    IAuthenticateStartResponse,
} from 'components/Authorization/Login/requests/AuthenticateStartRequest';
import authenticateRequest, {
    IAuthenticateResponse,
} from 'components/Authorization/Login/requests/AuthenticateRequest';
import Buttons from 'ekaubamaja-ui/lib/Components/Buttons';
import Button from 'ekaubamaja-ui/lib/Components/Button';
import Toaster from 'ekaubamaja-ui/lib/Components/Toaster';

export interface IIdCardConfig {
    isEnabled: boolean;
    labels: {
        somethingWentWrong: string;
        signInLabel: string;
    };
}

export interface IIdCardProps {
    config: IIdCardConfig;
    registerLabel?: string;
}

const IdCard: React.FunctionComponent<IIdCardProps> = (props) => {
    const { config, registerLabel } = props;
    const { labels } = config;
    const [isPolling, setIsPolling] = useState(false);
    const [{}, idCardAuthenticateStart] = useMutation(() => authenticateStartRequest());
    const [{}, idCardAuthenticate] = useMutation((authToken: string) => authenticateRequest(authToken));

    const onAuthenticate = () => {
        setIsPolling(true);

        void idCardAuthenticateStart().then((challengeResponse) => {
            const { nonce } = challengeResponse.body as IAuthenticateStartResponse;

            void webeid.authenticate(nonce, { lang: 'ee' }).then((authToken) => {
                void idCardAuthenticate(JSON.stringify(authToken)).then((authResponse) => {
                    if (authResponse.status === 200) {
                        const response = authResponse.body as IAuthenticateResponse;

                        if (response.status === 1) {
                            window.location.href = '/idcard/login';
                        } else {
                            setIsPolling(false);
                            Toaster.addToast({
                                intent: 'danger',
                                text: response.message,
                                asHtml: true,
                            });
                        }
                    } else {
                        setIsPolling(false);
                        Toaster.addToast({
                            intent: 'danger',
                            text: labels.somethingWentWrong,
                            asHtml: true,
                        });
                    }
                });
            });
        });
    };
    return (
        <React.Fragment>
            <Buttons>
                <Button
                    title={registerLabel ?? config.labels.signInLabel}
                    intent="primary"
                    type={'submit'}
                    onClick={onAuthenticate}
                    disabled={isPolling}
                />
            </Buttons>
        </React.Fragment>
    );
};

export default IdCard;
