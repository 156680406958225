import Menu from 'ekaubamaja-ui/lib/Components/Menu';
import MenuItem from 'ekaubamaja-ui/lib/Components/MenuItem';
import * as React from 'react';
import { IUserHubWishlistMenuItems } from 'components/UserHubWishlist/index';

interface IProps {
    menuItems: IUserHubWishlistMenuItems[];
}

const RenderWishlistMenu: React.FunctionComponent<IProps> = (props) => {
    return (
        <div className="userhub__menu">
            <Menu>
                {props.menuItems.map((menuItem: IUserHubWishlistMenuItems, key: number) => {
                    return (
                        <MenuItem
                            key={key}
                            text={menuItem.text}
                            href={menuItem.url}
                            notifications={menuItem.notificationValue}
                        />
                    );
                })}
            </Menu>
        </div>
    );
};

export default RenderWishlistMenu;
