import * as React from 'react';
import LayoutForm from 'ekaubamaja-ui/lib/Layouts/LayoutForm';
import FormRow from 'ekaubamaja-ui/lib/Components/FormRow';
import Buttons from 'ekaubamaja-ui/lib/Components/Buttons';
import Button from 'ekaubamaja-ui/lib/Components/Button';
import { ICartTotals } from 'components/Cart/Interfaces/ICartTotals';
import { empty } from '../../helpers/empty';
import RenderHTML from 'ekaubamaja-ui/lib/Components/RenderHTML';

interface IProps {
    cartTotals?: ICartTotals | null;
    buttonHref: string;
    buttonLabel: string;
    cartContainerBeforeTotals?: string | null;
}

const RenderCartMenu: React.FunctionComponent<IProps> = (props) => {
    const { cartTotals, cartContainerBeforeTotals } = props;

    return (
        <React.Fragment>
            {cartTotals && (
                <div className="userhub__delivery">
                    <div className="userhub__column">
                        {!empty(cartContainerBeforeTotals) && cartContainerBeforeTotals && (
                            <div className="userhub__callout">
                                <RenderHTML
                                    html={cartContainerBeforeTotals}
                                    nowrapper={true}
                                />
                            </div>
                        )}
                        <LayoutForm layout="details">
                            <FormRow
                                label={cartTotals.grandTotal.label}
                                value={cartTotals.grandTotal.value}
                                important={true}
                            />
                            {!!cartTotals.shipping && parseFloat(cartTotals.shipping.value) > 0 && (
                                <React.Fragment>
                                    <FormRow
                                        label={cartTotals.subtotal.label}
                                        value={cartTotals.subtotal.value}
                                    />
                                    <FormRow
                                        label={cartTotals.shipping.label}
                                        value={cartTotals.shipping.value}
                                    />
                                </React.Fragment>
                            )}
                            <FormRow
                                label={cartTotals.withoutTax.label}
                                value={cartTotals.withoutTax.value}
                            />
                            {cartTotals.taxes &&
                                cartTotals.taxes.map((item, key) => (
                                    <FormRow
                                        key={key}
                                        label={item.label}
                                        value={item.value}
                                    />
                                ))}
                            {!!cartTotals.pawnTotal && (
                                <FormRow
                                    label={cartTotals.pawnTotal.label}
                                    value={cartTotals.pawnTotal.value}
                                />
                            )}
                        </LayoutForm>
                        <Buttons layout="vertical-wide">
                            <Button
                                title={props.buttonLabel}
                                type="anchor"
                                href={props.buttonHref}
                                intent="primary"
                            />
                        </Buttons>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
};

export default RenderCartMenu;
