import { combineReducers } from 'redux';
import { entitiesReducer, queriesReducer } from 'redux-query';
import { OverlaysState } from 'data/overlays/interfaces';
import overlaysReducer from 'data/overlays/overlaysReducer';
import gridViewReducer from 'components/Common/store/gridViewReducer';
import { IGridViewState } from 'components/Common/store/gridViewSelector';
import { IProductsState } from 'components/Common/store/productsSelector';
import productsReducer from 'components/Common/store/productsReducer';

export interface IStoreState {
    overlaysReducer: OverlaysState;
    gridViewReducer: IGridViewState;
    productsReducer: IProductsState;
}

const combineRootReducer = combineReducers({
    entities: entitiesReducer,
    queries: queriesReducer,
    overlaysReducer,
    gridViewReducer,
    productsReducer,
});

export default combineRootReducer;
