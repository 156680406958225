import * as React from 'react';

export interface ICartProductProps {
    addToCartLabel: string;
}

const CartButton = (props: ICartProductProps) => {
    const { addToCartLabel } = props;

    return (
        <React.Fragment>
            <span className="icon">
                <img
                    src={`${window.assetUrl}/assets/img/sprite_cart.svg`}
                    alt={addToCartLabel}
                    width="16"
                    height="64"
                />
            </span>
            <span className="label">{addToCartLabel}</span>
        </React.Fragment>
    );
};

export default CartButton;
