import * as React from 'react';
import { empty } from '../../helpers/empty';
import { Popover } from 'ekaubamaja-ui/lib/Components/Popover';
import Menu from 'ekaubamaja-ui/lib/Components/Menu';
import MenuItem from 'ekaubamaja-ui/lib/Components/MenuItem';
import { CartAmountSizes } from '../../enums/CartAmountSizes';
import Icon from 'ekaubamaja-ui/lib/Components/Icon';

interface IProps {
    labels: IAmountSizeLabels;
    product: IAmountSizeProduct;
    selectedAmountSize: string;
    setSelectedAmountSize: (value: string) => void;
}

export interface IAmountSizeLabels {
    piece: string;
    pieceMultipleShort: string;
    box: string;
    base: string;
}

interface IAmountSizeProduct {
    caseQty?: number;
    palletQty?: number;
}

const AmountSize: React.FunctionComponent<IProps> = (props) => {
    const { product, labels } = props;

    return (
        <>
            {(!empty(product.caseQty) || !empty(product.palletQty)) && (
                <Popover
                    content={
                        <Menu className="cart-control__menu">
                            <MenuItem
                                onClick={() => props.setSelectedAmountSize(CartAmountSizes.PIECE)}
                                active={props.selectedAmountSize === CartAmountSizes.PIECE}
                                text={labels.piece.toLowerCase()}
                            />
                            {!empty(product.caseQty) && (
                                <MenuItem
                                    onClick={() => props.setSelectedAmountSize(CartAmountSizes.BOX)}
                                    active={props.selectedAmountSize === CartAmountSizes.BOX}
                                    text={`${labels.box.toLowerCase()} (${product.caseQty} ${
                                        labels.pieceMultipleShort
                                    })`}
                                />
                            )}
                            {!empty(product.palletQty) && (
                                <MenuItem
                                    onClick={() => props.setSelectedAmountSize(CartAmountSizes.BASE)}
                                    active={props.selectedAmountSize === CartAmountSizes.BASE}
                                    text={`${labels.base.toLowerCase()} (${product.palletQty} ${
                                        labels.pieceMultipleShort
                                    })`}
                                />
                            )}
                        </Menu>
                    }
                    target={
                        <div className="cart-control__units__wrapper">
                            <span className="label">
                                {props.selectedAmountSize === CartAmountSizes.PIECE && labels.piece.toLowerCase()}
                                {props.selectedAmountSize === CartAmountSizes.BOX && labels.box.toLowerCase()}
                                {props.selectedAmountSize === CartAmountSizes.BASE && labels.base.toLowerCase()}
                            </span>
                            <Icon
                                width={8}
                                height={8}
                                kind="arrow3-down"
                            />
                        </div>
                    }
                    hasBackdrop={false}
                    portalClassName={'popover-default-portal'}
                    popoverClassName={'popover-default-popover nopadding'}
                    className="cart-control__units"
                    position="bottom-right"
                    minimal={true}
                    usePortal={true}
                    modifiers={{
                        offset: {
                            offset: '20, 5',
                        },
                    }}
                />
            )}
        </>
    );
};

export default AmountSize;
