import * as React from 'react';
import { useMutation } from 'redux-query-react';
import { IRequestData, request } from 'data/requests/request';
import Summary from 'ekaubamaja-ui/lib/Applications/Checkout/Components/Summary';
import { ISummaryMeta, ISummaryProduct } from 'ekaubamaja-ui/lib/Applications/Checkout/Components/Summary/index';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';

import { MutableRefObject } from 'react';

import useErrorState from 'components/error/Error';
import { IQuote } from 'components/Checkout/interfaces/checkout/quote/IQuote';
import isLoggedIn from '../../../helpers/auth/isLoggedIn';
import LoadingContent from 'ekaubamaja-ui/lib/Components/LoadingContent';
import { sum } from 'components/Checkout/helpers/sum';
import { ICustomer } from 'components/Checkout/interfaces/checkout/customer/ICustomer';

export interface IProps {
    customer: ICustomer | null;
    cart: any;
    shippingPrice: MutableRefObject<number>;
    setCart: any;
    quote: IQuote | null;
}

const PRODUCT_CAMPAIGN_SEGMENT = 'product_campaign_discount';
const GIFT_CARD_SEGMENT = 'gift_card_discount';

const Cart = (props: IProps) => {
    const { cart, setCart, shippingPrice, quote } = props;
    const queryingCart = useRef(false);
    const { t } = useTranslation();
    const data: IRequestData = {
        type: 'cart',
        url: isLoggedIn() ? 'carts/mine/totals' : `guest-carts/${window.quoteIdMask}/totals`,
    };
    const [{ isFinished, status }, cartRequest] = useMutation(() => request(data));
    const responseSuccessful = () => !!cart && status === 200;
    const { setError } = useErrorState();
    const [updateState, setUpdateState] = useState(false);
    const metaDefault: ISummaryMeta[] = [];
    const cartMetaData = useRef(metaDefault);

    let cartItems: ISummaryProduct[] =
        responseSuccessful() &&
        cart.items
            .filter((item) => !item.extension_attributes.is_pawn)
            .map((item, key) => {
                const sumAddOn: React.ReactNode[] = [];

                const description = quote?.items?.find((quoteItem) => item.item_id === quoteItem.item_id)?.sku;
                const rowTotal = item.row_total_incl_tax ?? item.price * item.qty;
                return {
                    name: item.name,
                    amount: item.qty,
                    description,
                    sum: `${sum(rowTotal)}&nbsp;€`,
                    sumAddOn,
                };
            });
    if (!cartItems) {
        cartItems = [];
    }
    const getSegmentAttribute = (attributeName: string, usePartialSearch: boolean = false) => {
        if (responseSuccessful()) {
            const attribute = cart.total_segments.find((item) => item.code === attributeName);
            if (attribute) {
                return attribute;
            }

            if (usePartialSearch) {
                const attributes = cart.total_segments.filter((item) => {
                    return item.code.substr(0, attributeName.length) === attributeName;
                });

                if (attributes) {
                    return attributes;
                }
            }
        }
        return undefined;
    };

    const updateMeta = () => {
        const metaCart = cart;
        if (!metaCart || !responseSuccessful()) {
            return;
        }
        if (metaCart) {
            metaCart.base_grand_total = parseFloat(
                (metaCart.base_grand_total - (cart?.shipping_incl_tax || 0)).toFixed(2),
            );
            metaCart.base_grand_total = parseFloat((metaCart.base_grand_total + shippingPrice.current).toFixed(2));
            metaCart.shipping_incl_tax = shippingPrice.current;
        }
        let subtotal = metaCart ? metaCart.subtotal_incl_tax : 0;

        const meta: ISummaryMeta[] = [];
        meta.push({
            label: t('checkout.Cart Subtotal'),
            sum: `${sum(subtotal)}&nbsp;€`,
        });

        if (cart?.extension_attributes?.total_pawn) {
            meta.push({
                label: t('checkout.Cart pawn'),
                sum: `${sum(metaCart.extension_attributes.total_pawn)}&nbsp;€`,
            });
        }

        meta.push({
            label: t('checkout.Shipping'),
            sum: `${metaCart ? sum(metaCart.shipping_incl_tax) : 0}&nbsp;€`,
        });

        const productCampaignDiscount = getSegmentAttribute(PRODUCT_CAMPAIGN_SEGMENT, true);
        const giftCardDiscount = getSegmentAttribute(GIFT_CARD_SEGMENT);

        if (productCampaignDiscount) {
            productCampaignDiscount.forEach((discount) => {
                meta.push({
                    label: discount.title,
                    sum: `-${sum(discount.value)}&nbsp;€`,
                });
            });
        }

        if (giftCardDiscount) {
            meta.push({
                label: t('checkout.Gift Card Discount'),
                sum: `${sum(giftCardDiscount.value)}&nbsp;€`,
            });
        }

        if (cart.discount_amount) {
            meta.push({
                label: t('checkout.Cart discount'),
                sum: `${sum(cart.discount_amount)}&nbsp;€`,
            });
        }

        meta.push({
            label: t('checkout.Cart Total'),
            sum: `${sum(cart.base_grand_total)}&nbsp;€`,
        });

        cartMetaData.current = meta;
    };
    updateMeta();

    const shippingDataSave = () => {
        updateCart(true);
    };

    const updateCart = (updateShippingPrice = false) => {
        if (!queryingCart.current) {
            queryingCart.current = true;
            cartRequest().then((response) => {
                queryingCart.current = false;
                if (updateShippingPrice) {
                    shippingPrice.current = response.body.shipping_incl_tax;
                }
                setCart(response.body);
            });
        }
    };
    const updateShipping = (event) => {
        shippingPrice.current = parseFloat(event.detail?.discountPrice ?? event.detail?.price);
        updateMeta();
        setUpdateState(!updateState);
    };

    if ((!cart && !queryingCart.current) || (!isFinished && !!cart)) {
        updateCart();
    }
    useEffect(() => {
        if (isFinished && !!cart && !responseSuccessful()) {
            setError(true);
        }
        window.addEventListener('shipping-additional-change', updateShipping);
        window.addEventListener('shipping-additional-save', shippingDataSave);
        return function cleanup() {
            window.removeEventListener('shipping-additional-save', shippingDataSave);
            window.removeEventListener('shipping-additional-change', updateShipping);
        };
    });

    return isFinished && responseSuccessful() ? (
        <React.Fragment>
            <Summary
                title={`${t('checkout.Order')} ${responseSuccessful() ? cart.extension_attributes.order_id ?? '' : ''}`}
                products={cartItems}
                meta={cartMetaData.current}
                totalLabel={t('checkout.x')}
            />
        </React.Fragment>
    ) : (
        <LoadingContent />
    );
};
export default Cart;
