import * as React from 'react';
import { Popover, PopoverInteractionKind } from 'ekaubamaja-ui/lib/Components/Popover';
import RenderWishlistButton from 'components/UserHubWishlist/RenderWishlistButton';
import RenderWishlistMenu from 'components/UserHubWishlist/RenderWishlistMenu';
import Overlay from 'ekaubamaja-ui/lib/Components/Overlay';
import Icon from 'ekaubamaja-ui/lib/Components/Icon';
import { useMutation, useRequest } from 'redux-query-react';
import { request } from 'data/requests/request';
import { useSelector } from 'react-redux';
import { useCallback, useEffect } from 'react';

interface IProps {
    config: {
        label: string;
        url: string;
    };
}

const mapStateToProps = (state) => {
    return {
        userHubWishlistGuestResponse: state.entities.userHubWishlistGuest,
    };
};

const UserHubWishlistGuest: React.FunctionComponent<IProps> = (props) => {
    const { config } = props;

    const [{ isFinished }] = useRequest(
        request({ type: 'userHubWishlistGuest', url: 'wishlist/wishlist/userHubWishlistGuest', notApi: true }),
    );
    const { userHubWishlistGuestResponse } = useSelector(mapStateToProps);
    const [{}, notificationsRequest] = useMutation(() =>
        request({ type: 'userHubWishlistGuest', url: `wishlist/wishlist/userHubWishlistGuest`, notApi: true }),
    );

    const effect = useCallback(async () => {
        notificationsRequest();
    }, [notificationsRequest]);

    useEffect(() => {
        window.addEventListener('wishlist-altered', effect);
        return function cleanup() {
            window.removeEventListener('wishlist-altered', effect);
        };
    });

    return (
        <React.Fragment>
            <a href={config.url}>
                <span className="icon">
                    <img
                        src={`${window.assetUrl}/assets/img/sprite_heart.svg`}
                        alt={config.label}
                        width="16"
                        height="16"
                    />
                </span>
                <span className="label">{config.label}</span>
                {isFinished && (
                    <b className="notifications">
                        {userHubWishlistGuestResponse?.notifications ? userHubWishlistGuestResponse?.notifications : 0}
                    </b>
                )}
            </a>
        </React.Fragment>
    );
};

export default UserHubWishlistGuest;
