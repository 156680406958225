import { ICustomer } from '../../interfaces/checkout/customer/ICustomer';
import * as React from 'react';
import { empty } from '../../../../helpers/empty';
import { baseDummyFields } from 'components/Checkout/shipping/address/dummyFields';

const ExistingAddresses = (
    isBusiness: boolean,
    selectedAddress,
    selectAddress,
    selectedMethod,
    customer: ICustomer | null,
    selectedAddressId: number | null,
) => {
    let addresses = customer?.addresses;
    if (selectedAddress && selectedAddress.isNew) {
        const alreadySelectedNewAddress = addresses && addresses.find((address) => address.isNew);
        if (alreadySelectedNewAddress) {
            if (alreadySelectedNewAddress !== selectedAddress) {
                addresses?.pop();
                addresses?.push(selectedAddress);
            }
        } else {
            addresses?.push(selectedAddress);
        }
    }

    // This removes small fielded addresses from big address methods.
    if (selectedMethod && !isBusiness) {
        addresses = addresses?.filter((address) => {
            const dummyValues = Object.entries(baseDummyFields).filter(([field, dummyValue]) => {
                return selectedMethod && selectedMethod.visibleFields.includes(field) && address[field] === dummyValue;
            });
            return dummyValues.length < 1;
        });
    }

    return addresses
        ? addresses.map((address) => {
              const vatEmpty = empty(address.vat_id) || address.vat_id === baseDummyFields.vat_id;
              const canBeShippingDefault = !isBusiness && address.default_shipping && vatEmpty;
              const canBeBillingDefault = isBusiness && address.default_billing;
              // No selected address present AND can be preselected
              if (!selectedAddress && (canBeShippingDefault || canBeBillingDefault)) {
                  // Address already saved in backend OR no address is not yet saved in backend
                  if ((selectedAddressId && selectedAddressId === address.id) || !selectedAddressId) {
                      selectAddress(address);
                  }
              }
              const fullName = [address.firstname, address.lastname].join(' ');
              return {
                  title: isBusiness ? `${address.company} (${address.vat_id})` : fullName,
                  content: (
                      <div>
                          <React.Fragment>
                              {isBusiness && (
                                  <React.Fragment>
                                      <div>{fullName}</div>
                                      <div>{address.street}</div>
                                      <div>{[address.city, address.postcode].join(', ')}</div>
                                  </React.Fragment>
                              )}
                              {selectedMethod && selectedMethod.visibleFields.includes('street') && (
                                  <div>{address.street}</div>
                              )}
                              {selectedMethod &&
                                  selectedMethod.visibleFields.includes('postcode') &&
                                  (selectedMethod.visibleFields.includes('city') ? (
                                      <div>{[address.city, address.postcode].join(', ')}</div>
                                  ) : (
                                      <div>{address.postcode}</div>
                                  ))}
                          </React.Fragment>
                          <div>
                              {address.phoneCode}
                              {address.telephone}
                          </div>
                      </div>
                  ),
                  selected: selectedAddress === address,
                  isBusiness: !vatEmpty,
                  isNew: address.isNew || 0,
                  onClick: () => {
                      selectAddress(address);
                  },
              };
          })
        : [];
};

export default ExistingAddresses;
