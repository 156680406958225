import * as React from 'react';
import ControlCheckbox from 'ekaubamaja-ui/lib/Components/ControlCheckbox';
import Title from 'components/Catalog/Product/Title';
import ControlInput from 'ekaubamaja-ui/lib/Components/ControlInput';
import { formatPriceLocale } from '../../../../helpers/priceUtils';
import { cloneDeep, parseInt, without } from 'lodash';

export interface IReturnProduct {
    id: string;
    name: string;
    sku: string;
    price: number;
    sum: number;
    qty: number;
    url: string;
    relatedProduct: Omit<IReturnProduct, 'relatedProduct'>;
}

export interface IReturnTableLabels {
    product: string;
    amount: string;
    price: string;
    sum: string;
}

export interface IReturnTableProps {
    products: IReturnProduct[];
    productsSelected: IReturnProduct[];
    setProductsSelected: (products: IReturnProduct[]) => void;
    labels: IReturnTableLabels;
}

const ProductReturnTable: React.FunctionComponent<IReturnTableProps> = (props) => {
    const { products, labels, productsSelected, setProductsSelected } = props;

    const isProductSelected = (product: IReturnProduct): IReturnProduct | undefined => {
        return productsSelected.find((selectedProduct) => selectedProduct.sku === product.sku);
    };
    const selectSingle = (product: IReturnProduct, forcePush = false): void => {
        const productItems = without(
            productsSelected,
            productsSelected.find((selProduct) => product.id === selProduct.id) as unknown as IReturnProduct,
        );
        if (!isProductSelected(product) || forcePush) {
            productItems.push(product);
        }
        setProductsSelected(productItems);
    };

    const selectAll = (): void => {
        if (productsSelected.length === products.length) {
            setProductsSelected([]);
        } else {
            setProductsSelected(products);
        }
    };

    return (
        <div className="table">
            <table>
                <thead>
                    <tr>
                        <th>
                            <ControlCheckbox
                                checked={productsSelected.length === products.length}
                                onChange={selectAll}
                            />
                        </th>
                        <th>{labels.product}</th>
                        <th className="sum">{labels.amount}</th>
                        <th>{labels.price}</th>
                        <th className="sum">{labels.sum}</th>
                    </tr>
                </thead>
                <tbody>
                    {products.map((product) => {
                        const productSelected = isProductSelected(product);
                        return (
                            <React.Fragment>
                                <tr key={`productTr${product.id}`}>
                                    <td className="w1p">
                                        <ControlCheckbox
                                            checked={!!productSelected}
                                            onChange={() => selectSingle(cloneDeep(product))}
                                        />
                                    </td>
                                    <td>
                                        <Title
                                            titlePrimary={product.name}
                                            href={product.url}
                                        />
                                        <div className="box-product__codes">{product.sku}</div>
                                    </td>
                                    <td className="sum">
                                        {!!productSelected && (
                                            <ControlInput
                                                style={{ maxWidth: '100px', float: 'right' }}
                                                value={productSelected.qty.toString()}
                                                onChange={(value) => {
                                                    const newQty = value.target.value.replace(/[^0-9\s]/, '');
                                                    if (!!productSelected) {
                                                        productSelected.qty = newQty;
                                                        selectSingle(productSelected, true);
                                                        if (
                                                            parseInt(product?.qty as unknown as string) <
                                                            parseInt(newQty)
                                                        ) {
                                                            productSelected.qty = product.qty;
                                                            selectSingle(productSelected, true);
                                                        }
                                                    }
                                                }}
                                            />
                                        )}
                                        {!productSelected && <React.Fragment>{product.qty}</React.Fragment>}
                                    </td>
                                    <td>{formatPriceLocale(product.price)}</td>
                                    <td className="sum">{formatPriceLocale(product.sum)}</td>
                                </tr>
                                {product?.relatedProduct && (
                                    <tr className="subrow">
                                        <td className="w1p">
                                            <ControlCheckbox
                                                checked={!!productSelected}
                                                disabled={true}
                                            />
                                        </td>
                                        <td>
                                            <Title titleSecondary={product.relatedProduct.name} />
                                            <div className="box-product__codes">{product.relatedProduct.sku}</div>
                                        </td>
                                        <td className="sum">
                                            {!productSelected ? product.qty : productSelected.qty.toString()}
                                        </td>
                                        <td>{formatPriceLocale(product.relatedProduct.price)}</td>
                                        <td className="sum">{formatPriceLocale(product.relatedProduct.sum)}</td>
                                    </tr>
                                )}
                            </React.Fragment>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default ProductReturnTable;
