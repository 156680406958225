import * as React from 'react';
import { empty } from '../../../../helpers/empty';
import { useMutation } from 'redux-query-react';
import { request } from 'data/requests/request';
import { useEffect, useState } from 'react';
import RedirectAbsolute from 'components/Redirect/RedirectAbsolute';
import { RequestBody } from 'redux-query';
import { Banklink, IMakeCommerceConfig } from 'components/Checkout/interfaces/payment/MakeCommerce/IMakeCommerceConfig';
import { IPaymentMethodProps } from 'components/Checkout/payment/PaymentBlock';
import { PaymentMethodEnum } from 'components/Checkout/payment/MethodHandler';
import { ResponseStatus } from '../../../../enums/ResponseStatus';
import isLoggedIn from '../../../../helpers/auth/isLoggedIn';
import { IMakeCommercePaymentResponse } from 'components/Checkout/interfaces/payment/MakeCommerce/IMakeCommercePaymentResponse';
import { ICustomer } from 'components/Checkout/interfaces/checkout/customer/ICustomer';
import useOverlays from 'components/Checkout/overlay/Overlay';
import useScript from 'components/Checkout/helpers/useScript';
import SimplePayment from 'components/Checkout/payment/methods/SimplePayment';

let requestDone = false;
let paymentRequestDone = false;
let config: Partial<IMakeCommerceConfig> = {};
let returnUrl: string = '';

interface IProps {
    paymentMethodProps: IPaymentMethodProps;
}

interface Mk {
    Checkout: {
        initialize: (data: MkData) => void;
        open: (data: MkData) => void;
    };
}

interface MkData {
    amount: string;
    currency: string;
    email: string;
    clientName: string;
    transaction: string;
    key: string;
    locale: string;
    name: string;
    cancelled: string;
    noConflict: boolean;
    completed: string;
    description: string;
}

declare global {
    class Maksekeskus {}
}

const MakeCommerce = (props: IProps) => {
    const { closeOverlay } = useOverlays();
    const [{}, requestHelper] = useMutation((requestPayload: RequestBody) => request(requestPayload));
    const [] = useScript(config.makecommerce?.checkoutJs || '');
    const [stateChange, setStateChange] = useState(false);
    const [redirect, setRedirect] = useState<{ to: string }>();
    if (!requestDone) {
        requestDone = true;

        const args = { type: PaymentMethodEnum.makecommerce, url: 'checkout/makecommerce' };

        requestHelper(args as never).then((response) => {
            const responseSuccessful = response && response.status === ResponseStatus.ok;
            if (responseSuccessful) {
                config = response?.body[0];
                setStateChange(!stateChange);
            }
        });
    }

    const methods: any[] = [];
    if (!empty(config)) {
        const bankLinks = config.makecommerce?.paymentChannels.banklinks;
        const payLaters = config.makecommerce?.paymentChannels.payLater;
        const cards = config.makecommerce?.paymentChannels.cards;

        const simpleElement = (bankLink: Banklink) => (
            <SimplePayment
                method={PaymentMethodEnum.makecommerce}
                key={bankLink.name}
                onClick={() => {
                    const paymentVerificationUrl = isLoggedIn()
                        ? 'makecommerce/payment-adapter/mine'
                        : `makecommerce/payment-adapter/${window.quoteIdMask}`;
                    const extraParams = {
                        cartId: window.quoteIdMask,
                        country: !!config.makecommerce?.countries.length ? config.makecommerce?.countries[0].name : '',
                    };
                    const paymentMethodExtraData = { extraParams, paymentVerificationUrl };

                    props.paymentMethodProps.onClick({
                        paymentMethodExtraData,
                        redirect: undefined,
                        rawMethodData: bankLink,
                    });
                }}
                logoUrl={bankLink.logo_url}
                label={bankLink.name}
                paymentMethodProps={props.paymentMethodProps}
            />
        );

        if (!empty(bankLinks)) {
            bankLinks?.forEach((bankLink) => methods.push(simpleElement(bankLink)));
        }
        if (!empty(payLaters)) {
            payLaters?.forEach((payLater) => {
                if (
                    payLater.min_amount &&
                    payLater.min_amount <= (props.paymentMethodProps.cart?.base_grand_total || 0)
                ) {
                    methods.push(simpleElement(payLater));
                }
            });
        }
        if (!empty(cards)) {
            cards?.forEach((card) => methods.push(simpleElement({ ...card, url: '' })));
        }
    }

    const urlOverride = (
        paymentResponse: Partial<IMakeCommercePaymentResponse>,
        rawData: any,
        customer?: ICustomer,
    ) => {
        const selectedChannel: Banklink = rawData;
        if (selectedChannel && selectedChannel.methodType === 'cards') {
            const makecommerce = config?.makecommerce;
            if (!makecommerce) {
                return;
            }
            let clientName = '';
            if (customer && parseInt(makecommerce.ui.cardPrefill || '0') === 1) {
                clientName = `${customer.firstname} ${customer.lastname}`;
            }
            const orderId = paymentResponse.increment_id || '';
            const totals = paymentResponse.transaction?.amount || '';
            const email = customer?.email || '';
            const data: MkData = {
                amount: parseFloat(totals).toFixed(2),
                currency: paymentResponse.transaction?.currency || '€',
                email,
                clientName,
                transaction: paymentResponse.transaction?.id || '',
                key: makecommerce.publicKey,
                locale: makecommerce.locale,
                name: makecommerce.ui.cardShopName,
                cancelled: 'mkCardCancelled',
                noConflict: false,
                completed: 'mkCardCompleted',
                description: (makecommerce.ui.referenceText || '').replace('%', orderId),
            };
            window.mkCardCancelled = () => {
                setRedirect({ to: `checkout` });
            };

            window.mkCardCompleted = (token) => {
                const transactionData = JSON.parse(token?.json);
                if (!transactionData) {
                    setRedirect({ to: `checkout` });
                }

                if (!paymentRequestDone && transactionData) {
                    paymentRequestDone = true;
                    requestHelper({
                        type: 'makecommercePayment',
                        url: `makecommerce/pay/${transactionData.transaction?.id}`,
                        method: 'POST',
                        data: { token },
                    }).then((response) => {
                        const responseSuccessful = response && response.status === ResponseStatus.ok;
                        setRedirect({ to: responseSuccessful ? `checkout/onepage/success` : `checkout` });
                    });
                }
            };
            closeOverlay('payment-loading-overlay');
            (Maksekeskus as unknown as Mk).Checkout.initialize(data);
            (Maksekeskus as unknown as Mk).Checkout.open(data);
        } else if (paymentResponse?.transaction?.payment_methods[selectedChannel.methodType.toLowerCase()]) {
            paymentResponse?.transaction?.payment_methods[selectedChannel.methodType.toLowerCase()].forEach((val) => {
                if (val.name === selectedChannel.name && val.country === selectedChannel.country) {
                    returnUrl = val.url;
                }
            });
        }
        return returnUrl;
    };

    const effect = (event) => {
        if (event.detail?.setRedirectUrl && event.detail.methodCode === PaymentMethodEnum.makecommerce) {
            const redirectUrl: string =
                urlOverride(event.detail.paymentResponse, event.detail.rawData, event.detail.customer) || '';
            event.detail.setRedirectUrl({ to: redirectUrl, absolute: true });
        }
    };

    useEffect(() => {
        window.addEventListener('payment-response-receive', effect);
        return function cleanup() {
            window.removeEventListener('payment-response-receive', effect);
        };
    });

    return (
        <React.Fragment>
            {typeof redirect !== 'undefined' && redirect.to && <RedirectAbsolute to={redirect.to} />}
            {methods}
        </React.Fragment>
    );
};

export default MakeCommerce;
