import React, { useRef, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import IRegisterForm from 'components/Customer/interfaces/IRegisterForm';
import ControlInput from 'ekaubamaja-ui/lib/Components/ControlInput/index';
import CheckboxList from 'ekaubamaja-ui/lib/Applications/ePharma2/Components/CheckboxList/index';
import ControlPassword from 'components/GeneralForm/Field/ControlPassword';
import Buttons from 'ekaubamaja-ui/lib/Components/Buttons/index';
import Button from 'ekaubamaja-ui/lib/Components/Button/index';
import CheckboxWithLabel from 'ekaubamaja-ui/lib/Components/CheckboxWithLabel/index';
import FormKey from 'components/GeneralForm/Field/FormKey';
import { getCaptchaToken } from 'components/GoogleRecaptcha/Index';
import validatePasswordMinCharacterSets from '../../helpers/passwordValidator';
import LayoutForm from 'ekaubamaja-ui/lib/Layouts/LayoutForm';
import FormRow from 'ekaubamaja-ui/lib/Components/FormRow';

interface IProps {
    config: IRegisterForm;
}

const CustomerRegisterForm = (props: IProps): JSX.Element => {
    const { config } = props;
    const { passwordConfig } = config;
    const formElement = useRef<HTMLFormElement>(null);
    const [disabled, setDisabled] = useState(false);
    const [captchaToken, setCaptchaToken] = useState('');
    const FormDataValidationSchema = {
        firstname: Yup.string().nullable().required(config.labels.requiredField),
        lastname: Yup.string().nullable().required(config.labels.requiredField),
        email: Yup.string()
            .nullable()
            .email(config.emailConfig.emailMustBeValidErrorMessage)
            .required(config.labels.requiredField),
        telephone: Yup.string().nullable().required(config.labels.requiredField),
    };

    Object.assign(FormDataValidationSchema, {
        password: Yup.string()
            .nullable()
            .required(config.labels.requiredField)
            .min(passwordConfig.minLength)
            .test('password-min-character-sets', passwordConfig.labels.minCharacterSetLabel, (value) =>
                value ? validatePasswordMinCharacterSets(value, passwordConfig.requiredCharacterClasses) : true,
            ),
        password_confirmation: Yup.string()
            .nullable()
            .required(config.labels.requiredField)
            .when('password', {
                is: (password: string | undefined) => (password ? password.length > 0 : false),
                then: Yup.string().nullable().required(config.labels.requiredField),
            })
            .oneOf([Yup.ref('password'), null], passwordConfig.labels.passwordMatchLabel),
    });

    const formik = useFormik({
        initialValues: config.formData,
        validationSchema: Yup.object().shape(FormDataValidationSchema),
        validateOnChange: false,
        validateOnBlur: true,
        onSubmit: () => {
            void getCaptchaToken().then((token) => {
                setCaptchaToken(token);

                if (formElement.current) {
                    setDisabled(true);
                    formElement.current.submit();
                }
            });
        },
    });

    return (
        <form
            ref={formElement}
            action={config.formConfig.actionUrl}
            method="post"
            onSubmit={formik.handleSubmit}
        >
            <FormKey />
            <input
                type="hidden"
                name="success_url"
                value={config.formConfig.successUrl ?? ''}
            />
            <input
                type="hidden"
                name="error_url"
                value={config.formConfig.errorUrl ?? ''}
            />
            <input
                type="hidden"
                name="g-recaptcha-response"
                value={captchaToken}
            />
            <h3>{config.labels.accountInformation}</h3>
            <LayoutForm layout={'vertical'}>
                <FormRow
                    label={config.labels.firstNameLabel}
                    required={true}
                    error={formik.errors.firstname}
                >
                    <ControlInput
                        name="firstname"
                        value={formik.values.firstname ?? ''}
                        onChange={formik.handleChange}
                    />
                </FormRow>
                <FormRow
                    label={config.labels.lastNameLabel}
                    required={true}
                    error={formik.errors.lastname}
                >
                    <ControlInput
                        name="lastname"
                        value={formik.values.lastname ?? ''}
                        onChange={formik.handleChange}
                    />
                </FormRow>
                <FormRow
                    label={config.labels.emailLabel}
                    required={true}
                    error={formik.errors.email}
                >
                    <ControlInput
                        name="email"
                        value={formik.values.email ?? ''}
                        onChange={formik.handleChange}
                    />
                </FormRow>
                <FormRow
                    label={config.labels.phoneLabel}
                    required={true}
                    error={formik.errors.telephone}
                >
                    <ControlInput
                        name="telephone"
                        value={formik.values.telephone ?? ''}
                        onChange={formik.handleChange}
                    />
                </FormRow>
                <FormRow
                    label={config.labels.personalIdLabel}
                    required={true}
                    error={formik.errors.personal_id}
                >
                    <ControlInput
                        name="personal_id"
                        value={formik.values.personal_id ?? ''}
                        readonly={true}
                    />
                </FormRow>
                <FormRow
                    label={
                        <CheckboxList>
                            <CheckboxWithLabel
                                label={config.labels.newsletterSignup}
                                checked={formik.values.isSubscribed}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    formik.setFieldValue('isSubscribed', e.target.checked)
                                }
                            />
                        </CheckboxList>
                    }
                />
                <input
                    type="hidden"
                    name="is_subscribed"
                    value={formik.values.isSubscribed ? 1 : 0}
                />
                <h3>{config.labels.signInInformation}</h3>
                <FormRow
                    label={passwordConfig.labels.passwordLabel}
                    required={true}
                    error={formik.errors.password}
                >
                    <ControlPassword
                        name="password"
                        value={formik.values.password ?? ''}
                        onChange={formik.handleChange}
                        showPasswordButtonLabel={passwordConfig.labels.showPasswordLabel}
                    />
                </FormRow>
                <FormRow
                    label={passwordConfig.labels.confirmPasswordLabel}
                    required={true}
                    error={formik.errors.password_confirmation}
                >
                    <ControlPassword
                        name="password_confirmation"
                        value={formik.values.password_confirmation ?? ''}
                        onChange={formik.handleChange}
                        showPasswordButtonLabel={passwordConfig.labels.showPasswordLabel}
                    />
                </FormRow>
            </LayoutForm>

            <Buttons layout="vertical-wide">
                <Button
                    type="submit"
                    intent="primary"
                    disabled={disabled}
                    title={config.labels.formSubmitLabel}
                />
            </Buttons>
        </form>
    );
};

export default CustomerRegisterForm;
