import React, { useEffect, useState } from 'react';
import { useMutation } from 'redux-query-react';
import { IProduct } from 'components/Catalog/CategoryList/IProduct';
import BoxProduct from 'components/Catalog/CategoryList/BoxProduct';
import ProductListRequest, { IProductListResponse } from 'components/Catalog/CategoryList/ProductListRequest';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { IStoreState } from '../../../helpers/rootReducer';
import LoadingContent from 'ekaubamaja-ui/lib/Components/LoadingContent';
import { IProductActionsLabels } from 'components/Catalog/Product/ProductActions';
import { useLocation } from 'react-router-dom';
import { parse, ParsedQuery } from 'query-string';
import { CartSortDirection } from '../../../enums/CartSortDirection';
import { CartSortKey } from '../../../enums/CartSortKey';
import { cloneDeep } from 'lodash';
import { multiFilters } from 'components/LayeredNavigation/Filters';
import { IWishListLabels } from 'components/Cart/Buttons/AddAllToWishlist';
import useScrollRestoration from '../../../hooks/useScrollRestoration';
import FilterPaginator from 'components/Filter/Paginator';

interface IProps {
    config: {
        currentCategoryId?: number;
        ajaxUrl: string;
        labels: IProductActionsLabels;
        wishlistLabels: IWishListLabels;
    };
}
const pageSize = 60;

export const handleProducts = (
    inputProducts: IProduct[],
    parsedQueryParams: ParsedQuery<string>,
    ignoreFilter?: string,
): IProduct[] => {
    let comparedProducts: IProduct[] | undefined = cloneDeep(inputProducts);
    const direction = (parsedQueryParams['direction'] as string) ?? CartSortDirection.ASC;
    const sortOrder = (parsedQueryParams['order'] as string) ?? CartSortKey.POSITION;
    const params = parsedQueryParams['features'];
    ((params || '') as string).split(',').forEach((param) => {
        if (param.length) {
            comparedProducts = comparedProducts?.filter((comparedProduct) => {
                return !!comparedProduct[`${param}`];
            });
        }
    });

    multiFilters.forEach((queryParam) => {
        const filterValueString = parsedQueryParams[`${queryParam}`];
        if (!filterValueString || ignoreFilter === queryParam) {
            return;
        }
        const filterValues = ((filterValueString || '') as string).split(',');
        if (Array.isArray(filterValues) && filterValues.length > 0) {
            if (filterValues.length) {
                comparedProducts = comparedProducts?.filter((comparedProduct) => {
                    return comparedProduct[`${queryParam}`]
                        ? filterValues.some((r) => comparedProduct[`${queryParam}`].split(',').includes(r))
                        : false;
                });
            }
        }
    });

    if (sortOrder === CartSortKey.NAME) {
        comparedProducts = comparedProducts?.sort((a, b) => a.name.localeCompare(b.name));
    }
    if (sortOrder === CartSortKey.PRICE) {
        comparedProducts = comparedProducts?.sort((a, b) => (a.price > b.price ? 1 : -1));
    }
    if (sortOrder === CartSortKey.UNIT_PRICE) {
        comparedProducts = comparedProducts?.sort((a, b) => (a.unitPrice > b.unitPrice ? 1 : -1));
    }
    if (direction === CartSortDirection.DESC) {
        comparedProducts = comparedProducts?.reverse();
    }

    return comparedProducts;
};

const ProductCategoryList = (props: IProps) => {
    const { config } = props;
    const { currentCategoryId, labels, wishlistLabels } = config;
    const { isListView } = useSelector((state: IStoreState) => state.gridViewReducer);

    useScrollRestoration();
    const [{ isPending }, productListRequest] = useMutation((url: string) => ProductListRequest(url));
    const [productsInitialState, setProductsInitialState] = useState<IProduct[]>();
    const [products, setProducts] = useState<IProduct[]>();
    const [isLoading, setIsLoading] = useState(false);

    const location = useLocation();
    const parsedQueryParams = parse(location.search);

    const maxCount = products?.length || 0;
    const totalPages = Math.ceil(maxCount / pageSize);
    let page = parseInt((parsedQueryParams['p'] as string) || '1');
    if (page > totalPages) {
        page = 1;
    }

    useEffect(() => {
        setIsLoading(true);
        if (productsInitialState) {
            setProducts(handleProducts(productsInitialState, parsedQueryParams));
        }
    }, [location]);

    useEffect(() => {
        setIsLoading(false);
    }, [products]);

    const reloadProducts = async () => {
        const ajaxUrl = new URL(config.ajaxUrl);
        ajaxUrl.search = window.location.search;

        if (currentCategoryId) {
            ajaxUrl.searchParams.set('categoryId', currentCategoryId.toString());
        }

        await productListRequest(ajaxUrl.toString()).then((result) => {
            const response: IProductListResponse = result.body;
            const productItems = response.products;
            setProductsInitialState(productItems);
            setProducts(handleProducts(productItems, parsedQueryParams));
        });
    };

    useEffect(() => {
        void reloadProducts();
    }, []);

    return (
        <React.Fragment>
            {(isPending || isLoading) && (
                <div className={'layout-products'}>
                    <LoadingContent
                        layout="inline"
                        size="large"
                    />
                </div>
            )}
            {!isPending && !isLoading && (
                <>
                    {totalPages > 1 && (
                        <div className={'paginator'}>
                            <FilterPaginator
                                config={{
                                    pages: totalPages,
                                    current: page,
                                    labelPrev: labels.labelPrev,
                                    labelNext: labels.labelNext,
                                }}
                            />
                        </div>
                    )}
                    <div className="layout-products">
                        <div className={classNames('layout-products__list', { list: isListView })}>
                            {!!products &&
                                products.length > 0 &&
                                products.slice((page - 1) * pageSize, page * pageSize).map((product) => (
                                    <div
                                        className="layout-products__container"
                                        key={product.id}
                                    >
                                        <BoxProduct
                                            product={product}
                                            labels={labels}
                                            wishlistLabels={wishlistLabels}
                                        />
                                    </div>
                                ))}
                        </div>
                    </div>
                    {totalPages > 1 && (
                        <div className={'paginator'}>
                            <FilterPaginator
                                config={{
                                    pages: totalPages,
                                    current: page,
                                    labelPrev: labels.labelPrev,
                                    labelNext: labels.labelNext,
                                }}
                            />
                        </div>
                    )}
                </>
            )}
        </React.Fragment>
    );
};

export default ProductCategoryList;
