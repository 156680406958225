import * as React from 'react';
import Filter from 'components/LayeredNavigation/Components/Filter';
import { IFilters, IValueLabels } from 'components/LayeredNavigation/Filters';

interface IProps {
    activeFilters: IFilters;
    config: IValueLabels;
    appendId?: string;
}

const CategoryTree: React.FunctionComponent<IProps> = (props) => {
    const { activeFilters, config, appendId } = props;

    return (
        <div className="category-tree">
            <a
                href="#"
                className="menutoggler"
            >
                <span className="icon">
                    <img
                        src={`${window.assetUrl}/assets/img/sprite_grid.svg`}
                        alt=""
                    />
                </span>{' '}
                <span className="label">{config.openFilter}</span>
            </a>
            <Filter
                key={'rawmaterialcountry'}
                filters={activeFilters.rawmaterialcountry}
                type={'rawmaterialcountry'}
                label={config.rawmaterialcountry}
                appendId={appendId}
            />
            <Filter
                key={'attributegrapevariety'}
                filters={activeFilters.attributegrapevariety}
                type={'attributegrapevariety'}
                label={config.attributegrapevariety}
                appendId={appendId}
            />
            <Filter
                key={'attributesweetness'}
                filters={activeFilters.attributesweetness}
                type={'attributesweetness'}
                label={config.attributesweetness}
                appendId={appendId}
            />
            <Filter
                key={'attributegeoarea'}
                filters={activeFilters.attributegeoarea}
                type={'attributegeoarea'}
                label={config.attributegeoarea}
            />
            <Filter
                key={'attributefoodrecommendation'}
                filters={activeFilters.attributefoodrecommendation}
                type={'attributefoodrecommendation'}
                label={config.attributefoodrecommendation}
                appendId={appendId}
            />
            <Filter
                key={'brand'}
                filters={activeFilters.brand}
                type={'brand'}
                label={config.brand}
                appendId={appendId}
            />
            <Filter
                key={'attributebadge'}
                filters={activeFilters.attributebadge}
                type={'attributebadge'}
                label={config.attributebadge}
                appendId={appendId}
            />
            <Filter
                key={'features'}
                filters={activeFilters.boolfilters}
                type={'features'}
                label={config.features}
                labels={config}
                appendId={appendId}
            />
        </div>
    );
};

export default CategoryTree;
