import { IValidationItem, VALIDATION_TYPES } from 'components/Checkout/form/validate';
import { AddressFields } from 'components/Checkout/enums/address/AddressFields';

export const BaseAddressRules = (): IValidationItem[] => {
    return [
        {
            field: AddressFields.firstname,
            validations: [{ rule: VALIDATION_TYPES.REQUIRED }, { rule: VALIDATION_TYPES.NAME }],
        },
        {
            field: AddressFields.lastname,
            validations: [{ rule: VALIDATION_TYPES.REQUIRED }, { rule: VALIDATION_TYPES.NAME }],
        },
        {
            field: AddressFields.telephone,
            validations: [{ rule: VALIDATION_TYPES.REQUIRED }, { rule: VALIDATION_TYPES.PHONE }],
        },
        {
            field: AddressFields.region_id,
            validations: [{ rule: VALIDATION_TYPES.REQUIRED }],
        },
        {
            field: AddressFields.city,
            validations: [{ rule: VALIDATION_TYPES.REQUIRED }],
        },
        {
            field: AddressFields.street,
            validations: [{ rule: VALIDATION_TYPES.REQUIRED }],
        },
        {
            field: AddressFields.postcode,
            validations: [{ rule: VALIDATION_TYPES.REQUIRED }, { rule: VALIDATION_TYPES.NUMBER }],
        },
    ];
};
