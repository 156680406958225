// @ts-nocheck
export interface IPriceFormat {
    pattern: string;
    precision: number;
    requiredPrecision: number;
    decimalSymbol: string;
    groupSymbol: string;
    groupLength: number;
    integerRequired: boolean;
}

export function formatPriceLocale(price: string | number, format?: IPriceFormat, isShowSign = false) {
    format = Object.assign(format ?? {}, window.price_format);

    const precision = isNaN((format.requiredPrecision = Math.abs(format.requiredPrecision)))
        ? 2
        : format.requiredPrecision;
    const locale = window.LOCALE || 'en-US';
    let pattern = format.pattern || '%s';
    let amount = parseFloat(price as string);

    let sign = '';

    if (isShowSign) {
        sign = amount < 0 ? '-' : isShowSign ? '+' : '';
    } else {
        sign = '';
    }

    pattern = pattern.indexOf('{sign}') < 0 ? sign + pattern : pattern.replace('{sign}', sign);
    amount = Number(Math.round(Number(Math.abs(+amount || 0) + 'e+' + precision)) + ('e-' + precision));
    const result = amount.toLocaleString(locale, { minimumFractionDigits: precision });

    return pattern
        .replace('%s', result)
        .replace(/^\s\s*/, '')
        .replace(/\s\s*$/, '');
}
