import { request } from 'data/requests/request';

export interface IRequestProduct {
    sku: string;
    productId: string;
    qty?: {
        value: string;
    };
    disableCartEdit?: boolean;
    quoteId?: string;
}

const addWishlistProductRequest = (product: IRequestProduct) =>
    request({
        type: 'AddWishlistProduct',
        url: `wishlist/wishlist/addProduct`,
        method: 'POST',
        data: product,
        notApi: true,
        absolute: false,
    });

export default addWishlistProductRequest;
