import * as React from 'react';
import { useRequest } from 'redux-query-react';
import { useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import { getItem, setItem } from '../../helpers/storage';
import { request } from 'data/requests/request';
import isLoggedIn from '../../helpers/auth/isLoggedIn';
import RenderCartButton from 'components/CartButton/RenderCartButton';
import { Popover, PopoverInteractionKind } from 'ekaubamaja-ui/lib/Components/Popover';
import RenderCartMenu from 'components/CartButton/RenderCartMenu';
import Overlay from 'ekaubamaja-ui/lib/Components/Overlay';
import Icon from 'ekaubamaja-ui/lib/Components/Icon';

export interface ICartButtonProps {
    /* DOM to parse */
    dom: string;
    config: {
        href: string;
        src: string;
        label: string;
    };
}

const mapStateToProps = (state) => {
    return {
        grandTotal: state.entities.primitiveCart?.grandTotal,
        itemsCount: state.entities.primitiveCart?.itemsCount,
        cartTotalsResponse: state.entities.primitiveCart?.cartTotals,
        cartContainerBeforeTotalsResponse: state.entities.primitiveCart?.cartContainerBeforeTotals,
    };
};

const CartButton: React.FunctionComponent<ICartButtonProps> = (props) => {
    const [{}, cartRequest] = useRequest(request({ type: 'primitiveCart', url: 'fast/cart/info', notApi: true }));
    const { grandTotal, itemsCount, cartTotalsResponse, cartContainerBeforeTotalsResponse } =
        useSelector(mapStateToProps);
    const [total, setTotal] = useState(getItem('grandTotal'));
    const [count, setCount] = useState(getItem('itemsCount'));
    const [cartTotals, setCartTotals] = useState(getItem('cartTotalsResponse'));
    const [cartContainerBeforeTotals, setCartContainerBeforeTotals] = useState(
        getItem('cartContainerBeforeTotalsResponse'),
    );
    const [openOverlay, setOpenOverlay] = React.useState<boolean>(false);

    useEffect(() => {
        setTotal(grandTotal);
        setCount(itemsCount);
        setCartTotals(cartTotalsResponse);
        setCartContainerBeforeTotals(cartContainerBeforeTotalsResponse);
    }, [grandTotal, itemsCount, cartTotalsResponse, cartContainerBeforeTotalsResponse]);

    const effect = useCallback(async () => {
        const response = await cartRequest();
        // @ts-ignore
        setTotal(response.body?.grandTotal);
        // @ts-ignore
        setCount(response.body?.itemsCount);
        // @ts-ignore
        setCartTotals(response.body?.cartTotals);
        // @ts-ignore
        setCartContainerBeforeTotals(response.body?.cartContainerBeforeTotals);
    }, [cartRequest]);

    useEffect(() => {
        window.addEventListener('cart-altered', effect);
        return function cleanup() {
            window.removeEventListener('cart-altered', effect);
        };
    }, [cartRequest]);
    return (
        <React.Fragment>
            {isLoggedIn() ? (
                <React.Fragment>
                    <Popover
                        enforceFocus={true}
                        interactionKind={PopoverInteractionKind.CLICK}
                        position="bottom-right"
                        usePortal={true}
                        minimal={true}
                        autoFocus={false}
                        canEscapeKeyClose={true}
                        modifiers={{
                            offset: {
                                offset: '-10px, 0',
                            },
                            flip: {
                                enabled: false,
                            },
                        }}
                        className="dropper"
                        portalClassName={'site-menu-portal'}
                        popoverClassName={'site-menu-popover'}
                        content={
                            <RenderCartMenu
                                cartContainerBeforeTotals={cartContainerBeforeTotals}
                                buttonLabel={props.config.label}
                                buttonHref={props.config.href}
                                cartTotals={cartTotals}
                            />
                        }
                        target={
                            <RenderCartButton
                                notifications={count}
                                total={total}
                                label={props.config.label}
                                target="desktop"
                                onClick={(e) => {
                                    e.preventDefault();
                                }}
                            />
                        }
                        transitionDuration={0}
                        hoverOpenDelay={0}
                        hoverCloseDelay={100}
                    />
                    <RenderCartButton
                        notifications={count}
                        total={total}
                        label={props.config.label}
                        target="mobile"
                        onClick={(e) => {
                            e.preventDefault();
                            setOpenOverlay(true);
                        }}
                    />
                    <Overlay
                        isOpen={openOverlay}
                        doClose={() => setOpenOverlay(false)}
                        layout="focusview"
                        className="userhub__menu-mobile"
                        customHeader={
                            <div className="userhub__menu-mobile__heading">
                                <h2>{props.config.label}</h2>
                                <button onClick={() => setOpenOverlay(false)}>
                                    <Icon
                                        kind="close"
                                        width={16}
                                        height={16}
                                    />
                                </button>
                            </div>
                        }
                    >
                        <RenderCartMenu
                            buttonHref={props.config.href}
                            buttonLabel={props.config.label}
                            cartTotals={cartTotals}
                        />
                    </Overlay>
                </React.Fragment>
            ) : (
                <a href={props.config.href || ''}>
                    <span className="icon">
                        <img
                            src={props.config.src || ''}
                            alt={props.config.label || ''}
                        />
                    </span>
                    <span className="label">{props.config.label || ''}</span>
                    <b className="amount">{total}</b>
                    <b className="notifications">{count}</b>
                </a>
            )}
        </React.Fragment>
    );
};

CartButton.displayName = 'CartButton';

export default CartButton;
