import * as React from 'react';
import { ICustomer } from 'components/ClientCustomerList/ClientCustomerList';

interface IProps {
    customer: ICustomer;
    editLabel: string;
    deleteLabel: string;
}

const ClientCustomerList = (props: IProps) => {
    const { customer, editLabel, deleteLabel } = props;

    return (
        <React.Fragment>
            <tr>
                <td>
                    <b>{customer.name}</b>
                </td>
                <td>{customer.email}</td>
                <td>{customer.phone}</td>
                <td>{customer.role}</td>
                <td>{customer.status}</td>
                <td className="actions">
                    <a
                        href="#"
                        className="action intent-primary"
                        data-component="openModal"
                        data-config='{"name":"manage-user"}'
                    >
                        {editLabel}
                    </a>
                    <a
                        href="#"
                        className="action intent-danger"
                    >
                        {deleteLabel}
                    </a>
                </td>
            </tr>
        </React.Fragment>
    );
};

export default ClientCustomerList;
