import { request } from 'data/requests/request';

const shareQuoteRequest = (id: string) =>
    request({
        type: 'shareQuote',
        url: `cartshare/cart/ajax`,
        method: 'GET',
        data: { id },
        notApi: true,
        absolute: false,
    });

export default shareQuoteRequest;
