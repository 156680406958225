import * as React from 'react';
import LayoutForm from 'ekaubamaja-ui/lib/Layouts/LayoutForm';
import FormRow from 'ekaubamaja-ui/lib/Components/FormRow';
import ControlSelect from 'ekaubamaja-ui/lib/Components/ControlSelect';
import { useEffect, useState } from 'react';
import { first } from 'lodash';
import SmartId, { ISmartIdProps } from 'components/Authorization/Login/SmartId';
import Callout from 'ekaubamaja-ui/lib/Components/Callout';
import Buttons from 'ekaubamaja-ui/lib/Components/Buttons';
import Button from 'ekaubamaja-ui/lib/Components/Button';
import MobileId, { IMobileIdProps } from 'components/Authorization/Login/MobileId';
import IdCard, { IIdCardConfig, IIdCardProps } from 'components/Authorization/Login/IdCard';

interface IProps {
    config: {
        labels: {
            cancelLabel: string;
            authenticationMethod: string;
            verificationCodeLabel: string;
            registerLabel: string;
        };
        methods: IMethod[];
    };
}

export enum LoginMethodMode {
    METHOD_MODE_LOGIN = 'mode_login',
    METHOD_MODE_REGISTER = 'mode_register',
}

interface IMethod {
    label: string;
    code: IAuthMethodType;
    mode: LoginMethodMode;
    config: ISmartIdProps | IMobileIdProps | IIdCardConfig;
}

type IAuthMethodType = 'mobile-id' | 'smart-id' | 'id-card';

const StrongAuth: React.FunctionComponent<IProps> = (props) => {
    const [authMethod, setAuthMethod] = React.useState<IMethod>();
    const [verificationCode, setVerificationCode] = useState<string>();

    if ((props.config?.methods?.length || 0) < 1) {
        return null;
    }
    const { methods, labels } = props.config;

    useEffect(() => {
        if (!authMethod) {
            setAuthMethod(first(methods));
        }
    }, []);

    const registerLabel =
        authMethod?.mode === LoginMethodMode.METHOD_MODE_REGISTER ? props.config.labels.registerLabel : undefined;
    return (
        <React.Fragment>
            {verificationCode && authMethod && (
                <React.Fragment>
                    <Callout>
                        {labels?.verificationCodeLabel}: <strong>{verificationCode}</strong>
                    </Callout>
                    <Buttons>
                        <Button
                            title={labels.cancelLabel}
                            intent="discrete"
                            onClick={(e) => {
                                e.preventDefault();
                                setVerificationCode(undefined);
                            }}
                        />
                    </Buttons>
                </React.Fragment>
            )}
            {!verificationCode && authMethod && (
                <LayoutForm layout="vertical">
                    <FormRow label={props.config.labels.authenticationMethod}>
                        <ControlSelect
                            value={authMethod.code}
                            onChange={(v) => {
                                setAuthMethod(methods.find((method) => method.code === v.target.value));
                            }}
                        >
                            {methods.map((method) => (
                                <option
                                    key={method.code}
                                    value={method.code}
                                >
                                    {method.label}
                                </option>
                            ))}
                        </ControlSelect>
                    </FormRow>
                    {authMethod.code === 'smart-id' && (
                        <SmartId
                            config={authMethod.config as ISmartIdProps}
                            methodMode={authMethod.mode}
                            verificationCode={verificationCode}
                            setVerificationCode={setVerificationCode}
                            registerLabel={registerLabel}
                        />
                    )}
                    {authMethod.code === 'mobile-id' && (
                        <MobileId
                            config={authMethod.config as IMobileIdProps}
                            methodMode={authMethod.mode}
                            verificationCode={verificationCode}
                            setVerificationCode={setVerificationCode}
                            registerLabel={registerLabel}
                        />
                    )}
                    {authMethod.code === 'id-card' && (
                        <IdCard
                            config={authMethod.config as IIdCardConfig}
                            registerLabel={registerLabel}
                        />
                    )}
                </LayoutForm>
            )}
        </React.Fragment>
    );
};

export default StrongAuth;
