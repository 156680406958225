import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import OverlayConfirm from 'components/overlay/OverlayConfirm';
import Toaster from 'ekaubamaja-ui/lib/Components/Toaster';
import { useMutation } from 'redux-query-react';
import emptyQuoteRequest from 'components/Cart/requests/emptyQuoteRequest';
import Block from 'ekaubamaja-ui/lib/Components/Block';
import LayoutForm from 'ekaubamaja-ui/lib/Layouts/LayoutForm';
import FormRow from 'ekaubamaja-ui/lib/Components/FormRow';
import FormRowGroup from 'ekaubamaja-ui/lib/Components/FormRowGroup';
import ControlInput from 'ekaubamaja-ui/lib/Components/ControlInput';
import Button from 'ekaubamaja-ui/lib/Components/Button';
import Overlay from 'ekaubamaja-ui/lib/Components/Overlay';
import addWishlistRequest from 'components/Wishlist/requests/addWishlistRequest';
import changeWishlistNameRequest from 'components/Wishlist/requests/changeWishlistNameRequest';

interface IProps {
    config: {
        quoteId: string;
        quoteName: string;
        labels: IQuoteNameChangeLabels;
    };
}

export interface IQuoteNameChangeLabels {
    changeNameButton: string;
    modalText: string;
    modalTitle: string;
}

const ChangeQuoteName: React.FunctionComponent<IProps> = (props) => {
    const { config } = props;
    const [nameInput, setNameInput] = useState(config.quoteName);
    const [showModal, setShowModal] = useState(false);

    const [{}, changeWishlistNameQuery] = useMutation(() => changeWishlistNameRequest(config.quoteId, nameInput));

    // handle click of parent element if outline area is big
    useEffect(() => {
        const parent = document.getElementById('parentChangeQuoteName');
        if (parent) {
            parent.onclick = (e) => {
                e.preventDefault();
                setShowModal(true);
            };
        }
    }, []);

    const changeName = useCallback(async () => {
        const response = await changeWishlistNameQuery();

        Toaster.addToast({
            intent: response.status === 200 ? 'success' : 'danger',
            text: response.body.message,
            asHtml: true,
        });

        if (response.status === 200) {
            setTimeout(() => window.location.reload(), 500);
        }
    }, [nameInput]);

    return !showModal ? (
        <React.Fragment>
            <span onClick={() => setShowModal(true)}>{config.labels.changeNameButton}</span>
        </React.Fragment>
    ) : (
        <Overlay
            layout="focusview"
            title={config.labels.modalTitle}
            isOpen={showModal}
            doClose={() => {
                setShowModal(false);
            }}
            buttonIcons={[
                {
                    icon: 'close',
                    intent: 'link',
                    onClick: (e) => {
                        e.preventDefault();
                        setShowModal(false);
                    },
                },
            ]}
            size="medium"
        >
            <Block>
                <LayoutForm layout="vertical">
                    <FormRow label={config.labels.modalText}>
                        <FormRowGroup>
                            <ControlInput
                                value={nameInput}
                                onChange={(e) => setNameInput(e.target.value)}
                            />
                            <Button
                                title={config.labels.changeNameButton}
                                onClick={() => changeName()}
                            />
                        </FormRowGroup>
                    </FormRow>
                </LayoutForm>
            </Block>
        </Overlay>
    );
};

export default ChangeQuoteName;
