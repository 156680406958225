import * as React from 'react';
import Overlay from 'ekaubamaja-ui/lib/Components/Overlay';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreState } from '../../helpers/rootReducer';
import { includes } from 'lodash';
import { closeOverlay, openOverlay } from 'data/overlays/overlayHandler';
import ReorderActions from 'components/Reorder/reorderActions';
import ThemeWrapper from 'components/ThemeWrapper/ThemeWrapper';
import ProductsGrid from 'components/Catalog/Product/ProductsGrid';
import { IProduct } from 'components/Catalog/CategoryList/IProduct';
import { IProductActionsLabels } from 'components/Catalog/Product/ProductActions';

interface IProps {
    config: {
        products: IProduct[];
        reorderUrl: string;
        reorderModalLabel: string;
        reorderLabel: string;
        reorderSuccessMessage: string;
        reorderErrorMessage: string;
        redirectUrl: string;
        entityId: string;
        modalCancelLabel: string;
        modalReorderAndReplaceLabel: string;
        formKey: string;
        additionalLinkClasses?: string;
        listProductLabels: IProductActionsLabels;
    };
}

const Reorder: React.FunctionComponent<IProps> = (props) => {
    const { config } = props;
    const dispatch = useDispatch();
    const { openOverlays } = useSelector((state: IStoreState) => state.overlaysReducer);

    const toggleReorder = () => {
        if (includes(openOverlays, 'reorderModal')) {
            dispatch(closeOverlay({ name: 'all' }));
        } else {
            dispatch(openOverlay({ name: `reorderModal${config.entityId}` }));
        }
    };

    return (
        <React.Fragment>
            <a
                className={`action ${config.additionalLinkClasses}`}
                href="#"
                onClick={() => toggleReorder()}
            >
                {props.config.reorderLabel}
            </a>
            <Overlay
                layout="focusview"
                title={config.reorderModalLabel}
                isOpen={includes(openOverlays, `reorderModal${config.entityId}`)}
                doClose={() => {
                    dispatch(closeOverlay({ name: 'all' }));
                }}
                zIndex={16000004}
                size="medium"
                buttonIcons={[
                    {
                        icon: 'close',
                        onClick: () => {
                            dispatch(closeOverlay({ name: 'all' }));
                        },
                    },
                ]}
            >
                <ThemeWrapper>
                    <ProductsGrid
                        config={{
                            products: config.products,
                            labels: config.listProductLabels,
                            isShowList: true,
                            hideActions: true,
                        }}
                    />
                    <ReorderActions config={config} />
                </ThemeWrapper>
            </Overlay>
        </React.Fragment>
    );
};

export default Reorder;
