import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { BrowserRouter } from 'react-router-dom';

import MainRouter from './mainRouter';
import { validateLocale } from '../../../helpers/validateLocale';

export interface IRouteParams {
    locale: string;
    id?: string;
}

interface IProps {}
interface IState {}

export default class LocaleRouter extends React.Component<IProps, IState> {
    private readonly matchingUserLocale: string = 'en';

    constructor(props: IProps) {
        super(props);
        let userLocales: string[] = ['en'];
        if (navigator.languages && navigator.languages.length) {
            userLocales = userLocales.concat(navigator.languages);
        } else {
            userLocales.push(navigator.language);
        }
        this.matchingUserLocale = validateLocale(userLocales);
    }

    render() {
        return (
            <BrowserRouter>
                <Switch>
                    <Route
                        path="/"
                        component={MainRouter}
                    />
                    <Redirect to={`${Env.WEB_PATH}/home`} />
                </Switch>
            </BrowserRouter>
        );
    }
}
