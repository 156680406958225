import * as React from 'react';
import { useEffect, useState } from 'react';
import { ICartProduct } from 'components/Cart/Interfaces/ICartProduct';
import Product from 'components/Cart/Product/Product';
import { ICartLabels } from 'components/Cart/Interfaces/ICartLabels';
import classNames from 'classnames';

export interface ICartProductsProps {
    products: ICartProduct[];
    quoteId: string;
    emptyMessage?: string;
    cartType: string;
    labels: ICartLabels;
    wishlistProductIds?: string[];
}

const CartProducts = (props: ICartProductsProps) => {
    const { products, quoteId, emptyMessage, labels, wishlistProductIds } = props;

    const [error, setError] = useState<string>();
    if (!products) {
        return <React.Fragment />;
    }
    const effect = () => {
        setError(undefined);
    };
    useEffect(() => {
        window.addEventListener('cart-altered', effect);
        return function cleanup() {
            window.removeEventListener('cart-altered', effect);
        };
    }, [error]);

    return (
        <React.Fragment>
            <div className={'layout-cart__column-c'}>
                {error && <div className="cart-callout intent-danger">{error}</div>}

                {(!products || products.length < 1) && <div className="cart-callout">{emptyMessage}</div>}
                <div className="layout-carts">
                    <div className={classNames('layout-products__list', { list: true })}>
                        {products
                            .filter((product) => !product.isPawn)
                            .map((product, key) => (
                                <Product
                                    labels={labels}
                                    product={product}
                                    key={product.productId}
                                    quoteId={quoteId}
                                    setError={setError}
                                    cartType={props.cartType}
                                    wishlistProductIds={wishlistProductIds}
                                />
                            ))}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default CartProducts;
