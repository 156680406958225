import { Dispatch } from 'redux';
import { IGridViewState } from 'components/Common/store/gridViewSelector';
import { dispatchType } from 'components/Common/store/gridViewReducer';

export function setGridViewState(payload: IGridViewState) {
    return (dispatch: Dispatch) => {
        return dispatch({
            type: dispatchType.GRID_SET,
            payload,
        });
    };
}
