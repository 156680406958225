import * as React from 'react';
import { useCallback, useState } from 'react';
import OverlayConfirm from 'components/overlay/OverlayConfirm';
import Toaster from 'ekaubamaja-ui/lib/Components/Toaster';
import { useMutation } from 'redux-query-react';
import emptyQuoteRequest from 'components/Cart/requests/emptyQuoteRequest';
import cartFormPostRequest from 'data/requests/cart/cartFormPostRequest';
import { CartType } from '../../../enums/CartType';
import { ResponseStatus } from '../../../enums/ResponseStatus';

interface IProps {
    quoteId: string;
    cartType: string;
    formKey?: string;
    labels: IEmptyQuoteLabels;
}

export interface IEmptyQuoteLabels {
    emptyButtonTitle: string;
    confirmEmptyTitle: string;
    confirmEmptyDescription: string;
    confirmButtonCancel: string;
    confirmButtonOk: string;
}

const EmptyQuote: React.FunctionComponent<IProps> = (props) => {
    const { quoteId, labels, cartType, formKey } = props;
    const [showConfirm, setShowConfirm] = useState(false);

    const [{}, emptyWishlistQuote] = useMutation(() => emptyQuoteRequest(quoteId));
    const [{}, postRequest] = useMutation((data) => cartFormPostRequest(data));

    const handleClose = useCallback(async () => {
        setShowConfirm(false);
    }, []);

    const handleEmpty = useCallback(async () => {
        let response;
        if (cartType === CartType.WISHLIST) {
            response = await emptyWishlistQuote();
        } else if (cartType === CartType.CHECKOUT) {
            const formData = new FormData();
            formData.append('update_cart_action', 'empty_cart');
            formData.append('form_key', formKey ? formKey : '');

            // @ts-ignore
            response = await postRequest(formData);
        }
        if (response && response.status === ResponseStatus.ok) {
            window.dispatchEvent(new CustomEvent('cart-altered'));
            localStorage.removeItem('saved_shipping_method');
            localStorage.removeItem('saved_shipping_method_data');
            localStorage.removeItem('saved_address_fields');
            if (cartType === CartType.WISHLIST) {
                window.dispatchEvent(new CustomEvent('wishlist-altered'));
            }
        }

        Toaster.addToast({
            intent: response.status === ResponseStatus.ok ? 'success' : 'danger',
            text: response.body.message,
            asHtml: true,
        });
    }, [quoteId]);

    return (
        <React.Fragment>
            {showConfirm && (
                <OverlayConfirm
                    title={labels.confirmEmptyTitle}
                    description={labels.confirmEmptyDescription}
                    buttonCancel={labels.confirmButtonCancel}
                    buttonOk={labels.confirmButtonOk}
                    onAccept={handleEmpty}
                    onClose={handleClose}
                />
            )}
            <button
                onClick={(e) => {
                    e.preventDefault();
                    setShowConfirm(true);
                }}
                className="cart-button"
            >
                {labels.emptyButtonTitle}
            </button>
        </React.Fragment>
    );
};

export default EmptyQuote;
