import * as React from 'react';
import { useDispatch } from 'react-redux';
import { openOverlay } from 'data/overlays/overlayHandler';
import Actions from 'components/Common/Actions';
import ReturnOverlay from 'components/Return/returnOverlay';
import { IReturnFormLabels } from 'components/Return/returnForm';
import { IReturnTableLabels } from 'components/Catalog/Product/Return/Table';
import Button from 'ekaubamaja-ui/lib/Components/Button';

export interface IReturnActionProps {
    config: {
        labels: {
            return: string;
        } & IReturnFormLabels &
            IReturnTableLabels;
        orderId: string;
        hasBeenReturned: boolean;
        orderNumber: string;
        formData: {
            firstName: string;
            lastName: string;
            email: string;
            phone: string;
            returnReason: string;
            notes: string;
        };
    };
}

const ReturnActions: React.FunctionComponent<IReturnActionProps> = (props) => {
    const { config } = props;
    const dispatch = useDispatch();

    return (
        <>
            <Actions
                ulAdditionalStyles={{ marginTop: 0 }}
                class={`cart-buttons`}
                buttons={[
                    {
                        click: (e) => {
                            dispatch(openOverlay({ name: `returnModal${config.orderId}` }));
                        },
                        label: config.hasBeenReturned ? config.labels.hasBeenReturned : config.labels.return,
                        intent: `${config.hasBeenReturned ? 'button disabled' : ''} cart-button secondary`,
                        disabled: config.hasBeenReturned,
                    },
                ]}
            />
            <ReturnOverlay config={config} />
        </>
    );
};

export default ReturnActions;
