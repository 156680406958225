import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { ICartProduct } from 'components/Cart/Interfaces/ICartProduct';
import { ISidebarLabels } from 'components/Cart/Interfaces/ICartLabels';
import Overlay from 'ekaubamaja-ui/lib/Components/Overlay';
import Block from 'ekaubamaja-ui/lib/Components/Block';
import LayoutForm from 'ekaubamaja-ui/lib/Layouts/LayoutForm';
import FormRow from 'ekaubamaja-ui/lib/Components/FormRow';
import FormRowGroup from 'ekaubamaja-ui/lib/Components/FormRowGroup';
import ControlInput from 'ekaubamaja-ui/lib/Components/ControlInput';
import Button from 'ekaubamaja-ui/lib/Components/Button';
import CheckboxWithLabel from 'ekaubamaja-ui/lib/Components/CheckboxWithLabel';
import Buttons from 'ekaubamaja-ui/lib/Components/Buttons';
import { useMutation, useRequest } from 'redux-query-react';
import { request } from 'data/requests/request';
import { useSelector } from 'react-redux';
import { IWishlist } from 'components/Wishlist/interfaces/IWishlist';
import Toaster from 'ekaubamaja-ui/lib/Components/Toaster';
import { cloneDeep, head, without } from 'lodash';
import addWishlistRequest from 'components/Wishlist/requests/addWishlistRequest';
import addWishlistProductRequest, { IRequestProduct } from 'components/Cart/requests/addWishlistProductRequest';
import isLoggedIn from '../../../helpers/auth/isLoggedIn';
import { CartType } from '../../../enums/CartType';
import emptyQuoteRequest from 'components/Cart/requests/emptyQuoteRequest';

interface IProps {
    products: IRequestProduct[];
    labels: IWishListLabels;
    renderAddOne?: boolean;
    hasProductInAnyWishlist?: boolean;
    setHasProductInAnyWishlist?: (value: boolean) => void;
    cartType?: string;
    quoteId?: string;
    excludeQuoteId?: string;
}

export interface IWishListLabels {
    moveAllToWishlist: string;
    addAllWishlistSuccess: string;
    addOneWishlistSuccess: string;
    moveAllToWishlistModalTitle: string;
    moveAllToWishlistModalContent: string;
    moveAllToWishlistModalConfirm: string;
    moveAllToWishlistModalCancel: string;
    moveAllToWishlistModalMyWishlistLabel: string;
    moveAllToWishlistModalNewWishlist: string;
    moveAllToWishlistModalAdd: string;
    addToWishlist: string;
    logInToContinueTitle?: string;
    logInToContinueMessage?: string;
    logInToContinueButtonYes?: string;
    logInToContinueButtonCancel?: string;
}

const mapStateToProps = (state) => {
    return {
        wishlistsResponse: state.entities.wishlists,
    };
};

const AddAllToWishlist: React.FunctionComponent<IProps> = (props) => {
    const { labels, products, renderAddOne, hasProductInAnyWishlist, cartType, excludeQuoteId } = props;

    const [showModal, setShowModal] = useState<boolean>(false);

    const [selectedWishlists, setSelectedWishlists] = useState<IWishlist[]>([]);
    const [hasProductInAnyWishlistState, setHasProductInAnyWishlistState] = useState<boolean | undefined>(
        hasProductInAnyWishlist,
    );

    const [{ isFinished }] = useRequest(request({ type: 'wishlists', url: 'wishlist/wishlist/index', notApi: true }));
    const { wishlistsResponse } = useSelector(mapStateToProps);

    const [wishlists, setWishlists] = useState<IWishlist[]>([]);

    const [{}, addToWishlistQuery] = useMutation(() => addWishlistRequest(wishlistName));
    const [{}, addWishlistProduct] = useMutation((product: IRequestProduct) => addWishlistProductRequest(product));
    const [{}, emptyWishlistQuote] = useMutation(() => emptyQuoteRequest(props?.quoteId ? props.quoteId : ''));

    const toggleWishlistSelect = (id: string) => {
        const existingWishlist = selectedWishlists.find((wishlist) => wishlist.entity_id === id);
        if (existingWishlist) {
            setSelectedWishlists(without(selectedWishlists, existingWishlist));
        } else {
            const newWishlist = wishlists.find((savedWishlist) => savedWishlist.entity_id === id);
            if (newWishlist) {
                setSelectedWishlists([...selectedWishlists, newWishlist]);
            }
        }
    };

    useEffect(() => {
        if (isFinished) {
            let wishlistsToSelect = wishlistsResponse;
            if (excludeQuoteId) {
                wishlistsToSelect = wishlistsResponse.filter(
                    (wishlist) => wishlist.entity_id.toString() !== excludeQuoteId.toString(),
                );
            }

            setWishlists(wishlistsToSelect);
        }
    }, [isFinished]);

    const [wishlistName, setWishlistName] = useState<string>('');

    const addWishlist = useCallback(async () => {
        const response = await addToWishlistQuery();

        Toaster.addToast({
            intent: response.status === 200 ? 'success' : 'danger',
            text: response.body.message,
            asHtml: true,
        });

        if (response.status === 200) {
            window.dispatchEvent(
                new CustomEvent('wishlist-added', {
                    detail: response.body.entity,
                }),
            );
            setWishlistName('');
        }
    }, [wishlistName, wishlists]);

    const effect = useCallback(
        async (e) => {
            const wishlistsClone = cloneDeep(wishlists);
            wishlistsClone.push(e.detail);
            setWishlists(wishlistsClone);
        },
        [wishlists],
    );

    useEffect(() => {
        window.addEventListener('wishlist-added', effect);
        return function cleanup() {
            window.removeEventListener('wishlist-added', effect);
        };
    });

    const moveAllToSelected = () => {
        const filteredSelectedWishlistIds = selectedWishlists.map(
            (selectedWishlistItem) => selectedWishlistItem.entity_id,
        );

        const promises: Promise<any>[] = [];
        filteredSelectedWishlistIds.map((quoteId) => {
            products.map((product: IRequestProduct) => {
                product.quoteId = quoteId;
                if (!product.disableCartEdit) {
                    promises.push(addWishlistProduct(product));
                }
            });
        });
        Promise.all(promises).then(() => {
            if (props.setHasProductInAnyWishlist) {
                setHasProductInAnyWishlistState(true);
                props.setHasProductInAnyWishlist(true);
            }
            Toaster.addToast({
                intent: 'success',
                text: renderAddOne ? labels.addOneWishlistSuccess : labels.addAllWishlistSuccess,
                asHtml: true,
            });
            if (cartType && cartType === CartType.WISHLIST) {
                emptyWishlistQuote().then(() => {
                    window.dispatchEvent(new CustomEvent('cart-altered'));
                    window.dispatchEvent(new CustomEvent('wishlist-altered'));
                });
            } else {
                window.dispatchEvent(new CustomEvent('wishlist-altered'));
            }
        });
        setShowModal(false);
    };

    return (
        <React.Fragment>
            {!renderAddOne ||
                (renderAddOne && !head(products)?.disableCartEdit && (
                    <React.Fragment>
                        <button
                            onClick={() => setShowModal(!showModal)}
                            className={`cart-button secondary ${hasProductInAnyWishlistState ? 'active' : ''}`}
                            aria-label={renderAddOne ? labels.addToWishlist : labels.moveAllToWishlist}
                        >
                            {renderAddOne ? (
                                <React.Fragment>
                                    <span className="icon">
                                        <img
                                            src={`${window.assetUrl}/assets/img/sprite_heart_${
                                                hasProductInAnyWishlistState ? 'full' : 'empty'
                                            }.svg`}
                                            alt=""
                                            width="16"
                                            height="16"
                                        />
                                    </span>
                                    <span className="label">{labels.addToWishlist}</span>
                                </React.Fragment>
                            ) : (
                                labels.moveAllToWishlist
                            )}
                        </button>
                        <Overlay
                            layout="focusview"
                            title={isLoggedIn() ? labels.moveAllToWishlistModalTitle : labels.logInToContinueTitle}
                            isOpen={showModal}
                            doClose={() => {
                                setShowModal(false);
                            }}
                            buttonIcons={[
                                {
                                    icon: 'close',
                                    intent: 'link',
                                    onClick: (e) => {
                                        e.preventDefault();
                                        setShowModal(false);
                                    },
                                },
                            ]}
                            size="small"
                        >
                            {isLoggedIn() && (
                                <Block>
                                    <p>{labels.moveAllToWishlistModalContent}</p>
                                    <LayoutForm layout="vertical">
                                        <FormRow label={labels.moveAllToWishlistModalNewWishlist}>
                                            <FormRowGroup>
                                                <ControlInput
                                                    value={wishlistName}
                                                    onChange={(e) => setWishlistName(e.target.value)}
                                                />
                                                <Button
                                                    onClick={() => addWishlist()}
                                                    title={labels.moveAllToWishlistModalAdd}
                                                />
                                            </FormRowGroup>
                                        </FormRow>
                                        <FormRow label={labels.moveAllToWishlistModalMyWishlistLabel}>
                                            {isFinished &&
                                                wishlists &&
                                                wishlists.map((wishlist: IWishlist, key: number) => {
                                                    return (
                                                        <CheckboxWithLabel
                                                            key={key}
                                                            label={wishlist.name}
                                                            checked={selectedWishlists[wishlist.entity_id]}
                                                            onChange={() => toggleWishlistSelect(wishlist.entity_id)}
                                                        />
                                                    );
                                                })}
                                        </FormRow>
                                        <Buttons>
                                            <Button
                                                disabled={selectedWishlists.length < 1}
                                                title={labels.moveAllToWishlistModalAdd}
                                                intent="primary"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    moveAllToSelected();
                                                }}
                                            />
                                            <Button
                                                title={labels.moveAllToWishlistModalCancel}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setShowModal(false);
                                                }}
                                            />
                                        </Buttons>
                                    </LayoutForm>
                                </Block>
                            )}
                            {!isLoggedIn() && (
                                <Block>
                                    <p>{labels.logInToContinueMessage}</p>
                                    <LayoutForm layout="default">
                                        <Buttons>
                                            <Button
                                                title={labels.logInToContinueButtonYes}
                                                intent="primary"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    const el = document.getElementById('loginLink');
                                                    if (el) {
                                                        window.location.href = el?.getAttribute('href') ?? '';
                                                    }
                                                }}
                                            />
                                            <Button
                                                title={labels.logInToContinueButtonCancel}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setShowModal(false);
                                                }}
                                            />
                                        </Buttons>
                                    </LayoutForm>
                                </Block>
                            )}
                        </Overlay>
                    </React.Fragment>
                ))}
        </React.Fragment>
    );
};

export default AddAllToWishlist;
