import FormRow from 'ekaubamaja-ui/lib/Components/FormRow/index';
import ControlSelect from 'ekaubamaja-ui/lib/Components/ControlSelect/index';
import * as React from 'react';
import { useRef, useState } from 'react';
import { empty } from '../../../../helpers/empty';
import LayoutForm from 'ekaubamaja-ui/lib/Layouts/LayoutForm/index';
import ProceedButton from 'components/Checkout/shipping/ProceedButton';
import { useTranslation } from 'react-i18next';
import { IShippingMethod } from 'components/Checkout/interfaces/checkout/shipping/IShippingMethod';
import { IAdditional, IAdditionalComponents } from 'components/Checkout/shipping/ShippingAdditional';

export interface IAdditionalParcel extends IAdditional {
    place_id: string;
    place_name: string;
    shippingDescription: string;
}

interface SelectedItem {
    label: string;
    value: string;
    code?: string;
}

interface IProps {
    selectedMethod?: IShippingMethod;
    proceedAction: any;
    resetAction: any;
    setAdditionalData: any;
    shippingAdditional: any | undefined;
    setShippingAdditional: any | undefined;
    dropDownTitle: string;
    continueDisabled: boolean;
    additionalComponents: IAdditionalComponents;
}

const PlaceDelivery = (props: IProps) => {
    const { t } = useTranslation();
    const {
        selectedMethod,
        proceedAction,
        resetAction,
        setAdditionalData,
        shippingAdditional,
        setShippingAdditional,
        dropDownTitle,
        additionalComponents,
    } = props;
    const [selectedItem, setSelectedItem] = useState<SelectedItem | undefined>();
    const [placeHolder, setPlaceHolder] = useState('');

    const defaultSelected = useRef(false);
    const placeId = useRef(false);

    if (selectedMethod && shippingAdditional) {
        const selectedDefaultItem = shippingAdditional[selectedMethod?.methodCode];
        if (selectedDefaultItem && !placeId.current) {
            defaultSelected.current = true;
            placeId.current = selectedDefaultItem.place_id;
        }
    }

    const selectItem = async (item) => {
        if (!defaultSelected.current) {
            defaultSelected.current = true;
        }

        await setSelectedItem(item);
    };

    return (
        <React.Fragment>
            <LayoutForm layout="vertical">
                <FormRow
                    label={dropDownTitle}
                    required={true}
                >
                    <ControlSelect
                        disableSearch={false}
                        placeholder={placeHolder}
                        value={selectedItem?.label ?? ''}
                        onChange={(e) => {
                            resetAction();
                            selectItem(e.target.value);
                        }}
                    >
                        <option value="" />
                        {selectedMethod?.extra_data?.options?.map((location) => {
                            return (
                                <optgroup
                                    label={location.label}
                                    key={location.label}
                                >
                                    {location.value &&
                                        location.value.map((place) => {
                                            if (!placeHolder && empty(place.value)) {
                                                setPlaceHolder(place.label);
                                            }
                                            if (
                                                empty(selectedItem?.value) &&
                                                placeId.current &&
                                                placeId.current === place.value
                                            ) {
                                                selectItem(place);
                                            }
                                            if (empty(place.value)) {
                                                place.label = t(place.label);
                                            }
                                            if (
                                                !empty(place.selected) &&
                                                place.selected &&
                                                empty(selectedItem?.value) &&
                                                empty(placeId.current)
                                            ) {
                                                selectItem(place);
                                            }
                                            return (
                                                <option
                                                    value={place}
                                                    key={place.value}
                                                >
                                                    {place.label}
                                                </option>
                                            );
                                        })}
                                </optgroup>
                            );
                        })}
                    </ControlSelect>
                </FormRow>
                {additionalComponents.courierInfo && additionalComponents.courierInfo.component}
            </LayoutForm>
            <ProceedButton
                proceedAction={() => {
                    if (selectedItem) {
                        const additionalData: IAdditionalParcel | undefined = {
                            place_id: selectedItem?.code ?? selectedItem.value,
                            place_name: selectedItem.label,
                            shippingDescription: selectedItem.label,
                            courier_info: additionalComponents.courierInfo.exportField,
                        };
                        setAdditionalData(additionalData);
                        if (selectedMethod) {
                            shippingAdditional[selectedMethod.methodCode] = additionalData;
                            setShippingAdditional(shippingAdditional);
                        }
                        proceedAction();
                    }
                }}
                disabled={empty(selectedItem) || empty(selectedItem?.value) || props.continueDisabled}
            />
        </React.Fragment>
    );
};
export default PlaceDelivery;
