import { request } from 'data/requests/request';
import { IReturnProduct } from 'components/Catalog/Product/Return/Table';

export interface IFeedbackRequest {
    name: string;
    email: string;
    phone?: string;
    message: string;
    captchaToken: string;
}

const feedbackRequest = (data: IFeedbackRequest) =>
    request({
        method: 'POST',
        type: 'feedback',
        url: `feedback/feedback/submit`,
        notApi: true,
        data,
    });

export default feedbackRequest;
