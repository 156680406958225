import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { includes } from 'lodash';
import SiteMenu from 'ekaubamaja-ui/lib/Components/SiteMenu';
import { overlaysSelector } from 'data/overlays/overlaysSelector';
import { closeOverlay, openOverlay } from 'data/overlays/overlayHandler';
import { domToReact, htmlToDOM } from 'html-react-parser';
import ReactDOMServer from 'react-dom/server';
import { componentMapperParseOptions } from '../../utils/domParser';
import { useEffect } from 'react';
import { setActiveLink } from 'components/MainMenu/MainMenuWrapperHelper';

export interface IMainMenuProps {
    /* DOM to parse */
    dom: string;
    /** Unsafe config */
    config?: any;
    componentMapper?: (maybeComponent: string, maybeContent: string, maybeConfig) => React.Component;
}

const MainMenu: React.FunctionComponent<IMainMenuProps> = (props) => {
    const dispatch = useDispatch();
    const { openOverlays } = useSelector(overlaysSelector);

    const parserOptions = componentMapperParseOptions({ dom: props.dom, componentMapper: props.componentMapper });

    // execute componentMapper on menu dom elements, put back to string and forward to SiteMenu
    const domTree = htmlToDOM(props.dom.replace(/>\s+</g, '><'));
    const parsedDomReactElement = domToReact(domTree, parserOptions);
    const parsedDom = ReactDOMServer.renderToString(
        parsedDomReactElement as React.ReactElement<any, string | React.JSXElementConstructor<any>>,
    );

    useEffect(() => {
        setActiveLink();
    }, []);

    return (
        <SiteMenu
            dom={parsedDom}
            mobileMenuOpen={includes(openOverlays, 'mobilemenu')}
            openMobileMenu={(e) => {
                e.preventDefault();
                dispatch(closeOverlay({ name: 'all' }));
                dispatch(openOverlay({ name: 'mobilemenu' }));
            }}
            closeMobileMenu={(e) => {
                e.preventDefault();
                dispatch(closeOverlay({ name: 'all' }));
            }}
            target={props.config?.target || undefined}
            layout={props.config?.layout || undefined}
            canOutsideClickClose={true}
        />
    );
};

export default MainMenu;
