import { request } from 'data/requests/request';

const addWishlistRequest = (quoteId: string, name: string) =>
    request({
        type: 'changeWishlistName',
        url: `wishlist/wishlist/changeName`,
        method: 'POST',
        data: { name, quoteId },
        notApi: true,
        absolute: false,
    });

export default addWishlistRequest;
